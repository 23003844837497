import "./auth.css"
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {accountService} from "../../_services";
import brandLogo from "../../assets/images/logo.png"
import {Link} from "react-router-dom";

const Login = () => {
    useEffect(() => {
        document.title = "Skillspay - Login";
    }, []);
    let navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    const onChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        accountService.login(credentials)
            .then(res => {
                accountService.saveToken(res.data.jwt);
                localStorage.removeItem('gender_age');
                localStorage.removeItem('gender_type');
                localStorage.removeItem('quizState');
                localStorage.removeItem('score_value');
                localStorage.removeItem('quizToken');
                localStorage.removeItem('skip_all_questions');
                navigate('/courses')
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        if (accountService.isLogged()) {
            return (
                navigate('/courses', {replace: true})
            );
        }
    }, [navigate]);

    return (
        <div className="login-page h-100 mx-auto" style={{minHeight: '100vh', maxWidth: '500px'}}>
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center px-2 px-md-0">
                    <div className="login-content py-3 py-md-5 rounded-3 shadow-lg">
                        <div className="auth-form">
                            <Link to="/">
                                <div className="text-center mb-4 d-flex justify-content-center align-items-center">
                                    <img style={{width: '60%'}} src={brandLogo} alt="Brand Logo Skills Pay"/>
                                </div>
                            </Link>
                            <div className="text-center mb-3 fw-bold">Welcome Back</div>
                            <form onSubmit={onSubmit}>
                                <div className="form-group mb-4">
                                    <label className="mb-1 ps-3"
                                           htmlFor="email">Email</label>
                                    <input type="text" className="form-control"
                                           placeholder="Email Address" name="email" id="email"
                                           value={credentials.email} onChange={onChange}/>
                                </div>
                                <div className="form-group mb-5">
                                    <label className="mb-1 ps-3"
                                           htmlFor="pass">Password</label>
                                    <input type="password" className="form-control" placeholder="Password"
                                           name="password" id="pass" value={credentials.password}
                                           onChange={onChange}/>
                                </div>
                                {/*<div className="form-group text-end pe-2 mb-4">*/}
                                {/*    <Link to="#" className=" fw-">Forgot Password?</Link>*/}
                                {/*</div>*/}
                                <div className="text-center">
                                    <button type="submit"
                                            className="btn btn-block submit-button w-50 fw-bold">Sign me in
                                    </button>
                                </div>
                            </form>
                            <div className="new-account mt-3">
                                <p>Don't have an account?
                                    <Link to="/auth/quiz" className="sign-up-link fw-bold"> Sign Up Today</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;