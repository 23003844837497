import {useParams} from "react-router-dom";
import {CourseDetails, CourseHeader, PdfLessons} from "./Private";
import {Tab, Tabs} from "react-bootstrap";
import {useEffect, useState} from "react";
import {pdfCourseService} from "../../_services";

const PdfCourse = () => {
    useEffect(() => {
        document.title = "Skillspay - PDF Course";
    }, []);
    let {cid} = useParams();
    const [crsData, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await pdfCourseService.getPdfCourseById(cid);
                setData(data);
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, [cid]);


    if (crsData && crsData.length > 0) {
        const courseData = crsData[0];
        return (
            <div className="row m-0 align-items-center justify-content-center">
                <div className="col-xl-6-thedss col-xxl-5-thedss">
                    <div className="course-page">
                        <CourseHeader
                            pdf={true}
                            course_id={cid}
                            bg={courseData.background_name}
                            courseTitle={courseData.title}
                            courseLevel={courseData.level}
                            coursePercentage={parseInt(courseData.course_progress)}
                            courseLessonsNumber={courseData.lessons_num}
                            courseLessonsQuizzes={courseData.quizzes_num}
                        />
                        <div className="course-content">
                            <Tabs
                                variant="pills"
                                defaultActiveKey="lessons"
                                className="mb-3"
                                justify
                            >
                                <Tab eventKey="lessons" title="Lessons">
                                    <PdfLessons course_id={cid}/>
                                </Tab>
                                <Tab eventKey="details" title="Details">
                                    <CourseDetails
                                        pdf={true}
                                        course_id={cid}
                                        courseLevel={courseData.level}
                                        coursePages={courseData.course_pages}
                                        courseLessonsNumber={courseData.lessons_num}
                                        courseLessonsQuizzes={courseData.quizzes_num}
                                        courseDescription={courseData.description}
                                        received_skills={courseData.received_skills}
                                        what_will_you_learn={courseData.what_will_you_learn}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <></>
        );
    }
}

export default PdfCourse;