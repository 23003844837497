import Axios from "../ServiceCaller";

let getPaypalKeysSettings = async () => {
    const { data } = await Axios.get("/payments/paypal/keys-settings");
    return data.PaypalKeysSettings;
};

let getPaypalClientId = async () => {
    const { data } = await Axios.get("/payments/paypal/client-id");
    return data.ClientId;
};

let updatePaypalKeysSettings = async (paypalKeysSettingsData) => {
    const { data } = await Axios.put("/payments/paypal/keys-settings", paypalKeysSettingsData);
    return data;
};

export const paymentService = {
    getPaypalKeysSettings,
    getPaypalClientId,
    updatePaypalKeysSettings,
};