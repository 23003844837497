import {Header, TargetedCampaignForm, TargetedCampaignRow} from "./Admin";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {emailService} from "../../_services";
import {BiArrowBack} from "react-icons/bi";

const TargetedCampaigns = () => {
    const [targetedCampaignsData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await emailService.getTargetedCampaigns();
                setData(data);
            } catch (error) {
                console.error("Error fetching targeted campaigns data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const setFormHiddenAdd = () => {
        setHidden(!hidden);
    }
    return (
        <>
            <Header title="Targeted Campaigns"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-templates-page">
                    <Link className="big-title back fs-6 mb-2 mb-lg-3 d-flex align-items-center c-pointer"
                          to="/admin/email-marketing">
                        <BiArrowBack className="me-2 icon" size="25"/>Back To Emails Configurations
                    </Link>
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="big-title me-3 mb-2 mb-md-0">Targeted Campaigns</div>
                        <div className="add-btn-holder">
                            <button className="btn btn-primary py-1 btn-sm" disabled={!hidden} type="button"
                                    onClick={setFormHiddenAdd}>Add New Campaign
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <TargetedCampaignForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Name</td>
                                <td className="text-black-50">From Name</td>
                                <td className="text-black-50">Subject</td>
                                <td className="text-black-50">Template</td>
                                <td className="text-black-50">Targeted Type</td>
                                <td className="text-black-50">Targeted Value</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {targetedCampaignsData.length > 0 ? (
                                targetedCampaignsData.map((targeted_campaign) => (
                                    <TargetedCampaignRow
                                        key={targeted_campaign.id}
                                        targeted_campaign_id={targeted_campaign.id}
                                        campaign_name={targeted_campaign.campaign_name}
                                        from_name={targeted_campaign.from_name}
                                        subject={targeted_campaign.subject}
                                        template_id={targeted_campaign.template_id}
                                        template_name={targeted_campaign.template_name}
                                        targeted_type={targeted_campaign.targeted_type}
                                        targeted_value={targeted_campaign.targeted_value}
                                        created_date={targeted_campaign.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">There is no targeted campaign yet</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TargetedCampaigns;