import {Link} from "react-router-dom";
import {BiUserCircle} from "react-icons/bi";
import brandLogo from "../../../assets/images/logo.png";
import {accountService} from "../../../_services";

const Header = (props) => {
    let loggedIn = !!accountService.isLogged();

    return (
        <div className="bg-white main-header shadow-sm sticky-top">
            <div className="container d-flex align-items-center justify-content-between">
                <div>
                    {props.quiz ? (
                        <Link to="/home">
                            <img src={brandLogo} alt="" style={{ height: '66px' }} className="img-fluid" />
                        </Link>
                    ) : (
                        <Link to="/courses">
                            <img src={brandLogo} alt="" style={{ height: '66px' }} className="img-fluid" />
                        </Link>
                    )}
                </div>

                {props.desktop && (
                    <div className="d-none thedss-version">
                        <div className="d-flex">
                            <div className="px-3">
                                <Link to="/courses" className="sign-in-link fw-bold">Courses</Link>
                            </div>
                            <div className="px-3">
                                <Link to="/contact" className="sign-in-link fw-bold">Contact</Link>
                            </div>
                            <div className="px-3">
                                <Link to="/disclaimer" className="sign-in-link fw-bold">Disclaimer</Link>
                            </div>
                        </div>
                    </div>
                )}

                {loggedIn && (
                    <div className="me-3">
                        <Link to="/profile" className="d-flex align-items-center c-pointer header-p-link">
                            <BiUserCircle size='40'/>
                        </Link>
                    </div>
                )}
                {!loggedIn && (
                    <div className="me-4">
                        <Link to="/auth/login" className="sign-in-link fw-bold">Login</Link>
                    </div>
                )}

            </div>
        </div>
    );
}

export default Header;