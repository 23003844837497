import struggling from "../../assets/images/quiz/struggling.webp";
import stability from "../../assets/images/quiz/stability.webp";
import notSure from "../../assets/images/quiz/not-sure.webp";
import comfortable from "../../assets/images/quiz/comfortable.webp";
import rate1 from "../../assets/images/quiz/rate1.png";
import rate2 from "../../assets/images/quiz/rate2.png";
import rate3 from "../../assets/images/quiz/rate3.png";
import rate4 from "../../assets/images/quiz/rate4.png";
import rate5 from "../../assets/images/quiz/rate5.png";
import upwork from "../../assets/images/quiz/upwork.png";
import youtube from "../../assets/images/quiz/youtube.png";
import tiktok from "../../assets/images/quiz/tiktok.png";
import fiverr from "../../assets/images/quiz/fiverr.png";
import amazon from "../../assets/images/quiz/amazon.png";
import clickbank from "../../assets/images/quiz/clickbank.png";
import facebook from "../../assets/images/quiz/facebook.png";
import freelancer from "../../assets/images/quiz/freelancer.png";
import shopify from "../../assets/images/quiz/shopify.png";
import wordpress from "../../assets/images/quiz/wordpress.png";

/*---------------------------------------------------------------------------*/

const ages = [
    {
        id: "age1",
        name: "age",
        value: "18-24",
        image: "_18"
    },
    {
        id: "age2",
        name: "age",
        value: "25-34",
        image: "_25"
    },
    {
        id: "age3",
        name: "age",
        value: "35-44",
        image: "_35"
    },
    {
        id: "age4",
        name: "age",
        value: "45",
        image: "_45"
    }
];
const goals = [
    {
        id: "goal1",
        name: "goal",
        value: "Grow wealth",
        image: "_goal_grow"
    },
    {
        id: "goal2",
        name: "goal",
        value: "Be my own boss",
        image: "_goal_boss"
    },
    {
        id: "goal3",
        name: "goal",
        value: "Travel more",
        image: "_goal_travel"
    },
    {
        id: "goal4",
        name: "goal",
        value: "Other",
        image: "_goal_other"
    }
];
const financial_situations = [
    {
        id: "financial_situation1",
        name: "financial_situation",
        value: "I'm struggling to meet my financial goals",
        image: struggling
    },
    {
        id: "financial_situation2",
        name: "financial_situation",
        value: "I would like to have more stability",
        image: stability
    },
    {
        id: "financial_situation3",
        name: "financial_situation",
        value: "I’m not sure",
        image: notSure
    },
    {
        id: "financial_situation4",
        name: "financial_situation",
        value: "I'm comfortable",
        image: comfortable
    }
];
const income_levels_wanted = [
    {
        id: "income_level_wanted1",
        name: "income_level_wanted",
        value: "$30K - $50K"
    },
    {
        id: "income_level_wanted2",
        name: "income_level_wanted",
        value: "$50K - $100K"
    },
    {
        id: "income_level_wanted3",
        name: "income_level_wanted",
        value: "More than $100K"
    },
];
const career_flexibility = [
    {
        id: "career_flexibility1",
        name: "career_flexibility",
        value: "Yes"
    },
    {
        id: "career_flexibility2",
        name: "career_flexibility",
        value: "Maybe"
    },
    {
        id: "career_flexibility3",
        name: "career_flexibility",
        value: "No"
    },
];
const work_situation_wanted = [
    {
        id: "work_situation_wanted1",
        name: "work_situation_wanted",
        value: "Remote balance"
    },
    {
        id: "work_situation_wanted2",
        name: "work_situation_wanted",
        value: "Stable routine"
    },
    {
        id: "work_situation_wanted3",
        name: "work_situation_wanted",
        value: "I'm exploring options"
    }
];
const travel_while_working = [
    {
        id: "travel_while_working1",
        name: "travel_while_working",
        value: "It's a dream"
    },
    {
        id: "travel_while_working2",
        name: "travel_while_working",
        value: "I'm traveling"
    },
    {
        id: "travel_while_working3",
        name: "travel_while_working",
        value: "Sometimes"
    },
    {
        id: "travel_while_working4",
        name: "travel_while_working",
        value: "Not a priority"
    }
];
const content_writing_rate = [
    {
        id: "content_writing_rate1",
        name: "content_writing_rate",
        value: "Rate 1/5",
        image: rate1
    },
    {
        id: "content_writing_rate2",
        name: "content_writing_rate",
        value: "Rate 2/5",
        image: rate2
    },
    {
        id: "content_writing_rate3",
        name: "content_writing_rate",
        value: "Rate 3/5",
        image: rate3
    },
    {
        id: "content_writing_rate4",
        name: "content_writing_rate",
        value: "Rate 4/5",
        image: rate4
    },
    {
        id: "content_writing_rate5",
        name: "content_writing_rate",
        value: "Rate 5/5",
        image: rate5
    },
];
const ai_tools_easier_knowledge = [
    {
        id: "ai_tools_easier_knowledge1",
        name: "ai_tools_easier_knowledge",
        value: "No, this is news to me"
    },
    {
        id: "ai_tools_easier_knowledge2",
        name: "ai_tools_easier_knowledge",
        value: "I’m curious"
    },
    {
        id: "ai_tools_easier_knowledge3",
        name: "ai_tools_easier_knowledge",
        value: "Yes, I’ve heard of it"
    }
];
const digital_marketing_rate = [
    {
        id: "digital_marketing_rate1",
        name: "digital_marketing_rate",
        value: "Rate 1/5",
        image: rate1
    },
    {
        id: "digital_marketing_rate2",
        name: "digital_marketing_rate",
        value: "Rate 2/5",
        image: rate2
    },
    {
        id: "digital_marketing_rate3",
        name: "digital_marketing_rate",
        value: "Rate 3/5",
        image: rate3
    },
    {
        id: "digital_marketing_rate4",
        name: "digital_marketing_rate",
        value: "Rate 4/5",
        image: rate4
    },
    {
        id: "digital_marketing_rate5",
        name: "digital_marketing_rate",
        value: "Rate 5/5",
        image: rate5
    },
];
const promote_business_rate = [
    {
        id: "promote_business_rate1",
        name: "promote_business_rate",
        value: "Rate 1/5",
        image: rate1
    },
    {
        id: "promote_business_rate2",
        name: "promote_business_rate",
        value: "Rate 2/5",
        image: rate2
    },
    {
        id: "promote_business_rate3",
        name: "promote_business_rate",
        value: "Rate 3/5",
        image: rate3
    },
    {
        id: "promote_business_rate4",
        name: "promote_business_rate",
        value: "Rate 4/5",
        image: rate4
    },
    {
        id: "promote_business_rate5",
        name: "promote_business_rate",
        value: "Rate 5/5",
        image: rate5
    },
];
const ai_tools_promotion_knowledge = [
    {
        id: "ai_tools_promotion_knowledge1",
        name: "ai_tools_promotion_knowledge",
        value: "Hard to believe"
    },
    {
        id: "ai_tools_promotion_knowledge2",
        name: "ai_tools_promotion_knowledge",
        value: "I’m curious"
    },
    {
        id: "ai_tools_promotion_knowledge3",
        name: "ai_tools_promotion_knowledge",
        value: "Yes, I’ve heard of it"
    }
];
const interested_jobs = [
    {
        id: "interested_jobs1",
        name: "interested_jobs",
        value: "Digital Marketing"
    },
    {
        id: "interested_jobs2",
        name: "interested_jobs",
        value: "Youtube Automation"
    },
    {
        id: "interested_jobs3",
        name: "interested_jobs",
        value: "Affiliate Marketing"
    },
    {
        id: "interested_jobs4",
        name: "interested_jobs",
        value: "Dropshipping"
    },
    {
        id: "interested_jobs5",
        name: "interested_jobs",
        value: "Content Writing"
    },
    {
        id: "interested_jobs6",
        name: "interested_jobs",
        value: "Copywriting"
    },
    {
        id: "interested_jobs7",
        name: "interested_jobs",
        value: "Translation"
    },
    {
        id: "interested_jobs8",
        name: "interested_jobs",
        value: "Blogging"
    },
    {
        id: "interested_jobs9",
        name: "interested_jobs",
        value: "E-Books Publications"
    },
    {
        id: "interested_jobs10",
        name: "interested_jobs",
        value: "Others"
    }
];
const freelancing_freelancing_rate = [
    {
        id: "freelancing_freelancing_rate1",
        name: "freelancing_freelancing_rate",
        value: "Rate 1/5",
        image: rate1
    },
    {
        id: "freelancing_freelancing_rate2",
        name: "freelancing_freelancing_rate",
        value: "Rate 2/5",
        image: rate2
    },
    {
        id: "freelancing_freelancing_rate3",
        name: "freelancing_freelancing_rate",
        value: "Rate 3/5",
        image: rate3
    },
    {
        id: "freelancing_freelancing_rate4",
        name: "freelancing_freelancing_rate",
        value: "Rate 4/5",
        image: rate4
    },
    {
        id: "freelancing_freelancing_rate5",
        name: "freelancing_freelancing_rate",
        value: "Rate 5/5",
        image: rate5
    },
];
const freelance_platforms = [
    {
        id: "freelance_platforms1",
        name: "freelance_platforms",
        value: "Upwork",
        image: upwork
    },
    {
        id: "freelance_platforms2",
        name: "freelance_platforms",
        value: "YouTube",
        image: youtube
    },
    {
        id: "freelance_platforms3",
        name: "freelance_platforms",
        value: "Fiverr",
        image: fiverr
    },
    {
        id: "freelance_platforms4",
        name: "freelance_platforms",
        value: "Tiktok",
        image: tiktok
    },
    {
        id: "freelance_platforms5",
        name: "freelance_platforms",
        value: "Amazon",
        image: amazon
    },
    {
        id: "freelance_platforms6",
        name: "freelance_platforms",
        value: "Clickbank",
        image: clickbank
    },
    {
        id: "freelance_platforms7",
        name: "freelance_platforms",
        value: "Facebook",
        image: facebook
    },
    {
        id: "freelance_platforms8",
        name: "freelance_platforms",
        value: "Freelancer",
        image: freelancer
    },
    {
        id: "freelance_platforms9",
        name: "freelance_platforms",
        value: "Shopify",
        image: shopify
    },
    {
        id: "freelance_platforms10",
        name: "freelance_platforms",
        value: "Wordpress",
        image: wordpress
    },
];
const something_special_to_achieve = [
    {
        id: "something_special_to_achieve1",
        name: "something_special_to_achieve",
        value: "Buy a house",
        image: "_house"
    },
    {
        id: "something_special_to_achieve2",
        name: "something_special_to_achieve",
        value: "Vacation",
        image: "_vacation"
    },
    {
        id: "something_special_to_achieve3",
        name: "something_special_to_achieve",
        value: "Buy a new car",
        image: "_car"
    },
    {
        id: "something_special_to_achieve4",
        name: "something_special_to_achieve",
        value: "Save over $30,000",
        image: "_retirement"
    }
];
const daily_time_to_spend = [
    {
        id: "daily_time_to_spend1",
        name: "daily_time_to_spend",
        value: "Less than an hour"
    },
    {
        id: "daily_time_to_spend2",
        name: "daily_time_to_spend",
        value: "1 - 4 hours"
    },
    {
        id: "daily_time_to_spend3",
        name: "daily_time_to_spend",
        value: "4 - 10 hours"
    },
    {
        id: "daily_time_to_spend4",
        name: "daily_time_to_spend",
        value: "Over 10 hours"
    }
];

export const quizQuestions = {
    ages,
    goals,
    financial_situations,
    income_levels_wanted,
    career_flexibility,
    work_situation_wanted,
    travel_while_working,
    content_writing_rate,
    ai_tools_easier_knowledge,
    digital_marketing_rate,
    promote_business_rate,
    ai_tools_promotion_knowledge,
    interested_jobs,
    freelancing_freelancing_rate,
    freelance_platforms,
    something_special_to_achieve,
    daily_time_to_spend
};