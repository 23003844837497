import {Outlet} from "react-router-dom";
import {Sidebar} from "./Admin"
import "./admin.css"

const ALayout = () => {
    return (
        <div className="admin">
            <Sidebar/>
            <div className="main-content-holder">
                <Outlet/>
            </div>
        </div>
    );
}

export default ALayout;