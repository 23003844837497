import {useId} from "react";
import {useQuery} from "react-query";
import {courseService, pdfCourseService} from "../../../_services";
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {CourseBox} from "../../../pages/private/Private";
import {Link} from "react-router-dom";

const PdfCoursesHolder = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => pdfCourseService.getPdfCourses());

    if (isLoading) {
        return (
            <span></span>);
    }

    return (
        <div className="pdf-courses-holder my-4">
            <div className="d-flex align-items-center justify-content-between">
                <div className="main-title mb-2">Beginner Starter Courses</div>
                <Link to="/all-pdf-courses" className="text-secondary small-title fw-bold-500 mb-2">
                    View All
                </Link>
            </div>
            {data.length > 0 && (
                <Swiper
                    slidesPerView={3}
                    spaceBetween={15}
                    loop={true}
                    className="mySwiper"
                >
                    {
                        data.map((course) => (
                            <SwiperSlide key={course.id}>
                                <CourseBox
                                    pdf={true}
                                    course_id={course.id}
                                    bg={course.background_name}
                                    title={`${course.title.substring(0, 40)}`}
                                    level={course.level}
                                />
                            < /SwiperSlide>
                        ))
                    }
                </Swiper>
            )}
        </div>
    );
};

export default PdfCoursesHolder;