import React, {useEffect, useState} from "react";
import {settingService} from "../../../_services";

const StripeKeysSettingsForm = (props) => {
    const [isCreating, setIsCreating] = useState(false);

    const [params, setParamsValues] = useState({
        promise_key: "",
        secret_key: "",
    });

    useEffect(() => {
        setParamsValues({
            promise_key: props.promise_key,
            secret_key: props.secret_key,
        });
    }, [props]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("promise_key", params.promise_key);
            formData.append("secret_key", params.secret_key);
            formData.append("settings_id", props.settings_id);
            await settingService.updateStripeKeysSettings(formData);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
        } catch (error) {
            console.error("Error Creating/Updating Stripe Keys Settings:", error);
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="main-form-holder add-chapter-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center mb-3">Edit Stripe Keys Settings</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="form-group col-md-6 mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="promise_key">
                                Promise Key:
                            </label>
                            <div className="price-holder d-flex align-items-center">
                                <div className="price-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Stripe Promise Key"
                                        value={params.promise_key}
                                        onChange={onChange}
                                        name="promise_key"
                                        id="promise_key"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="secret_key">
                                Secret Key:
                            </label>
                            <div className="product_name-holder d-flex align-items-center">
                                <div className="product_name-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Stripe Secret Key"
                                        value={params.secret_key}
                                        onChange={onChange}
                                        name="secret_key"
                                        id="secret_key"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isCreating ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default StripeKeysSettingsForm;
