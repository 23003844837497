import TotalCard from "./TotalCard";
import {useEffect, useState} from "react";
import {dashboardService} from "../../../_services";
import {MdOutlineQuiz} from "react-icons/md";
import {BiDollarCircle, BiUser} from "react-icons/bi";

const TotalCards = () => {
    const [quizTaken, setQuizTaken] = useState(0);
    const [registeredUsers, setRegisteredUsers] = useState(0);
    const [revenue, setRevenue] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const quizData = await dashboardService.getTotalQuizTaken();
                setQuizTaken(quizData)
                const registeredData = await dashboardService.getTotalRegisteredUsers();
                setRegisteredUsers(registeredData)
                const revenueData = await dashboardService.getTotalRevenue();
                setRevenue(revenueData)
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="main-card-stats">
            <div className="big-title mb-2">Summary Report</div>
            <div className="row m-0 justify-content-center">
                <TotalCard
                    name="Total Quiz Taken"
                    value={quizTaken}
                    icon={<MdOutlineQuiz className="icon text-white" size="45"/>}
                />
                <TotalCard
                    name="Total Registered Users"
                    value={registeredUsers}
                    icon={<BiUser className="icon text-white" size="45"/>}
                />
                <TotalCard
                    name="Total Revenue"
                    value={`$${revenue}`}
                    icon={<BiDollarCircle className="icon text-white" size="45"/>}
                />
            </div>
        </div>
    );
}

export default TotalCards;