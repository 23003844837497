import {Link} from "react-router-dom";
import defaultCourseBg from "../../../assets/images/default_course_bg.jpg";

const CourseBox = (props) => {
    return (
        <>
            {props.pdf ? (
                <Link to={`/pdf-courses/course/${props.course_id}`}>
                    <div className="course-box rounded-3 bg-white pb-2 h-100 d-flex flex-column">
                        <div className="bg-holder">
                            {props.bg ? (
                                <img src={`https://api.skillspay.io/thedss/images/${props.bg}`}
                                     alt={props.title}
                                     className="rounded-3 img-fluid"

                                />
                            ) : (
                                <img className="course-bg img-fluid rounded-3" alt={props.title}
                                     src={defaultCourseBg}
                                />
                            )}
                        </div>
                        <div className="infos-holder mt-1">
                            <div className="level small">{props.level}</div>
                        </div>
                        <div className="infos-holder mt-1">
                            <div className="title">{props.title}</div>
                        </div>
                    </div>
                </Link>
            ) : (
                <Link to={`/courses/course/${props.course_id}`}>
                    <div className="course-box rounded-3 bg-white pb-2 h-100 d-flex flex-column">
                        <div className="bg-holder">
                            {props.bg ? (
                                <img src={`https://api.skillspay.io/thedss/images/${props.bg}`}
                                     alt={props.title}
                                     className="rounded-3 img-fluid"

                                />
                            ) : (
                                <img className="course-bg img-fluid rounded-3" alt={props.title}
                                     src={defaultCourseBg}
                                />
                            )}
                        </div>
                        <div className="infos-holder mt-1">
                            <div className="level small">{props.level}</div>
                        </div>
                        <div className="infos-holder mt-1">
                            <div className="title">{props.title}</div>
                        </div>
                    </div>
                </Link>
            )}
        </>
    );
}

export default CourseBox;