import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import PrivateRouter from "./pages/private/PrivateRouter";
import AuthRouter from "./pages/auth/AuthRouter";
import AuthGuard from "./_helpers/AuthGuard";
import IsLogged from "./_helpers/IsLogged";
import Deal from "./pages/public/Deal";
import AdminRouter from "./pages/admin/AdminRouter";
import AdminGuard from "./_helpers/AdminGuard";
import Home from "./pages/public/Home";
import PricingPlans from "./pages/public/PricingPlans";
import TermsAndConditions from "./pages/public/TermsAndConditions";
import PrivacyPolicy from "./pages/public/PricacyPolicy";
import SubscriptionTerms from "./pages/public/SubscriptionTerms";
import Disclaimer from "./pages/public/Disclaimer";
import FeaturedCourses from "./pages/public/FeaturedCourses";
import ThankYou from "./pages/public/ThankYou";
import FacebookPixelHolder from "./components/global/FacebookPixelHolder";
import ScrollRestoration from "./components/global/ScrollRestoration";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <FacebookPixelHolder/>
                <ScrollRestoration/>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route index path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/deal" element={<Deal/>}/>
                    <Route path="/pricing-plans" element={<PricingPlans/>}/>
                    <Route path="/featured-courses" element={<FeaturedCourses/>}/>
                    <Route path="/terms-conditions" element={<TermsAndConditions/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/thank-you" element={<ThankYou/>}/>
                    <Route path="/subscription-terms" element={<SubscriptionTerms/>}/>
                    <Route path="/disclaimer" element={<Disclaimer/>}/>
                    <Route path="/*" element={
                        <AuthGuard>
                            <PrivateRouter/>
                        </AuthGuard>
                    }/>
                    <Route path="/admin/*" element={
                        <AuthGuard>
                            <AdminGuard>
                                <AdminRouter/>
                            </AdminGuard>
                        </AuthGuard>
                    }/>
                    <Route className="App h-100 mx-auto" style={{minHeight: '100vh', maxWidth: '500px'}} path="/auth/*"
                           element={
                               <IsLogged>
                                   <AuthRouter/>
                               </IsLogged>
                           }/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;