import React, {useEffect, useState} from "react";
import {emailService} from "../../../_services";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TemplateForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [params, setParamsValues] = useState({
        name: "",
        content: "",
    });

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                name: props.template.name,
                content: props.template.content,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                name: "",
                content: "",
            });
        }
    }, [props.editMode, props.template]);

    const onChange = (value, name) => {
        setParamsValues({
            ...params,
            [name]: value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("name", params.name);
            formData.append("content", params.content);

            if (isCreatingMode) {
                await emailService.createTemplate(formData);
            } else {
                formData.append("id", props.template.template_id);
                await emailService.updateTemplate(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                name: "",
                order_num: "",
                content: "",
            });
        } catch (error) {
            console.error("Error Creating/Updating template:", error);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-template-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-name text-center">Add a New template</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="name">
                            Name:
                        </label>
                        <div className="name-holder d-flex align-items-center">
                            <div className="name-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Name of the template"
                                    value={params.name}
                                    onChange={(e) => onChange(e.target.value, "name")}
                                    name="name"
                                    id="name"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="content">
                            Template Content:
                        </label>
                        <div className="content-holder d-flex align-items-center">
                            <div className="content-input flex-grow-1">
                                <ReactQuill
                                    theme="snow"
                                    value={params.content}
                                    onChange={(value) => setParamsValues({ ...params, content: value })}
                                    modules={{
                                        toolbar: [
                                            ["bold", "italic", "underline", "strike"],
                                            ["blockquote", "code-block"],
                                            [{ header: 1 }, { header: 2 }],
                                            [{ list: "ordered" }, { list: "bullet" }],
                                            [{ script: "sub" }, { script: "super" }],
                                            [{ indent: "-1" }, { indent: "+1" }],
                                            [{ direction: "rtl" }],
                                            [{ size: ["small", false, "large", "huge"] }],
                                            [{ color: [] }, { background: [] }],
                                            [{ font: [] }],
                                            [{ align: [] }],
                                            ["link", "image", "video"],
                                            ["clean"],
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary px-4">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TemplateForm;