import {BiTrash} from "react-icons/bi";
import {useState} from "react";
import {emailService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const TargetedCampaignRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteCampaign = async () => {
        setIsDeleting(true);

        try {
            await emailService.deleteTargetedCampaign(props.targeted_campaign_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting course:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    if (props.targeted_campaign_id > 0) {
        return (
            <tr>
                <td>
                    <span>{props.campaign_name}</span>
                </td>
                <td>
                    <span>{props.from_name}</span>
                </td>
                <td>
                    <span>{props.subject}</span>
                </td>
                <td>
                    <span>{props.template_name}</span>
                </td>
                <td>
                    <span>{props.targeted_type}</span>
                </td>
                <td>
                    <span>{props.targeted_value}</span>
                </td>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
                <td>
                <span className="text-center">
                    {isDeleting ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <BiTrash onClick={handleDeleteCampaign} size="22" className="remove-icon mx-auto"/>
                    )}
                </span>
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
                <td colSpan="8" className="text-center">There is no targeted campaign yet</td>
            </tr>
        );
    }
}

export default TargetedCampaignRow;