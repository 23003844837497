import {LastCourseViewed} from "./Private";
import {accountService} from "../../_services";
import {useEffect, useId, useState} from "react";
import {useQuery} from "react-query";
import {Link, useNavigate} from "react-router-dom";
import {BiData, BiLogOut} from "react-icons/bi";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const Profile = () => {
    useEffect(() => {
        document.title = "Skillspay - Profile";
    }, []);
    const [admin, setAdmin] = useState(false);
    const [singleAccess, setSingleAccess] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await accountService.isAdmin();
                if (data === 'admin') {
                    setAdmin(true)
                }
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await accountService.isSingleAccess();
                if (data.access_type === 'single_course') {
                    setSingleAccess(true)
                }
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, []);

    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getUserData());
    const userData = data || {"data": []}
    let navigate = useNavigate();
    const logout = () => {
        accountService.logout();
        navigate("/auth/login")
    }


    if (isLoading) {
        return (
            <span></span>);
    }

    return (
        <div className="px-3 pt-4 profile-page">
            <div className="profile-content-holder mt-3 rounded-2">
                <div className="user-info-holder text-center">
                    <div className="username fw-bold">{userData.User[0].full_name}</div>
                    <div className="email text-black-50">{userData.User[0].email}</div>
                </div>
                <div className="last-viewed-course-holder my-4">
                    <div className="d-flex justify-content-between px-2">
                        <div className="text-center">
                            <div className="main-text-color fw-bold-500">Subscription Date</div>
                            <div className="main-text-color small">{userData.User[0].subscription_start ? getDate(userData.User[0].subscription_start) : ''}</div>
                        </div>
                        <div className="text-center">
                            <div className="main-text-color fw-bold-500">Expiration Date</div>
                            {userData.User[0].subscription_type === 'lifetime' ? (
                                <div className="main-text-color small">Lifetime</div>
                            ) : (
                                <div className="main-text-color small">{userData.User[0].subscription_end ? getDate(userData.User[0].subscription_end) : ''}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    {admin && (
                        <Link to="/admin/dashboard">
                            <div className="d-flex align-items-center btn btn-primary px-4 py-2 text-white mx-auto"
                                 style={{width: "fit-content"}}>
                                <div><BiData size="25" className="me-2"/></div>
                                <div className="fw-bold">Dashboard</div>
                            </div>
                        </Link>
                    )}
                </div>
                <div onClick={logout}
                     className="d-flex align-items-center justify-content-end c-pointer header-l-link me-3">
                    <BiLogOut size="20px" color="red" style={{width: "25px"}}/>
                    <span className="ms-2">Logout </span>
                </div>
            </div>
        </div>
    );
}

export default Profile;