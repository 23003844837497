import PaymentHolder from "../../private/main/PaymentHolder";
import React, {useEffect, useState} from "react";
import {courseService, pdfCourseService} from "../../../_services";
import Button from "react-bootstrap/Button";

const PricingPlanRow = (props) => {
    const [coursesData, setData] = useState([]);
    const [pdfCoursesData, setPdfData] = useState([]);
    const [params, setParamsValues] = useState({
        courseId: 0,
        pdfCourseId: 0,
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await courseService.getCourses();
                const pdf_data = await pdfCourseService.getPdfCourses();
                setData(data);
                setPdfData(pdf_data);
            } catch (error) {
                console.error("Error fetching courses / pdf courses data:", error);
            }
        };

        fetchData();
    }, []);
    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const handleSetAccountStep = () => {
        props.setPlanDetails({
            duration_type: props.duration_type,
            duration: props.duration,
            course_id: params.courseId || '',
            pdf_course_id: params.pdf_course_id || '',
            product_name: props.plan_name,
            price: props.sale_price,
        });
        props.setHolderStep('account')
    };

    return (
        <div className="plan-holder mb-3 position-relative most-popular">
            {props.plan_id === 8 && (
                <div className="position-absolute w-100 h-100 top-0 start-0 overflow-hidden">
                    <div className="most-popular-budget position-absolute fw-bold">Most Popular</div>
                </div>
            )}
            <div className="plan-content bg-white text-center p-3 pb-2 rounded-4 shadow">
                <div className="plan-title text-color fw-bold">
                    {props.plan_name}
                </div>
                <div className="plan-price big-title fw-bold mb-2">
                    <span className="text-decoration-line-through fs-6 me-1">
                        {localStorage.getItem('skillspay_chosen_currency_name') && localStorage.getItem('skillspay_chosen_currency') && localStorage.getItem('skillspay_chosen_currency') !== '1' ? (
                            <>
                                {`${Math.floor(props.regular_price / localStorage.getItem('skillspay_chosen_currency')).toLocaleString()} ${localStorage.getItem('skillspay_chosen_currency_name')}`}
                            </>
                        ) : (
                            <>
                                <span>${props.regular_price.toLocaleString()}</span>
                            </>
                        )}
                    </span>
                    <span className="">
                        {localStorage.getItem('skillspay_chosen_currency_name') && localStorage.getItem('skillspay_chosen_currency') && localStorage.getItem('skillspay_chosen_currency') !== '1' ? (
                            <>
                                {`${Math.floor(props.sale_price / localStorage.getItem('skillspay_chosen_currency')).toLocaleString()} ${localStorage.getItem('skillspay_chosen_currency_name')}`}*
                            </>
                        ) : (
                            <>
                                <span>${props.sale_price.toLocaleString()}*</span>
                            </>
                        )}
                    </span>
                </div>
                <div className="plan-description text-color mb-1 small-title">
                    {props.description}
                </div>
                <div className="text-color mb-2 small-title vat-text">
                    * VAT and local taxes may apply
                </div>
                <div className="pay-button my-3 text-center position-relative">
                    {props.duration_type === 'video_course' ? (
                        <div>
                            <div className="choose-course-holder">
                                <form encType="multipart/form-data">
                                    <div className="form-group mb-4">
                                        <label className="mb-1 text-start" htmlFor="courseId">
                                            Video Course:
                                        </label>
                                        <div className="course-holder d-flex align-items-center">
                                            <div className="course-input flex-grow-1">
                                                <select
                                                    className="form-control py-2"
                                                    value={params.courseId}
                                                    onChange={onChange}
                                                    name="courseId"
                                                    id="courseId"
                                                    required
                                                >
                                                    <option value="0">Please Select a Course</option>
                                                    {coursesData.length > 0 && (
                                                        coursesData.map((item, index) => (
                                                            <option value={item.id}
                                                                    key={index}>{item.title}</option>
                                                        ))
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {params.courseId > 0 && (
                                <Button variant="primary" onClick={handleSetAccountStep} className="pay-btn rounded-2 px-5 py-2">
                                    Subscribe Now
                                </Button>
                                // <PaymentHolder
                                //     setHolderStep={props.setHolderStep}
                                //     duration_type={props.duration_type}
                                //     duration={props.duration}
                                //     course_id={params.courseId}
                                //     client_id={props.clientID}
                                //     product_name={props.plan_name}
                                //     price={props.sale_price}
                                // />
                            )}
                        </div>
                    ) : props.duration_type === 'pdf_course' ? (
                        <div>
                            <div className="choose-course-holder">
                                <form encType="multipart/form-data">
                                    <div className="form-group mb-4">
                                        <label className="mb-1 text-start" htmlFor="pdfCourseId">
                                            Pdf Course:
                                        </label>
                                        <div className="course-holder d-flex align-items-center">
                                            <div className="course-input flex-grow-1">
                                                <select
                                                    className="form-control py-2"
                                                    value={params.pdfCourseId}
                                                    onChange={onChange}
                                                    name="pdfCourseId"
                                                    id="pdfCourseId"
                                                    required
                                                >
                                                    <option value="0">Please Select a Course</option>
                                                    {pdfCoursesData.length > 0 && (
                                                        pdfCoursesData.map((item, index) => (
                                                            <option value={item.id}
                                                                    key={index}>{item.title}</option>
                                                        ))
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {params.pdfCourseId > 0 && (
                                <Button variant="primary" onClick={handleSetAccountStep} className="pay-btn rounded-2 px-5 py-2">
                                    Subscribe Now
                                </Button>
                                // <PaymentHolder
                                //     setHolderStep={props.setHolderStep}
                                //     duration_type={props.duration_type}
                                //     duration={props.duration}
                                //     pdf_course_id={params.pdfCourseId}
                                //     client_id={props.clientID}
                                //     product_name={props.plan_name}
                                //     price={props.sale_price}
                                // />
                            )}
                        </div>
                    ) : (
                        <Button variant="primary" onClick={handleSetAccountStep} className="pay-btn rounded-2 px-5 py-2">
                            Subscribe Now
                        </Button>
                        // <PaymentHolder
                        //     setHolderStep={props.setHolderStep}
                        //     duration_type={props.duration_type}
                        //     duration={props.duration}
                        //     client_id={props.clientID}
                        //     product_name={props.plan_name}
                        //     price={props.sale_price}
                        // />
                    )}
                </div>
            </div>
        </div>
    );
}

export default PricingPlanRow;