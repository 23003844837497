import {BiEdit, BiTrash} from "react-icons/bi";
import {useState} from "react";
import {settingService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const CurrenciesRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteCurrencies = async () => {
        setIsDeleting(true);

        try {
            await settingService.deleteCurrency(props.currency_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting currencies:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditCurrencies = () => {
        props.handleEditCurrencies(props);
        props.sendHiddenToParent(!props.hidden);
    };
    if (props.currency_id > 0) {
        return (
            <tr>
                <td>
                    <span>{props.code}</span>
                </td>
                <td>
                    <span>{props.rate}</span>
                </td>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
                <td>
                    {props.currency_id === 1 ? (
                        <span className="main-dark-color small">Default</span>
                    ) : (
                        <span className="text-center">
                            <button className="border-0 bg-transparent" onClick={handleEditCurrencies}
                                    disabled={!props.hidden}>
                                <BiEdit size="22" className="edit-icon"/>
                            </button>
                            {isDeleting ? (
                                <span className="text-danger">Loading...</span>
                            ) : (
                                <BiTrash onClick={handleDeleteCurrencies} size="22" className="remove-icon mx-auto"/>
                            )}
                        </span>
                    )}
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
                <td colSpan="5" className="text-center">There is no currencies yet</td>
            </tr>
        );
    }
}

export default CurrenciesRow;