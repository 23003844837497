import Axios from "../ServiceCaller";

let getPricingPlans = async () => {
    const { data } = await Axios.get("/pricing-plans");
    return data.PricingPlans;
};
let createPricingPlan = async (pricingPlanData) => {
    const { data } = await Axios.post("/pricing-plans", pricingPlanData);
    return data;
};
let getPricingPlanById = async (id) => {
    const { data } = await Axios.get(`/pricing-plans/${id}`);
    return data.PricingPlan
};
let updatePricingPlan = async (pricingPlanData) => {
    const { data } = await Axios.put("/pricing-plans", pricingPlanData);
    return data;
};
let deletePricingPlan = async (pricing_plan_id) => {
    try {
        const response = await Axios.delete(`/pricing-plans`, {
            data: { id: pricing_plan_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting PricingPlan:", error);
        throw error;
    }
};

export const pricingPlanService = {
    getPricingPlans,
    createPricingPlan,
    getPricingPlanById,
    updatePricingPlan,
    deletePricingPlan,
};