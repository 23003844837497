import {Header, PricingPlanForm, PricingPlanRow} from "./Admin";
import {useEffect, useState} from "react";
import {pricingPlanService} from "../../_services";
import {Link} from "react-router-dom";

const PricingPlans = () => {

    const [pricingPlansData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [pricingPlanToEdit, setPricingPlanToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await pricingPlanService.getPricingPlans();
                setData(data);
            } catch (error) {
                console.error("Error fetching pricingPlans data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditPricingPlan = (pricingPlan) => {
        setEditMode(true);
        setPricingPlanToEdit(pricingPlan);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Pricing Plans"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-pricingPlans-page">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="d-flex flex-column flex-md-row align-items-center">
                            <div className="big-title me-md-3">List of PricingPlans</div>
                            <div className="add-btn-holder">
                                <button className="btn btn-primary py-1 btn-sm" disabled={!hidden} type="button"
                                        onClick={setFormHiddenAdd}>Add New PricingPlan
                                </button>
                            </div>
                        </div>
                        <div className="text-center my-2 my-md-0 ms-md-3">
                            <Link to="/admin/pricing-plans/currencies">
                                <button className="btn btn-success py-1 btn-sm">Manage Currencies</button>
                            </Link>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <PricingPlanForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            pricingPlan={pricingPlanToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Plan Name</td>
                                <td className="text-black-50">Description</td>
                                <td className="text-black-50">Type</td>
                                <td className="text-black-50">Duration</td>
                                <td className="text-black-50">Regular Price</td>
                                <td className="text-black-50">Sale Price</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {pricingPlansData.length > 0 ? (
                                pricingPlansData.map((pricingPlan) => (
                                    <PricingPlanRow
                                        key={pricingPlan.id}
                                        pricing_plan_id={pricingPlan.id}
                                        plan_name={pricingPlan.plan_name}
                                        description={pricingPlan.description}
                                        duration_type={pricingPlan.duration_type}
                                        duration={pricingPlan.duration}
                                        regular_price={pricingPlan.regular_price}
                                        sale_price={pricingPlan.sale_price}
                                        created_date={pricingPlan.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditPricingPlan={handleEditPricingPlan}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PricingPlans;