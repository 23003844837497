import {useEffect} from "react";

const Support = () => {
    useEffect(() => {
        document.title = "Skillspay - Support";
    }, []);
    return (
        <></>
    );
}

export default Support;