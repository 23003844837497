import React, {useEffect, useState} from "react";
import {paymentService} from "../../../_services";

const PaypalKeysSettingsForm = (props) => {
    const [isCreating, setIsCreating] = useState(false);

    const [params, setParamsValues] = useState({
        client_id: "",
        client_secret: "",
    });

    useEffect(() => {
        setParamsValues({
            client_id: props.client_id,
            client_secret: props.client_secret,
        });
    }, [props]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("client_id", params.client_id);
            formData.append("client_secret", params.client_secret);
            formData.append("settings_id", props.settings_id);
            await paymentService.updatePaypalKeysSettings(formData);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
        } catch (error) {
            console.error("Error Creating/Updating Paypal Keys Settings:", error);
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="main-form-holder add-chapter-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center mb-3">Edit Paypal Keys Settings</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="form-group col-md-6 mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="client_id">
                                Client ID:
                            </label>
                            <div className="price-holder d-flex align-items-center">
                                <div className="price-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Paypal Client ID"
                                        value={params.client_id}
                                        onChange={onChange}
                                        name="client_id"
                                        id="client_id"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="client_secret">
                                Client Secret:
                            </label>
                            <div className="product_name-holder d-flex align-items-center">
                                <div className="product_name-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Paypal Client Secret"
                                        value={params.client_secret}
                                        onChange={onChange}
                                        name="client_secret"
                                        id="client_secret"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isCreating ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default PaypalKeysSettingsForm;
