const TotalCard = (props) => {
    return (
        <div className="col-lg-6 col-xl-4 mb-3">
            <div
                className="box-card shadow rounded-2 py-3 px-4 d-flex justify-content-between align-items-center">
                <div className="infos-holder">
                    <div className="value fw-bold text-white">{props.value}</div>
                    <div className="name">{props.name}</div>
                </div>
                <div className="icon-holder">
                    {props.icon}
                </div>
            </div>
        </div>
    );
}

export default TotalCard;