import Axios from "./ServiceCaller";

let getHomePageData = async () => {
    const {data} = await Axios.get('settings/home-page');
    return data.HomePageData;
};

let updateHomePageData = async (homePageData) => {
    const { data } = await Axios.put("settings/home-page", homePageData);
    return data;
};

let getGoogleAnalyticsKeysSettings = async () => {
    const { data } = await Axios.get("settings/google-analytics");
    return data.GoogleAnalyticsKeysSettings;
};

let updateGoogleAnalyticsKeysSettings = async (GoogleAnalyticsKeysSettingsData) => {
    const { data } = await Axios.put("settings/google-analytics", GoogleAnalyticsKeysSettingsData);
    return data;
};

let getFacebookPixelKeysSettings = async () => {
    const { data } = await Axios.get("settings/facebook-pixel");
    return data.FacebookPixelKeysSettings;
};

let updateFacebookPixelKeysSettings = async (FacebookPixelKeysSettingsData) => {
    const { data } = await Axios.put("settings/facebook-pixel", FacebookPixelKeysSettingsData);
    return data;
};

let getTranzakKeysSettings = async () => {
    const { data } = await Axios.get("settings/tranzak");
    return data.TranzakKeysSettings;
};

let updateTranzakKeysSettings = async (TranzakKeysSettingsData) => {
    const { data } = await Axios.put("settings/tranzak", TranzakKeysSettingsData);
    return data;
};

let getStripeKeysSettings = async () => {
    const { data } = await Axios.get("settings/stripe");
    return data.StripeKeysSettings;
};

let getStripePromiseKey = async () => {
    const { data } = await Axios.get("settings/stripe/promise-key");
    return data.PromiseKey;
};

let updateStripeKeysSettings = async (StripeKeysSettingsData) => {
    const { data } = await Axios.put("settings/stripe", StripeKeysSettingsData);
    return data;
};

let getSliderSettings = async () => {
    const { data } = await Axios.get("settings/slider-settings");
    return data.SliderSettings;
};

let createSliderSettings = async (sliderSettingsData) => {
    const { data } = await Axios.post("settings/slider-settings", sliderSettingsData);
    return data;
};

let deleteSliderSettings = async (slider_id) => {
    try {
        const response = await Axios.delete(`settings/slider-settings`, {
            data: { id: slider_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting HomePageSlider:", error);
        throw error;
    }
};

let getCurrencies = async () => {
    const { data } = await Axios.get("/settings/currencies");
    return data.Currencies;
};

let createCurrency = async (currencyData) => {
    const { data } = await Axios.post("/settings/currencies", currencyData);
    return data;
};

let getCurrencyById = async (id) => {
    const { data } = await Axios.get(`/settings/currencies/${id}`);
    return data.Currency;
};

let updateCurrency = async (currencyData) => {
    const { data } = await Axios.put("/settings/currencies", currencyData);
    return data;
};

let deleteCurrency = async (currency_id) => {
    try {
        const response = await Axios.delete(`/settings/currencies`, {
            data: { id: currency_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Currency:", error);
        throw error;
    }
};

export const settingService = {
    getHomePageData,
    updateHomePageData,
    getGoogleAnalyticsKeysSettings,
    updateGoogleAnalyticsKeysSettings,
    getFacebookPixelKeysSettings,
    updateFacebookPixelKeysSettings,
    getTranzakKeysSettings,
    updateTranzakKeysSettings,
    getStripeKeysSettings,
    getStripePromiseKey,
    updateStripeKeysSettings,
    getSliderSettings,
    createSliderSettings,
    deleteSliderSettings,
    getCurrencies,
    createCurrency,
    getCurrencyById,
    updateCurrency,
    deleteCurrency,
};