import "./animated.css"
import React, { useState, useEffect } from 'react';

const CircularProgress = (props) => {
    const [percentage, setPercentage] = useState(1);
    const score_value = Number(props.score_value);
    useEffect(() => {
        const interval = setInterval(() => {
            if (percentage < score_value) {
                setPercentage(percentage + 1);
            }
        }, 30);

        return () => {
            clearInterval(interval);
        };
    }, [percentage, score_value]);

    const radius = props.landing ? 26.25 : 52.5; // Radius of the circular progress bar
    const circumference = 2 * Math.PI * radius;
    const progress = (percentage / 100) * circumference;

    return (
        <>
            {props.landing ? (
                <div className="circular-progress-component position-relative landing-true">
                    <svg width="75" height="75">
                        <circle
                            className="progress-bar"
                            r={radius}
                            cx="37.5"
                            cy="37.5"
                            fill="transparent"
                            strokeDasharray={circumference}
                            strokeDashoffset={circumference - progress}
                        />
                    </svg>
                    <div className="percentage d-flex align-items-center flex-column position-absolute top-50 start-50 translate-middle">
                        {percentage}%
                    </div>
                </div>
            ) : (
                <div className="circular-progress-component position-relative">
                    <svg width="150" height="150">
                        <circle
                            className="progress-bar"
                            r={radius}
                            cx="75"
                            cy="75"
                            fill="transparent"
                            strokeDasharray={circumference}
                            strokeDashoffset={circumference - progress}
                        />
                    </svg>
                    <div
                        className="percentage d-flex align-items-center flex-column lh-1 position-absolute top-50 start-50 translate-middle">
                        {percentage}%<br/>
                        <span>Required - 75</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default CircularProgress;
