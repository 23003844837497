import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {courseService, lessonService} from "../../_services";
import Iframe from "react-iframe";
import {BiArrowBack, BiBookOpen, BiSolidFilePdf} from "react-icons/bi";
import ProgressBar from "react-bootstrap/ProgressBar";

const Lesson = () => {
    useEffect(() => {
        document.title = "Skillspay - Lesson";
    }, []);
    let {lid} = useParams();
    const [lsnData, setData] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await lessonService.getLessonById(lid);
                setData(data);
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, [lid]);
    const handlePreviousLesson = async (courseId, chapterId, orderNum) => {
        const nextLessonOrderNum = orderNum - 1;
        try {
            const data = await lessonService.getLessonByOrderNumber(courseId, chapterId, nextLessonOrderNum);
            if (data.lesson_id > 0) {
                navigate(`/courses/lesson/${data.lesson_id}`, {replace: true})
            }
        } catch (error) {
            console.error("Error fetching course data:", error);
        }
    };
    const handleNextLesson = async (courseId, chapterId, orderNum) => {
        const nextLessonOrderNum = orderNum + 1;
        try {
            const data = await lessonService.getLessonByOrderNumber(courseId, chapterId, nextLessonOrderNum);
            await courseService.IncreaseCourseProgress(courseId, chapterId, lid);
            if (data.lesson_id > 0) {
                navigate(`/courses/lesson/${data.lesson_id}`, {replace: true})
            }
        } catch (error) {
            console.error("Error fetching course data:", error);
        }
    };
    if (lsnData && lsnData.length > 0) {
        const lessonData = lsnData[0];
        const openPdfUrl = (pdfName) => {
            const urlPath = `https://api.skillspay.io/thedss/lesson-resources/${pdfName}`;
            window.open(urlPath, '_blank');
        };

        return (
            <div className="row m-0 align-items-center justify-content-center">
                <div className="col-xl-6-thedss col-xxl-5-thedss">
                    <div className="lesson-page px-2">
                        <Link to={`/courses/course/${lessonData.course_id}`}>
                            <div className="d-flex align-items-center mt-2 border-bottom"
                                 style={{width: "fit-content"}}>
                                <div className="icon-holder me-1">
                                    <BiArrowBack size="25" color="#3d4465"/>
                                </div>
                                <div className="back-link">Back To Course</div>
                            </div>
                        </Link>
                        <div className="course-title text-center mt-4">{lessonData.course_title}</div>
                        <div className="chapter-title text-center mb-4">{lessonData.chapter_title}</div>
                        <div className="lesson-title mb-2"><BiBookOpen size="20" className="icon"/> {lessonData.title}
                        </div>
                        <Iframe className="rounded-3" url={lessonData.video_url.toString()} width="100%"
                                height="280px"/>
                        {
                            lessonData.l_resource_file && (
                                <div className="lesson-resources mt-4 mb-5">
                                    <div className="resources-title">Lesson Resources:</div>
                                    <button className="border-0 bg-transparent"
                                            onClick={() => openPdfUrl(lessonData.l_resource_file)}>
                                        <div className="file-link-holder d-flex align-items-center pt-3">
                                            <div className="icon-holder me-1">
                                                <BiSolidFilePdf className="pdf-icon" size="25"/>
                                            </div>
                                            <div className="pdf-title">
                                                {lessonData.title}
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            )
                        }
                        <div className="next-previous d-flex justify-content-between px-3 mt-3">
                            <button className="text-white px-3 py-1 rounded-2"
                                    onClick={() => handlePreviousLesson(lessonData.course_id, lessonData.chapter_id, lessonData.order_num)}>Previous
                                Lesson
                            </button>
                            <button className="text-white px-3 py-1 rounded-2"
                                    onClick={() => handleNextLesson(lessonData.course_id, lessonData.chapter_id, lessonData.order_num)}>Next
                                Lesson
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <>

            </>
        )
    }
}

export default Lesson;