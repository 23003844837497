import {FaCheck} from "react-icons/fa6";
import {Link} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";

const OrderSuccessful = ({price, method_payment}) => {
    return (
        <div className="thank-you-page">
            <div className="thank-you-content">
                <div className="circle-holder mx-auto">
                    <div className="circle-1 rounded-circle p-4">
                        <div className="circle-2 rounded-circle p-4">
                            <div className="circle-3 rounded-circle p-4">
                                <FaCheck size="100" color="#fff"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="big-title fw-bold text-center mt-5">
                    Payment Successful!
                </div>
                <div className="w-75 mx-auto horizontal-line my-4"/>
                <div className="payment-info main-text-color text-center fs-4">
                    Amount paid: <span className="info fw-bold-600">${price}</span>
                </div>
                <div className="payment-info main-text-color text-center fs-4">
                    Paid by: <span className="info fw-bold-600">{method_payment}</span>
                </div>
                <div className="link-btn-holder mt-5 text-center px-4">
                    <Link to="/auth/login" className="d-inline-block link w-100 rounded-3 py-3 fw-bold-600">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-center mx-1">
                                <BiArrowBack size="25" color="#fff"/>
                            </div>
                            <div className="text mx-1 text-white">
                                Back to Login
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default OrderSuccessful;