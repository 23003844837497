import {BiTrash} from "react-icons/bi";
import {userService} from "../../../_services";
import {useState} from "react";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const UserRow = (props) => {
    const name = props.full_name || (props.email.match(/^(.+)@/) || [])[1];
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteUser = async () => {
        setIsDeleting(true);

        try {
            await userService.deleteUser(props.user_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting user:", error);
        } finally {
            setIsDeleting(false);
        }
    };


    return (
        <tr>
            <td>
                <span>#{props.user_id}</span>
            </td>
            <td>
                {name}
            </td>
            <td>
                {props.email}
            </td>
            <td>
                {props.status}
            </td>
            <td>
                <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
            </td>
            <td>
                <span className="text-center">
                      {isDeleting ? (
                          <span className="text-danger">Loading...</span>
                      ) : (
                          <BiTrash
                              onClick={() => (props.user_id === 1 ? null : handleDeleteUser())}
                              size="22"
                              className={`remove-icon mx-auto ${props.user_id === 1 ? 'disabled' : ''}`}
                          />
                      )}
                </span>
            </td>
        </tr>
    );
}

export default UserRow;