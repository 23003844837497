import {Header} from "../private/Private";
import {useState, useEffect} from "react";
import {accountService, emailService} from "../../_services";
import {useNavigate} from "react-router-dom";
import {quizQuestions} from "./QuizQuestions";
import CircularProgress from "../../components/private/animated/CircularProgress";
import Lottie from "lottie-react"
import FourWeekChartBar from "../../components/private/animated/four_week_chart_bar.json";

// Importing Quiz Images
import gender_male from "../../assets/images/quiz/gender_male.webp";
import gender_female from "../../assets/images/quiz/gender_female.webp";
import _to_5_job from "../../assets/images/quiz/9-to-5_job.webp";
import freelance from "../../assets/images/quiz/freelance.webp";
import other from "../../assets/images/quiz/other.webp"
import low from "../../assets/images/quiz/low.webp"
import medium from "../../assets/images/quiz/medium.webp"
import high from "../../assets/images/quiz/high.webp"
import rate5 from "../../assets/images/quiz/rate5.png"
import star_eyes from "../../assets/images/quiz/star-eyes.png"
import ProgressBar from "react-bootstrap/ProgressBar";
import {BiCheckCircle, BiEnvelope} from "react-icons/bi";

const Quiz = () => {
    useEffect(() => {
        document.title = "Skillspay - Quiz";
    }, []);
    let navigate = useNavigate();
    const [formStep, setFormStep] = useState(0);
    const [formProgress, setFormProgress] = useState(1);
    let gender_type = localStorage.getItem("gender_type") ? localStorage.getItem("gender_type") : "";
    const [genderType, setGender] = useState(gender_type);
    let gender_age = localStorage.getItem("gender_age") ? localStorage.getItem("gender_age") : "";
    const [age, setAge] = useState(gender_age);
    let score_value = localStorage.getItem("score_value") ? localStorage.getItem("score_value") : "";
    const [scoreValue, setScoreValue] = useState(score_value);
    const [careerPlanGoalProgress, setCareerPlanGoalProgress] = useState(50);
    const [careerPlanSkillProgress, setCareerPlanSkillProgress] = useState(50);
    const [careerPlanContentProgress, setCareerPlanContentProgress] = useState(50);

    const currentDate = new Date();
    const currentDateOne = new Date();
    const currentDateTwo = new Date();
    const currentDateThree = new Date();
    const currentDateFour = new Date();
    currentDate.setMonth(currentDate.getMonth() + 3);
    currentDateOne.setMonth(currentDateOne.getMonth());
    currentDateTwo.setMonth(currentDateTwo.getMonth() + 1);
    currentDateThree.setMonth(currentDateThree.getMonth() + 2);
    currentDateFour.setMonth(currentDateFour.getMonth() + 3);
    const options = {year: 'numeric', month: 'short'};
    const options1 = {month: 'short'};
    const planFreedomDate = currentDate.toLocaleDateString('en-US', options);
    const planFreedomDateOne = currentDateOne.toLocaleDateString('en-US', options1);
    const planFreedomDateTwo = currentDateTwo.toLocaleDateString('en-US', options1);
    const planFreedomDateThree = currentDateThree.toLocaleDateString('en-US', options1);
    const planFreedomDateFour = currentDateFour.toLocaleDateString('en-US', options1);

    const initializeStateFromLocalStorage = () => {
        const storedState = localStorage.getItem("quizState");
        if (storedState) {
            const parsedState = JSON.parse(storedState);
            setFormStep(parsedState.formStep);
            setFormProgress(parsedState.formProgress);
            setParamsValues(parsedState.params);
        }
    };

    useEffect(() => {
        initializeStateFromLocalStorage();
    }, []);

    const [params, setParamsValues] = useState(
        {
            gender: "",
            age: "",
            goal: "",
            income_level_wanted: "",
            travel_while_working: "",
            content_writing_rate: "",
            digital_marketing_rate: "",
            interested_jobs: [],
            freelancing_freelancing_rate: "",
            freelance_platforms: [],
            freelancer_profile_summary: "skip",
            something_special_to_achieve: "",
            daily_time_to_spend: "",
            personal_plan: "skip",
            career_freedom_plan: "skip",
            email: "",
        }
    )

    const nextFormStep = () => {
        // Refactor validation logic to be more comprehensive
        const validateCurrentStep = () => {
            if (formStep === 1 && !params.gender) return false;
            if (formStep === 2 && !params.age) return false;
            if (formStep === 3 && !params.goal) return false;
            if (formStep === 4 && !params.income_level_wanted) return false;
            if (formStep === 5 && !params.travel_while_working) return false;
            if (formStep === 6 && !params.content_writing_rate) return false;
            if (formStep === 7 && !params.digital_marketing_rate) return false;
            if (formStep === 8 && params.interested_jobs.length < 1) return false;
            if (formStep === 9 && !params.freelancing_freelancing_rate) return false;
            if (formStep === 10 && params.freelance_platforms.length < 1) return false;
            if (formStep === 16) {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                return emailRegex.test(params.email);
            }
            return true;
        }

        // Validate before proceeding to the next step
        if (!validateCurrentStep()) {
            return;
        }

        // If validation passes, continue with existing logic
        if (formStep < 3) {
            localStorage.setItem("gender_type", params.gender);
            localStorage.setItem("gender_age", params.age.slice(0, 2));
            setGender(params.gender);
            setAge(params.age.slice(0, 2));
        }
        if (formStep === 9) {
            const min = 79;
            const max = 94;
            const score_value = Math.floor(Math.random() * (max - min + 1)) + min;
            localStorage.setItem("score_value", `${score_value}`);
            setScoreValue(`${score_value}`);
        }

        // Set the current field from params and check if empty
        const currentField = Object.keys(params)[formStep - 1];
        if (params[currentField] === "") {
            return;
        }

        // Update progress for applicable steps
        if (formStep !== 1 && formStep !== 2 && formStep !== 11 && formStep !== 14 && formStep !== 15) {
            setFormProgress((currentProgress) => currentProgress + 1);
        }

        // Move to the next step
        setFormStep((cur) => cur + 1);
        window.scrollTo({top: 0, behavior: 'smooth'});
        localStorage.setItem("quizState", JSON.stringify({
            formStep,
            formProgress,
            params,
        }));
    };

    const completeGoalProgress = (target) => {
        const targetProgress = target;
        const incrementAmount = 1;
        const delay = 30;

        const increment = () => {
            if (careerPlanGoalProgress < targetProgress) {
                setCareerPlanGoalProgress((prevProgress) => {
                    const newProgress = prevProgress + incrementAmount;
                    return newProgress <= targetProgress ? newProgress : targetProgress;
                });
                setTimeout(increment, delay);
            }
        };

        increment();
    };

    const completeSkillProgress = (target) => {
        const targetProgress = target;
        const incrementAmount = 1;
        const delay = 30;

        const increment = () => {
            if (careerPlanSkillProgress < targetProgress) {
                setCareerPlanSkillProgress((prevProgress) => {
                    const newProgress = prevProgress + incrementAmount;
                    return newProgress <= targetProgress ? newProgress : targetProgress;
                });
                setTimeout(increment, delay);
            }
        };

        increment();
    };

    const completeContentProgress = (target) => {
        const targetProgress = target;
        const incrementAmount = 1;
        const delay = 30;

        const increment = () => {
            if (careerPlanContentProgress < targetProgress) {
                setCareerPlanContentProgress((prevProgress) => {
                    const newProgress = prevProgress + incrementAmount;
                    return newProgress <= targetProgress ? newProgress : targetProgress;
                });
                setTimeout(increment, delay);
            }
        };

        increment();
    };

    const previousFormStep = () => {
        if (formStep === 1) {
            return;
        }
        setFormStep(cur => cur - 1)
        if (formStep !== 3) {
            setFormProgress((currentProgress) => currentProgress - 1);
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
        localStorage.setItem("quizState", JSON.stringify({
            formStep,
            formProgress,
            params,
        }));
    }

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value
        });
    }

    const goToDealPage = () => {
        accountService.saveQuizToken(params.email);
        accountService.saveQuizUser(params)
            .then(res => {
                emailService.scheduleSendEmail({'user_email': params.email})
                    .then(r => {
                        navigate('/deal', {replace: true});
                    });
            })
            .catch(error => console.log(error))
    }

    const skipAllQuestions = () => {
        localStorage.setItem("skip_all_questions", "thedss_skip_all_questions")
        navigate('/deal', {replace: true});
    }

    const onMultiSelectChange = (event) => {
        const selectedValue = event.target.value;
        const name = event.target.name;

        if (event.target.checked) {
            setParamsValues({
                ...params,
                [name]: [...params[name], selectedValue],
            });
        } else {
            setParamsValues({
                ...params,
                [name]: params[name].filter((value) => value !== selectedValue),
            });
        }
    };

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <Header quiz={true}/>
            <div className="row m-0 align-items-center justify-content-center">
                <div className="col-xl-6-thedss">
                    <div className="new-quiz-page px-2">
                        <div className="bg-white rounded-2 shadow-sm px-2 py-4">
                            {(formStep !== 0 && formStep !== 1 && formStep !== 2 && formStep !== 11 && formStep !== 15 && formStep !== 17) && (
                                <div className="main-form-progress-holder mb-5">
                                    <div className="progress-title text-center mb-1">Unfolding Your Journey</div>
                                    <div
                                        className="main-form-progress-holder justify-content-center d-flex align-items-center">
                                        <div className="w-75 me-3"><ProgressBar now={formProgress * 8.33}/></div>
                                        <div className="progress-form-value small">{formProgress} <span>/12</span></div>
                                    </div>
                                </div>
                            )}
                            <div className="steps-holder">
                                <form>
                                    {formStep === 0 && (
                                        <section className="gender-age-step">
                                            <div className="form-group mb-4">
                                                <div className="mb-4 text-center big-title fw-bold px-3 lh-sm">
                                                    Let’s Create a Financial Study Plan for You
                                                </div>
                                                <div className="text-center mb-4 small-title px-2 lh-lg">
                                                    To better understand your needs and requirements, we’ve put below a
                                                    few questions which should take you less than a minute to complete.
                                                    These questions will help us understand your needs better and craft
                                                    a tailored study plan that will help you achieve your goals of
                                                    making money online.
                                                </div>
                                                <div className="button-holder text-center">
                                                    <button type="button"
                                                            onClick={() => setFormStep(1)}
                                                            className="border-0 py-2 px-5 rounded-3 continue-btn">
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 1 && (
                                        <section className="gender-age-step">
                                            <div className="form-group mb-4">
                                                <div className="mb-2 text-center big-title fw-bold px-3 lh-sm">Create
                                                    Your Path
                                                    to Financial Freedom
                                                </div>
                                                <div className="text-center mb-4 small-title">Select your gender:</div>
                                                <div className="row">
                                                    <div className="col-6 mb-3 text-center">
                                                        <input type="radio" value="Male"
                                                               onChange={onChange}
                                                               id="gender_male"
                                                               name="gender"
                                                               checked={params.gender === "Male"}/>
                                                        <label
                                                            className="c-pointer male choice-content position-relative"
                                                            htmlFor="gender_male">
                                                            <div
                                                                className="circle position-absolute bottom-0 start-50 translate-middle-x bg-transparent"></div>
                                                            <div
                                                                className="d-flex align-items-end justify-content-center h-100 bg-holder position-relative"
                                                                style={{backgroundImage: `url(${gender_male})`}}>
                                                                <div
                                                                    className="gender w-100 rounded-3 py-2 text-white">Male
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-6 mb-3 text-center">
                                                        <input type="radio" value="Female"
                                                               onChange={onChange}
                                                               id="gender_female"
                                                               name="gender"
                                                               checked={params.gender === "Female"}/>
                                                        <label
                                                            className="c-pointer female choice-content position-relative w-75"
                                                            htmlFor="gender_female">
                                                            <div
                                                                className="circle position-absolute bottom-0 start-50 translate-middle-x bg-transparent"></div>
                                                            <div
                                                                className="d-flex align-items-end justify-content-center h-100 bg-holder position-relative"
                                                                style={{backgroundImage: `url(${gender_female})`}}>
                                                                <div
                                                                    className="gender w-100 rounded-3 py-2 text-white">Female
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 2 && (
                                        <section className="gender-age-step">
                                            <div className="form-group mb-4">
                                                <div className="mb-2 text-center big-title fw-bold px-3 lh-sm">Career
                                                    freedom
                                                    plan according to your age
                                                </div>
                                                <div className="row">
                                                    {
                                                        quizQuestions.ages.map((item, index) => (
                                                            <div className="col-6 mb-3 text-center" key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.age === item.value}/>
                                                                <label
                                                                    className={`c-pointer choice-content position-relative ${genderType.toLowerCase()}`}
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="circle position-absolute bottom-0 start-50 translate-middle-x bg-transparent"></div>
                                                                    <div
                                                                        className="d-flex align-items-end justify-content-center h-100 bg-holder position-relative"
                                                                        style={{
                                                                            backgroundImage: `url(${
                                                                                require(`../../assets/images/quiz/${genderType.toLowerCase()}${item.image}.webp`)
                                                                            })`
                                                                        }}>
                                                                        <div
                                                                            className="gender w-100 rounded-3 py-2 text-white">
                                                                            {item.value === "45" ? `${item.value}+` : item.value}
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 3 && (
                                        <section className="goal-step">
                                            <div className="form-group mb-4">
                                                <div className="mb-2 text-center big-title fw-bold px-3 lh-sm">What is
                                                    your
                                                    goal?
                                                </div>
                                                <div className="text-center mb-4 small-title">Please select your first
                                                    priority.
                                                </div>
                                                <div className="row">
                                                    {
                                                        quizQuestions.goals.map((item, index) => (
                                                            <div className="col-6 mb-3 text-center" key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.goal === item.value}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content position-relative"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex justify-content-center h-100 bg-holder"
                                                                        style={{
                                                                            backgroundImage: `url(${
                                                                                require(`../../assets/images/quiz/${genderType.toLowerCase()}_${age}${item.image}.webp`)
                                                                            })`
                                                                        }}>
                                                                        <div
                                                                            className="rounded-3 value">{item.value}</div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 4 && (
                                        <section className="income_level_wanted step">
                                            <div className="form-group mb-4">
                                                <div className="mb-4 text-center big-title fw-bold px-3 lh-sm">Annual
                                                    income
                                                    level you want to have
                                                </div>
                                                <div className="row align-items-center justify-content-center px-3">
                                                    {
                                                        quizQuestions.income_levels_wanted.map((item, index) => (

                                                            <div className="mb-3 col-xl-6-thedss" key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.income_level_wanted === item.value}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content big-rectangle no-img"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between h-100 p-4">
                                                                        <div
                                                                            className="rounded-3 value">{item.value}</div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 5 && (
                                        <section className="travel_while_working step">
                                            <div className="form-group mb-4">
                                                <div className="mb-4 text-center big-title fw-bold px-3 lh-sm">Do you
                                                    wish you
                                                    could travel while working?
                                                </div>
                                                <div className="row px-2 ">
                                                    {
                                                        quizQuestions.travel_while_working.map((item, index) => (

                                                            <div className="col-6 mb-3" key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.travel_while_working === item.value}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content big-rectangle no-img"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between h-100 p-4">
                                                                        <div
                                                                            className="rounded-3 value">{item.value}</div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className="img-holder">
                                                        <img
                                                            style={{height: "250px"}}
                                                            className="img-fluid w-75 h-auto"
                                                            src={require(`../../assets/images/quiz/${genderType.toLowerCase()}_${age}_travel.webp`)}
                                                            alt="travel_while_working"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 6 && (
                                        <section className="content_writing_rate step">
                                            <div className="form-group mb-4">
                                                <div className="mb-2 text-center big-title fw-bold px-3 lh-sm">
                                                    Rate your knowledge in Content Creation
                                                </div>
                                                <div className="text-center mb-4 small-title fw-normal">
                                                    Experienced Content Creators Make over
                                                    <span
                                                        className="fw-bold"> $100,000</span> annually.
                                                </div>
                                                <div className="d-flex justify-content-between px-3">
                                                    {
                                                        quizQuestions.content_writing_rate.map((item, index) => (
                                                            <div className="small-square-holder mb-3 text-center"
                                                                 key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.content_writing_rate === item.value}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content small-square"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex flex-column align-items-center justify-content-center h-100">
                                                                        <div
                                                                            className="img-holder d-flex justify-content-center align-items-center">
                                                                            <img
                                                                                src={item.image}
                                                                                alt={item.value}/>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className="d-flex justify-content-between px-3">
                                                    <div className="small-title small fw-normal">I need more
                                                        information
                                                    </div>
                                                    <div className="small-title small fw-normal">I know everything</div>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 7 && (
                                        <section className="digital_marketing_rate step">
                                            <div className="form-group mb-4">
                                                <div className="mb-2 text-center big-title fw-bold px-3 lh-sm">Rate your
                                                    knowledge in digital marketing
                                                </div>
                                                <div className="text-center mb-4 small-title fw-normal">Experienced
                                                    Digital Marketers make over <span
                                                        className="fw-bold">$100,000</span> annually.
                                                </div>
                                                <div className="d-flex justify-content-between px-3">
                                                    {
                                                        quizQuestions.digital_marketing_rate.map((item, index) => (
                                                            <div className="small-square-holder mb-3 text-center"
                                                                 key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.digital_marketing_rate === item.value}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content small-square"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex flex-column align-items-center justify-content-center h-100">
                                                                        <div
                                                                            className="img-holder d-flex justify-content-center align-items-center">
                                                                            <img
                                                                                src={item.image}
                                                                                alt={item.value}/>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className="d-flex justify-content-between px-3">
                                                    <div className="small-title small fw-normal">I need more
                                                        information
                                                    </div>
                                                    <div className="small-title small fw-normal">I know everything</div>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 8 && (
                                        <section className="interested_jobs step">
                                            <div className="form-group mb-4">
                                                <div className="mb-4 text-center big-title fw-bold px-3 lh-sm">Choose
                                                    jobs you
                                                    might be interested in
                                                </div>
                                                <div className="d-flex flex-wrap px-3 justify-content-center">
                                                    {
                                                        quizQuestions.interested_jobs.map((item, index) => (

                                                            <div className="mb-3 mx-2" key={index}>
                                                                <input type="checkbox" value={item.value}
                                                                       onChange={onMultiSelectChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.interested_jobs.includes(item.value)}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content big-rectangle no-img"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between h-100 p-3">
                                                                        <div
                                                                            className="rounded-3 value small">{item.value}</div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 9 && (
                                        <section className="freelancing_rate step">
                                            <div className="form-group mb-4">
                                                <div className="mb-4 text-center big-title fw-bold px-3 lh-sm">
                                                    Rate your readiness to start making money online
                                                </div>
                                                <div className="d-flex justify-content-between px-3">
                                                    {
                                                        quizQuestions.freelancing_freelancing_rate.map((item, index) => (
                                                            <div className="small-square-holder mb-3 text-center"
                                                                 key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.freelancing_freelancing_rate === item.value}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content small-square"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex flex-column align-items-center justify-content-center h-100">
                                                                        <div
                                                                            className="img-holder d-flex justify-content-center align-items-center">
                                                                            <img
                                                                                src={item.image}
                                                                                alt={item.value}/>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className="d-flex justify-content-between px-3">
                                                    <div className="small-title small fw-normal">I need more
                                                        information
                                                    </div>
                                                    <div className="small-title small fw-normal">I'm totally prepared
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 10 && (
                                        <section className="freelance_platforms step">
                                            <div className="form-group mb-4">
                                                <div className="mb-4 text-center big-title fw-bold px-3 lh-sm">
                                                    Choose platforms you have heard of
                                                </div>
                                                <div className="d-flex flex-wrap px-3 justify-content-center">
                                                    {
                                                        quizQuestions.freelance_platforms.map((item, index) => (

                                                            <div className="mb-3 mx-2" key={index}>
                                                                <input type="checkbox" value={item.value}
                                                                       onChange={onMultiSelectChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.freelance_platforms.includes(item.value)}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content big-rectangle no-img"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex align-items-center h-100 p-2">
                                                                        <div className="img-holder me-2"><img

                                                                            style={{width: "25px", height: "25px"}}
                                                                            src={item.image} alt={item.value}/></div>
                                                                        <div
                                                                            className="rounded-3 value">{item.value}</div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 11 && (
                                        <section className="freelancer_profile_summary">
                                            <div className="header-title fw-bold text-center mb-4">
                                                Summary of your<br/>
                                                <span>Financial Freedom profile</span>
                                            </div>
                                            <div
                                                className="summary-content mb-4 rounded-3 border border-1 px-2 py-3 shadow-sm">
                                                <div className="content-header">
                                                    <div
                                                        className="d-flex justify-content-between align-items-center px-2">
                                                        <div className="fs-6 small"
                                                             style={{fontWeight: "600"}}>Readiness score
                                                        </div>
                                                        <div className="result text-white px-2 py-1 rounded-3">Resuls:
                                                            Perfect <img
                                                                className="img-fluid"
                                                                width={18}
                                                                src={star_eyes}
                                                                alt="eyes-start"/>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-evenly align-items-center px-2">
                                                        <div className="circle-progress-holder w-50">
                                                            <CircularProgress score_value={scoreValue}/>
                                                        </div>
                                                        <div className="img-holder w-50 text-end position-relative">
                                                            <img
                                                                className="img-fluid w-75"
                                                                src={require(`../../assets/images/quiz/${genderType.toLowerCase()}_${age}.webp`)}
                                                                alt="perfect result"/>
                                                            <div
                                                                className="gradient-bg position-absolute end-0 bottom-0 w-100 h-50"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="content-body">
                                                    <div
                                                        className="d-flex justify-content-between align-items-end bars-holder mt-2 position-relative">
                                                        <div
                                                            className="position-absolute dashed-line position-relative start-50 translate-middle-x"></div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="progress-value"><span
                                                                className="txt-green">8.7</span>/10
                                                            </div>
                                                            <div className="bar1-progress rounded-top mx-auto"></div>
                                                            <div className="b-title mt-1">MINDSET</div>
                                                            <div className="s-title fw-light">Entrepreneur</div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="progress-value"><span
                                                                className="txt-green">8.5</span>/10
                                                            </div>
                                                            <div className="bar2-progress rounded-top mx-auto"></div>
                                                            <div className="b-title mt-1">MOTIVATION</div>
                                                            <div className="s-title fw-light">High</div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="progress-value"><span
                                                                className="txt-red">6.7</span>/10
                                                            </div>
                                                            <div className="bar3-progress rounded-top mx-auto"></div>
                                                            <div className="b-title mt-1">TIME</div>
                                                            <div className="s-title fw-light">For freelancing</div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="progress-value"><span
                                                                className="txt-red">5</span>/10
                                                            </div>
                                                            <div className="bar4-progress rounded-top mx-auto"></div>
                                                            <div className="b-title mt-1">KNOWLEDGE</div>
                                                            <div className="s-title fw-light">Beginner</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="footer-part py-2 px-3 rounded-2">
                                                <div className="d-flex">
                                                    <div className="icon-holder me-3">
                                                        <img
                                                            className="img-fluid" src={rate5}
                                                            alt="freelancer_profile_summary"/>
                                                    </div>
                                                    <div className="infos">
                                                        <div className="infos-title fw-bold small">Impressive score to
                                                            succeed
                                                        </div>
                                                        <div className="infos-body small">
                                                            By working online from home, you will enjoy greater
                                                            flexibility and
                                                            earnings potential. Global Stats data shows 61% of
                                                            individuals
                                                            earning more online than in traditional jobs. You can also
                                                            benefit
                                                            from this.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button onClick={() => nextFormStep()} type="button"
                                                        className="continue-btn mt-4 px-5 py-2 rounded-2">Continue
                                                </button>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 12 && (
                                        <section className="something_special_to_achieve step">
                                            <div className="form-group mb-4">
                                                <div className="mb-3 text-center big-title fw-bold px-3 lh-sm">
                                                    What's your motivation for the next 12months?
                                                </div>
                                                <div className="text-center mb-4 small-title fw-normal">
                                                    It’s easier to stay focus and hit your goals when you have something
                                                    motivating you.
                                                </div>
                                                <div className="row px-3">
                                                    {
                                                        quizQuestions.something_special_to_achieve.map((item, index) => (

                                                            <div className="mb-3 col-xl-6-thedss" key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.something_special_to_achieve === item.value}/>
                                                                <label
                                                                    style={{height: "100px"}}
                                                                    className="c-pointer rounded-3 choice-content big-rectangle"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex align-items-center ps-4 justify-content-between h-100 p-3 position-relative">
                                                                        <div
                                                                            className="rounded-3 value">{item.value}</div>
                                                                        <div
                                                                            className="img-holder position-absolute bottom-0 end-0 me-4">
                                                                            <img
                                                                                style={{height: "94px"}}
                                                                                src={require(`../../assets/images/quiz/${genderType.toLowerCase()}${item.image}.webp`)}
                                                                                alt={item.value}/></div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className="mb-3">
                                                        <input type="radio" value="Low"
                                                               onChange={onChange}
                                                               id="something_special_to_achieve6"
                                                               name="something_special_to_achieve"
                                                               checked={params.something_special_to_achieve === "Other"}/>
                                                        <label
                                                            style={{height: "100px"}}
                                                            className="c-pointer rounded-3 choice-content big-rectangle"
                                                            htmlFor="something_special_to_achieve6">
                                                            <div
                                                                className="d-flex align-items-center h-100 p-3">
                                                                <div className="rounded-3 value">Other</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 13 && (
                                        <section className="daily_time_to_spend step">
                                            <div className="form-group mb-4">
                                                <div className="mb-4 text-center big-title fw-bold px-3 lh-sm">
                                                    How much time can you commit a week to learning a new skill?
                                                </div>
                                                <div className="row px-3 align-items-center justify-content-center">
                                                    {
                                                        quizQuestions.daily_time_to_spend.map((item, index) => (

                                                            <div className="mb-3 col-xl-6-thedss" key={index}>
                                                                <input type="radio" value={item.value}
                                                                       onChange={onChange}
                                                                       id={item.id}
                                                                       name={item.name}
                                                                       checked={params.daily_time_to_spend === item.value}/>
                                                                <label
                                                                    className="c-pointer rounded-3 choice-content big-rectangle no-img"
                                                                    htmlFor={item.id}>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between h-100 p-4">
                                                                        <div
                                                                            className="rounded-3 value">{item.value}</div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 14 && (
                                        <section className="personal_plan">
                                            <div className="mb-2 text-center big-title fw-bold px-3 lh-sm">
                                                Your personal plan to financial freedom
                                            </div>
                                            <div className="text-center mb-4 small-title">
                                                Based on your answers, we expect you to gain necessary skills and become
                                                an
                                            </div>
                                            <div
                                                className="big-title fw-bold px-2 text-decoration-underline fs-6 text-center mb-3">
                                                Experience Professional by {planFreedomDate}
                                            </div>
                                            <div
                                                className="personal-plan-content mb-4 rounded-3 border border-1 p-2 shadow-sm">
                                                <div className="content-body">
                                                    <div
                                                        className="d-flex justify-content-between align-items-end bars-holder mt-2 position-relative">
                                                        <div
                                                            className="position-absolute dashed-line-100 position-relative start-50 translate-middle-x"></div>
                                                        <div
                                                            className="position-absolute dashed-line-50 position-relative start-50 translate-middle-x"></div>
                                                        <div
                                                            className="position-absolute dashed-line-0 position-relative start-50 translate-middle-x"></div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="bar1-progress rounded-top mx-auto"></div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="bar2-progress rounded-top mx-auto"></div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="bar3-progress rounded-top mx-auto"></div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="bar4-progress rounded-top mx-auto"></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-between align-items-end mt-2 position-relative">
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="s-title">{planFreedomDateOne}</div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="s-title">{planFreedomDateTwo}</div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="s-title">{planFreedomDateThree}</div>
                                                        </div>
                                                        <div className="w-25 text-center px-1 position-relative">
                                                            <div className="s-title">{planFreedomDateFour}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button onClick={() => nextFormStep()} type="button"
                                                        className="continue-btn mt-4 px-5 py-2 rounded-2">Continue
                                                </button>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 15 && (
                                        <section className="career_freedom_plan">
                                            {careerPlanContentProgress !== 100 && (
                                                <div className="mb-2 text-center big-title fw-bold px-3">
                                                    We are designing your
                                                    career freedom plan...
                                                </div>
                                            )}
                                            {careerPlanContentProgress === 100 && (
                                                <div className="mb-2 text-center big-title fw-bold px-3">
                                                    Ready to start?<br/>
                                                    Your courses and tools to start are waiting.
                                                </div>
                                            )}
                                            <div className="goals-plan">
                                                {careerPlanGoalProgress !== 100 && (
                                                    <div className="career-frerdom-plan-content py-3 px-3">
                                                        <div className="main-progress-holder">
                                                            <div
                                                                className="progress-header d-flex justify-content-between mb-2">
                                                                <div className="progress-title">Aligning with your
                                                                    goals
                                                                </div>
                                                                <div
                                                                    className="progress-value">{careerPlanGoalProgress}%
                                                                </div>
                                                            </div>
                                                            <div className="progress-bar-holder">
                                                                <ProgressBar now={careerPlanGoalProgress}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {careerPlanGoalProgress === 100 && (
                                                    <div className="career-result py-3 px-4">
                                                        <div
                                                            className="d-flex align-items-center pb-3 justify-content-between border-bottom">
                                                            <div className="progress-title">Goals</div>
                                                            <div className="icon-holder"><BiCheckCircle size="25"
                                                                                                        color="#7ABE4C"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {careerPlanGoalProgress !== 100 && (
                                                    <div className="career-footer text-center border p-3 rounded-3">
                                                        <div className="small-title">To move forward, specify</div>
                                                        <div
                                                            className="mb-4 text-center big-title fw-bold fs-5 lh-sm">Are
                                                            you
                                                            inclined
                                                            to finish what you start?
                                                        </div>
                                                        <div className="d-flex justify-content-evenly">
                                                            <button onClick={() => completeGoalProgress(100)}
                                                                    type="button"
                                                                    className="continue-btn px-5 py-2 rounded-2">No
                                                            </button>
                                                            <button onClick={() => completeGoalProgress(100)}
                                                                    type="button"
                                                                    className="continue-btn px-5 py-2 rounded-2">Yes
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={careerPlanGoalProgress !== 100 ? "d-none skills-plan" : "skills-plan"}>
                                                {careerPlanSkillProgress !== 100 && (
                                                    <div className="career-frerdom-plan-content py-3 px-3">
                                                        <div className="main-progress-holder">
                                                            <div
                                                                className="progress-header d-flex justify-content-between mb-2">
                                                                <div className="progress-title">Setting skills to
                                                                    improve
                                                                </div>
                                                                <div
                                                                    className="progress-value">{careerPlanSkillProgress}%
                                                                </div>
                                                            </div>
                                                            <div className="progress-bar-holder">
                                                                <ProgressBar now={careerPlanSkillProgress}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {careerPlanSkillProgress === 100 && (
                                                    <div className="career-result py-3 px-4">
                                                        <div
                                                            className="d-flex align-items-center pb-3 justify-content-between border-bottom">
                                                            <div className="progress-title">Skills</div>
                                                            <div className="icon-holder"><BiCheckCircle size="25"
                                                                                                        color="#7ABE4C"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {careerPlanSkillProgress !== 100 && (
                                                    <div className="career-footer text-center border p-3 rounded-3">
                                                        <div className="small-title">To move forward, specify</div>
                                                        <div
                                                            className="mb-4 text-center big-title fw-bold fs-5 lh-sm">Are
                                                            you
                                                            familiar with tools for promotion and networking?
                                                        </div>
                                                        <div className="d-flex justify-content-evenly">
                                                            <button onClick={() => completeSkillProgress(100)}
                                                                    type="button"
                                                                    className="continue-btn px-5 py-2 rounded-2">No
                                                            </button>
                                                            <button onClick={() => completeSkillProgress(100)}
                                                                    type="button"
                                                                    className="continue-btn px-5 py-2 rounded-2">Yes
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={careerPlanSkillProgress !== 100 ? "d-none contents-plan" : "contents-plan"}>
                                                {(careerPlanContentProgress !== 100) && (
                                                    <div className="career-frerdom-plan-content py-3 px-3">
                                                        <div className="main-progress-holder">
                                                            <div
                                                                className="progress-header d-flex justify-content-between mb-2">
                                                                <div className="progress-title">Picking content</div>
                                                                <div
                                                                    className="progress-value">{careerPlanContentProgress}%
                                                                </div>
                                                            </div>
                                                            <div className="progress-bar-holder">
                                                                <ProgressBar now={careerPlanContentProgress}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {careerPlanContentProgress === 100 && (
                                                    <div className="career-result py-3 px-4">
                                                        <div
                                                            className="d-flex align-items-center pb-3 justify-content-between border-bottom">
                                                            <div className="progress-title">Content</div>
                                                            <div className="icon-holder"><BiCheckCircle size="25"
                                                                                                        color="#7ABE4C"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {careerPlanContentProgress !== 100 && (
                                                    <div className="career-footer text-center border p-3 rounded-3">
                                                        <div className="small-title">To move forward, specify</div>
                                                        <div className="mb-4 text-center big-title fw-bold fs-5 lh-sm">
                                                            Are you willing to learn how to increase your income?
                                                        </div>
                                                        <div className="d-flex justify-content-evenly">
                                                            <button onClick={() => completeContentProgress(100)}
                                                                    type="button"
                                                                    className="continue-btn px-5 py-2 rounded-2">No
                                                            </button>
                                                            <button onClick={() => completeContentProgress(100)}
                                                                    type="button"
                                                                    className="continue-btn px-5 py-2 rounded-2">Yes
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {careerPlanContentProgress === 100 && (
                                                <div className="text-center">
                                                    <button onClick={() => nextFormStep()} type="button"
                                                            className="continue-btn mt-4 px-5 py-2 rounded-2">Continue
                                                    </button>
                                                </div>
                                            )}
                                        </section>
                                    )}
                                    {formStep === 16 && (
                                        <section className="email step">
                                            <div className="mb-4 text-center big-title fs-5 fw-bold px-3">
                                                Enter your
                                                email to
                                                get your personal <span>Financial Freedom</span> plan and other <span>Useful
                                                Resources</span>!
                                            </div>
                                            <div className="form-group mb-3 px-3">
                                                <div className="email-holder">
                                                    <div className="email-input position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y ps-2">
                                                            <BiEnvelope size="25" color="gray"/>
                                                        </div>
                                                        <input type="text" className="form-control rounded-2 ps-5 py-2"
                                                               placeholder="Your email" value={params.email}
                                                               onChange={onChange}
                                                               name="email" id="email"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4 px-3">
                                                <div className="email-holder d-flex align-items-center">
                                                    <div className="me-3">
                                                        <input type="checkbox" value="email"
                                                               className="news-email-input"
                                                               id="newsletters"
                                                               name="newsletters"/>
                                                    </div>
                                                    <label className="c-pointer rounded-3 small"
                                                           htmlFor="newsletters">I'd like to receive valuable insights,
                                                        exclusive offers, and the latest news straight to my inbox!
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button onClick={() => nextFormStep()} type="button"
                                                        className="continue-btn mt-4 px-5 py-2 rounded-2">Continue
                                                </button>
                                            </div>
                                        </section>
                                    )}
                                    {formStep === 17 && (
                                        <section className="four_week_plan step">
                                            <div className="mb-5 text-center big-title fw-bold px-3 lh-sm">
                                                Your Personalized Financial Freedom Plan is ready!
                                            </div>
                                            <div className="text-center big-title fs-6 fw-bold px-3 lh-sm">
                                                Your readiness level
                                            </div>
                                            <div className="chart-bar-holder">
                                                <Lottie className="chart-bar-item mx-auto position-relative"
                                                        animationData={FourWeekChartBar}
                                                        loop={false}
                                                />
                                                <div
                                                    className="months-holder d-flex justify-content-between">
                                                    <div className="w-25 text-center">
                                                        <div className="s-title">{planFreedomDateOne}</div>
                                                    </div>
                                                    <div className="w-25 text-center">
                                                        <div className="s-title">{planFreedomDateTwo}</div>
                                                    </div>
                                                    <div className="w-25 text-center">
                                                        <div className="s-title">{planFreedomDateThree}</div>
                                                    </div>
                                                    <div className="w-25 text-center">
                                                        <div className="s-title">{planFreedomDateFour}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button onClick={() => goToDealPage()} type="button"
                                                        className="continue-btn mt-4 px-5 py-2 rounded-2">Continue
                                                </button>
                                            </div>
                                        </section>
                                    )}
                                    {(formStep !== 0 && formStep !== 11 && formStep !== 14 && formStep !== 15 && formStep !== 16 && formStep !== 17) && (
                                        <div className="step-buttons text-center">
                                            <button className="py-1 px-3" onClick={() => previousFormStep()}
                                                    type="button">Previous
                                            </button>
                                            <button className="py-1 px-3" onClick={() => nextFormStep()}
                                                    type={formStep === 21 ? "submit" : "button"}>
                                                {formStep === 21 ? "Complete" : "Next"}
                                            </button>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                        <div className="row not-interested-holder mt-3 px-1">
                            <div className="small text-center">
                                Not interested in a customized plan, <button type="button" onClick={skipAllQuestions}
                                                                             className="border-0 p-0 m-0 bg-transparent fw-bold">Skip
                                all Questions.</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Quiz;