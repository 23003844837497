import {Header} from "./Admin";
import {useEffect, useState} from "react";
import {userService} from "../../_services";
import {Link, useParams} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";

const QuizUserDetails = () => {
    const getDate = (dt) => {
        let date = new Date(dt);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date.getFullYear();
        return mm + '/' + dd + '/' + yyyy;
    }
    let {uid} = useParams();
    const [quizUsersData, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await userService.getQuizUserById(uid);
                setData(data);
            } catch (error) {
                console.error("Error fetching quiz user data:", error);
            }
        };

        fetchData();
    }, [uid]);


    if (quizUsersData && quizUsersData.length > 0) {
        const quizUsersDetailsData = quizUsersData[0];
        return (
            <>
                <>
                    <Header title="Quiz User Details"/>
                    <div className="page-content p-2 p-lg-3">
                        <div className="main-quiz-users-page">
                            <Link className="big-title back fs-6 mb-2 mb-lg-3 d-flex align-items-center c-pointer"
                                  to="/admin/quiz-users">
                                <BiArrowBack className="me-2 icon" size="25"/>Back To Quiz Users List
                            </Link>
                            <div className="big-title mb-2 text-center">{quizUsersDetailsData.email} <span className="fw-normal">({quizUsersDetailsData.account_status})</span></div>
                            <div className="row m-0 vertical-items">
                                <div className="bg-white rounded-3 pt-3 px-2 px-lg-3 text-center">
                                    <div className="row m-0">
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Gender</div>
                                            <div className="quiz-value">{quizUsersDetailsData.gender}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Age</div>
                                            <div className="quiz-value">{quizUsersDetailsData.age}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Goal</div>
                                            <div className="quiz-value">{quizUsersDetailsData.goal}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Income Source</div>
                                            <div className="quiz-value">{quizUsersDetailsData.income_source}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Income Level</div>
                                            <div className="quiz-value">{quizUsersDetailsData.income_level}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Financial Situation</div>
                                            <div className="quiz-value">{quizUsersDetailsData.financial_situation}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Desired Income Level</div>
                                            <div className="quiz-value">{quizUsersDetailsData.income_level_wanted}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Career Flexibility</div>
                                            <div className="quiz-value">{quizUsersDetailsData.career_flexibility}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Desired Work Situation</div>
                                            <div className="quiz-value">{quizUsersDetailsData.work_situation_wanted}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Travel While Working</div>
                                            <div className="quiz-value">{quizUsersDetailsData.travel_while_working}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Content Writing Rate</div>
                                            <div className="quiz-value">{quizUsersDetailsData.content_writing_rate}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">AI Tools Knowledge</div>
                                            <div className="quiz-value">{quizUsersDetailsData.ai_tools_easier_knowledge}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Digital Marketing Rate</div>
                                            <div className="quiz-value">{quizUsersDetailsData.digital_marketing_rate}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Promote Business Rate</div>
                                            <div className="quiz-value">{quizUsersDetailsData.promote_business_rate}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">AI Tools Promotion Knowledge</div>
                                            <div className="quiz-value">{quizUsersDetailsData.ai_tools_promotion_knowledge}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Interested Jobs</div>
                                            <div className="quiz-value">{quizUsersDetailsData.interested_jobs}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Freelancing Rate</div>
                                            <div className="quiz-value">{quizUsersDetailsData.freelancing_freelancing_rate}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Freelance Platforms</div>
                                            <div className="quiz-value">{quizUsersDetailsData.freelance_platforms}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Something Special to Achieve</div>
                                            <div className="quiz-value">{quizUsersDetailsData.something_special_to_achieve}</div>
                                        </div>
                                        <div className="col-6 col-lg-4 col-xxl-3 mb-3 p-2">
                                            <div className="quiz-name">Daily Time to Spend</div>
                                            <div className="quiz-value">{quizUsersDetailsData.daily_time_to_spend}</div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-1">
                                        <div className="text-nowrap small text-black-50">{quizUsersDetailsData.created_date ? `Created at: ${getDate(quizUsersDetailsData.created_date)}` : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </>
        );
    } else {
        return (
            <>
            </>
        )
    }
}

export default QuizUserDetails;