import {PayPalButtons} from "@paypal/react-paypal-js";
import {accountService, orderService} from "../../../_services";

const PaypalPaymentButton = (props) => {

    const createOrder = (data) => {
        // Order is created on the server and the order id is returned
        return fetch("https://api.skillspay.io/payments/paypal/create-paypal-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-token': accountService.getToken()
            },
            // use the "body" param to optionally pass additional order information
            // like product skus and quantities
            body: JSON.stringify({
                product:
                    {
                        description: props.product_name,
                        price: props.price,
                    },
            }),
        })
            .then((response) => response.json())
            .then((order) => order.id);
    };

    const onApprove = (data) => {
        const ORDER_ID = data.orderID;
        // Order is captured on the server and the response is returned to the browser
        return fetch(`https://api.skillspay.io/payments/paypal/capture-paypal-order/${ORDER_ID}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then(async data => {
                if (data.status === "COMPLETED") {
                    try {
                        const orderData = {
                            "product_name": `${props.product_name}`,
                            "total": props.price,
                            "order_id": ORDER_ID,
                            "course_id": props.course_id || "",
                            "order_status": "paid",
                            "payment_method": "paypal",
                            "duration_type": props.duration_type,
                            "duration": props.duration,
                        }
                        props.setPaymentMethod('Paypal')
                        await orderService.createOrder(orderData);
                        localStorage.setItem('ORDER_ID', ORDER_ID);
                        try {
                            const email = props.userDetails.email;
                            const full_name = props.userDetails.full_name;
                            const password = props.userDetails.password;
                            const duration_type = props.duration_type;
                            const duration = props.duration;
                            const response = await fetch('https://api.skillspay.io/auth/signup', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({email, full_name, password, ORDER_ID, duration_type, duration}),
                            });
                            if (response.status === 200) {
                                props.setHolderStep('order-success')
                            }
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    } catch (error) {
                        console.error("Error: ", error);
                    }
                }
            });
    };

    return (
        <div className="custom-paypal-pay-button">
            <PayPalButtons
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
                style={{
                    layout: "horizontal",
                    shape: "rect",
                    color: "white",
                    label: "pay",
                    borderRadius: 5,
                    tagline: false
                }}
            />
        </div>
    );
}

export default PaypalPaymentButton;