import {BiEdit, BiTrash} from "react-icons/bi";
import {useEffect, useState} from "react";
import {lessonService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const LessonRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);
    useEffect(() => {
        props.sendTitleToParent(props.chapter_title);
        props.sendIdToParent(props.course_id);
    }, [props]);

    const handleDeleteLesson = async () => {
        setIsDeleting(true);

        try {
            await lessonService.deleteLesson(props.lesson_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting course:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditLesson = () => {
        props.handleEditLesson(props);
        props.sendHiddenToParent(!props.hidden);
    };

    if (props.lesson_id > 0) {
        return (
            <tr>
                <td>
                    <span>{props.title}</span>
                </td>
                <td>
                    {props.video_url}
                </td>
                <td>
                    {props.order_num}
                </td>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
                <td>
                <span className="text-center">
                    <button className="border-0 bg-transparent" onClick={handleEditLesson} disabled={!props.hidden}>
                        <BiEdit size="22" className="edit-icon"/>
                    </button>
                    {isDeleting ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <BiTrash onClick={handleDeleteLesson} size="22" className="remove-icon mx-auto"/>
                    )}
                </span>
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
                <td colSpan="5" className="text-center">There is no lesson yet in this chapter</td>
            </tr>
        );
    }
}

export default LessonRow;