import Axios from "../ServiceCaller";

// Templates
let getCustomTemplates = async () => {
    const { data } = await Axios.get("email-marketing/custom-templates");
    return data.CustomTemplates;
};
let getTemplates = async () => {
    const { data } = await Axios.get("email-marketing/templates");
    return data.Templates;
};

let createTemplate = async (templateData) => {
    const { data } = await Axios.post("email-marketing/templates", templateData);
    return data;
};

let updateTemplate = async (templateData) => {
    const { data } = await Axios.put("email-marketing/templates", templateData);
    return data;
};

let getTemplateById = async (id) => {
    const { data } = await Axios.get(`email-marketing/templates/${id}`);
    return data.Template;
};

let deleteTemplate = async (template_id) => {
    try {
        const response = await Axios.delete(`email-marketing/templates`, {
            data: { id: template_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Template:", error);
        throw error;
    }
};

// Campaigns
let getCampaigns = async () => {
    const { data } = await Axios.get("email-marketing/campaigns");
    return data.Campaigns;
};

let createCampaign = async (campaignData) => {
    const { data } = await Axios.post("email-marketing/campaigns", campaignData);
    return data;
};

let updateCampaign = async (campaignData) => {
    const { data } = await Axios.put("email-marketing/campaigns", campaignData);
    return data;
};

let getCampaignById = async (id) => {
    const { data } = await Axios.get(`email-marketing/campaigns/${id}`);
    return data.Campaign;
};

let deleteCampaign = async (campaign_id) => {
    try {
        const response = await Axios.delete(`email-marketing/campaigns`, {
            data: { id: campaign_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Campaign:", error);
        throw error;
    }
};

// Targeted Campaigns
let getTargetedCampaigns = async () => {
    const { data } = await Axios.get("email-marketing/targeted-campaigns");
    return data.TargetedCampaigns;
};

let createTargetedCampaign = async (targetedCampaignData) => {
    const { data } = await Axios.post("email-marketing/targeted-campaigns", targetedCampaignData);
    return data;
};

let getTargetedCampaignById = async (id) => {
    const { data } = await Axios.get(`email-marketing/targeted-campaigns/${id}`);
    return data.TargetedCampaign;
};

let deleteTargetedCampaign = async (targeted_campaign_id) => {
    try {
        const response = await Axios.delete(`email-marketing/targeted-campaigns`, {
            data: { id: targeted_campaign_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Targeted Campaign:", error);
        throw error;
    }
};

let scheduleSendTargetedCampaigns = async (targeted_campaign_id) => {
    const { data } = await Axios.post("email-marketing/targeted-campaigns/send", targeted_campaign_id);
    return data;
};


// Schedule Sending Email Marketing Mails
let scheduleSendEmail = async (userEmail) => {
    const { data } = await Axios.post("schedule-send-email-marketing", userEmail);
    return data;
};

// SendGrid
let getSendGridKeysSettings = async () => {
    const { data } = await Axios.get("email-marketing/sendgrid/keys-settings");
    return data.SendGridKeysSettings;
};

let updateSendGridKeysSettings = async (SendGridKeysSettingsData) => {
    const { data } = await Axios.put("email-marketing/sendgrid/keys-settings", SendGridKeysSettingsData);
    return data;
};

export const emailService = {
    // Templates
    getCustomTemplates,
    getTemplates,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    getTemplateById,
    // Campaigns
    getCampaigns,
    createCampaign,
    updateCampaign,
    deleteCampaign,
    getCampaignById,
    // Targeted Campaigns
    getTargetedCampaigns,
    createTargetedCampaign,
    deleteTargetedCampaign,
    getTargetedCampaignById,
    scheduleSendTargetedCampaigns,
    // Schedule Sending Email Marketing Mails
    scheduleSendEmail,
    // SendGrid
    getSendGridKeysSettings,
    updateSendGridKeysSettings,

};