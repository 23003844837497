import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper/modules";
import slider_image from "../../../assets/images/slider_image.jpg";
import {useEffect, useState} from "react";
import {settingService} from "../../../_services";
import happy_clients from "../../../assets/images/public/happy_clients.png"
import {BiMailSend, BiSolidHeart} from "react-icons/bi";

const HomePageSlider = () => {
    const [sliderSettingsData, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await settingService.getSliderSettings();
                setData(data);
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="home-slider mt-3 mb-5 home-slider-desktop">
            <Swiper
                spaceBetween={20}
                pagination={true}
                slidesPerView={1}
                loop={true}
                autoplay={
                    sliderSettingsData.length > 0
                        ? {
                            delay: sliderSettingsData[0].slide_duration,
                            disableOnInteraction: false,
                        }
                        : {
                            delay: 2500,
                            disableOnInteraction: false,
                        }
                }
                modules={[Autoplay, Pagination]}
                className="mySwiper rounded-4">
                {sliderSettingsData.length > 0 ? (
                        sliderSettingsData.map((slider) => (
                            <SwiperSlide key={slider.id}>
                                <div className="position-relative rounded-4 mt-3 mb-5 h-100">
                                    <img className="rounded-4 mt-3 mb-5 img-fluid object-fit-cover"
                                         src={`https://api.skillspay.io/thedss/slider-images/${slider.slider_image}`}
                                         alt="slider slide item"
                                    />
                                </div>
                            </SwiperSlide>
                        ))
                    )
                    : (
                        <SwiperSlide>
                            <div className="position-relative rounded-4 mt-3 mb-5 h-100">
                                <img className="rounded-4 mt-3 mb-5 img-fluid" src={slider_image} alt=""/>
                            </div>
                        </SwiperSlide>
                    )}
            </Swiper>
        </div>
    );
}

export default HomePageSlider;