import {useEffect, useState} from "react";
import {settingService} from "../../../_services";
import SliderSettingsForm from "./SliderSettingsForm";
import SliderSettingsRow from "./SliderSettingsRow";

const SliderSettings = () => {
    const [sliderSettingsData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);
    const [sliderDurationFromChild, setSliderDurationFromChild] = useState('');
    const getSliderDurationFromChild = (slider_duration) => {
        setSliderDurationFromChild(slider_duration);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await settingService.getSliderSettings();
                setData(data);
            } catch (error) {
                console.error("Error fetching slider settings data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const setFormHiddenAdd = () => {
        setHidden(!hidden);
    }

    return (
        <div className="slider-settings my-3 px-2">
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="big-title me-3 mb-2 mb-md-0">Slider Images</div>
                <div className="add-btn-holder">
                    <button className="btn btn-primary py-1 btn-sm" type="button" disabled={!hidden}
                            onClick={setFormHiddenAdd}>Edit Slider Settings
                    </button>
                </div>
            </div>
            <div className={hidden ? "d-none" : "d-block"}>
                <SliderSettingsForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                    slider_duration={sliderDurationFromChild}
                />
            </div>
            <div className="row m-0 vertical-items">
                {sliderSettingsData.length > 0 ? (
                        sliderSettingsData.map((sliderSetting) => (
                            <SliderSettingsRow
                                key={sliderSetting.id}
                                slider_id={sliderSetting.id}
                                slider_image={sliderSetting.slider_image}
                                slide_duration={sliderSetting.slide_duration}
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                sendSliderDurationToParent={getSliderDurationFromChild}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        ))
                    )
                    : (
                        <div className="text-center">There is no slider image yet</div>
                    )}
            </div>
        </div>
    );
}

export default SliderSettings;