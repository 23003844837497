import {Link} from "react-router-dom";
import defaultCourseBg from "../../../assets/images/default_course_bg.jpg";
import {useId} from "react";
import {useQuery} from "react-query";
import {courseService} from "../../../_services";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CourseBoxStarted = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => courseService.getCourses());

    if (isLoading) {
        return (
            <span></span>);
    }

    return (
        <>
            {data.length > 0 && (
                <div className="video-courses-holder my-4">
                    <div className="main-title mb-2">Continue Learning</div>
                    {data.map((course) => (
                        course.course_progress > 0 && (
                            <Link to={`/courses/course/${course.id}`} key={course.id}
                                  className="d-inline-block conitnue-course-box mb-3 rounded-2 w-100 p-1">
                                <div className="course-box d-flex">
                                    <div className="w-25 p-2">
                                        {course.background_name ? (
                                            <img
                                                src={`https://api.skillspay.io/thedss/images/${course.background_name}`}
                                                alt={course.title}
                                                className="img-fluid w-100 object-fit-cover rounded-3"

                                            />
                                        ) : (
                                            <img className="img-fluid w-100 rounded-3 object-fit-cover rounded-3"
                                                 alt={course.title}
                                                 src={defaultCourseBg}
                                            />
                                        )}
                                    </div>
                                    <div className="w-75 d-flex align-items-center">
                                        <div className="infos-holder w-75">
                                            <div className="text-white mb-2">
                                                Keep Improving
                                            </div>
                                            <div className="small text-white-50">
                                                {`${course.title.substring(0, 25)}`}
                                            </div>
                                        </div>
                                        <div className="infos-holder w-25 text-center">
                                            <CircularProgressbar
                                                value={parseInt(course.course_progress)}
                                                text={`${parseInt(course.course_progress)}%`}
                                                styles={buildStyles({
                                                    textSize: '25px',
                                                    pathColor: '#fff',
                                                    textColor: '#fff',
                                                    trailColor: '#d6d6d6',
                                                    backgroundColor: '#3e98c7',
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    ))}
                </div>
            )}
        </>

    )
        ;
};

export default CourseBoxStarted;