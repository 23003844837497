import {Route, Routes} from "react-router-dom";
import Login from "./Login";
import Quiz from "./Quiz";
import Error from "../../_utils/Error";
import Signup from "./Signup";

const AuthRouter = () => {
    return (
        <Routes>
            <Route index element={<Login/>}/>
            <Route path="/login" element={<Login/>}/>
            {/*<Route path="/signup" element={<Signup/>}/>*/}
            <Route path="/quiz" element={<Quiz/>}/>
            <Route path="*" element={<Error/>}/>
        </Routes>
    );
}

export default AuthRouter;