import React, {useEffect, useState} from "react";
import {emailService} from "../../../_services";
import {NavLink} from "react-router-dom";
import {quizQuestions} from "../../../pages/auth/QuizQuestions";

const TargetedCampaignForm = (props) => {
    const [isCreating, setIsCreating] = useState(false);
    const [templatesData, setData] = useState([]);
    const [customTemplatesData, setCustomData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [params, setParamsValues] = useState({
        campaign_name: "",
        from_name: "",
        subject: "",
        custom_template_id: "",
        header_first_title: "",
        header_second_title: "",
        template_id: 0,
        button_text_content: "",
        button_link: "",
        footer_first_text: "",
        footer_second_text: "",
        targeted_type: "status",
        targeted_value: "All",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await emailService.getTemplates();
                const customData = await emailService.getCustomTemplates();
                setData(data);
                setCustomData(customData);
            } catch (error) {
                console.error("Error fetching templates data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const dependentOptions = {
        status: [
            {id: "status1", value: "All"},
            {id: "status2", value: "Not Paid"}
        ],
        age: [
            {id: "age1", value: "18-24"},
            {id: "age2", value: "25-34"},
            {id: "age3", value: "35-44"},
            {id: "age4", value: "45"}
        ],
        goal: [
            {id: "goal1", value: "Grow wealth"},
            {id: "goal2", value: "Be my own boss"},
            {id: "goal3", value: "Travel more"},
            {id: "goal4", value: "Other"}
        ],
        income_source: [
            {id: "income_source1", value: "9-to-5 job"},
            {id: "income_source2", value: "Self Employed"},
            {id: "income_source3", value: "Other"}
        ],
        something_special_to_achieve: [
            {id: "something_special_to_achieve1", value: "Buy a house"},
            {id: "something_special_to_achieve2", value: "Vacation"},
            {id: "something_special_to_achieve3", value: "Buy a new car"},
            {id: "something_special_to_achieve4", value: "Save over $30,000"}
        ],
        financial_situation: [
            {id: "financial_situation1", value: "I'm struggling to meet my financial goals"},
            {id: "financial_situation2", value: "I would like to have more stability"},
            {id: "financial_situation3", value: "I’m not sure"},
            {id: "financial_situation4", value: "I'm comfortable"}
        ]
    };

    const selectedOptions = dependentOptions[params.targeted_type] || [];

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("campaign_name", params.campaign_name);
            formData.append("from_name", params.from_name);
            formData.append("subject", params.subject);
            formData.append("custom_template_id", params.custom_template_id);
            formData.append("header_first_title", params.header_first_title);
            formData.append("header_second_title", params.header_second_title);
            formData.append("template_id", params.template_id);
            formData.append("button_text_content", params.button_text_content);
            formData.append("button_link", params.button_link);
            formData.append("footer_first_text", params.footer_first_text);
            formData.append("footer_second_text", params.footer_second_text);
            formData.append("targeted_type", params.targeted_type);
            formData.append("targeted_value", params.targeted_value);

            if (params.template_id === 0 || params.custom_template_id === 0) {
                return;
            }

            await emailService.createTargetedCampaign(formData).then(async res => {
                const new_targeted_campaign_id = {
                    "targeted_campaign_id": res.campaign_id,
                };
                await emailService.scheduleSendTargetedCampaigns(new_targeted_campaign_id);
            })

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);

            setParamsValues({
                campaign_name: "",
                from_name: "",
                subject: "",
                custom_template_id: 0,
                header_first_title: "",
                header_second_title: "",
                template_id: 0,
                button_text_content: "",
                button_link: "",
                footer_first_text: "",
                footer_second_text: "",
                targeted_type: "status",
                targeted_value: "All",
            });
        } catch (error) {
            console.error("Error Creating/Updating campaign:", error);
        } finally {
            setIsCreating(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            campaign_name: "",
            from_name: "",
            subject: "",
            custom_template_id: 0,
            header_first_title: "",
            header_second_title: "",
            template_id: 0,
            button_text_content: "",
            button_link: "",
            footer_first_text: "",
            footer_second_text: "",
            targeted_type: "status",
            targeted_value: "All",
        });
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="campaigns-form main-form-holder add-course-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center">Add a New Campaign</div>
            <div className="form-holder">
                <form onSubmit={onSubmit} encType="multipart/form-data">
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="campaign_name">
                            Campaign Name:
                        </label>
                        <div className="campaign_name-holder d-flex align-items-center">
                            <div className="campaign_name-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Name Of The Campaign"
                                    value={params.campaign_name}
                                    onChange={onChange}
                                    name="campaign_name"
                                    id="campaign_name"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="from_name">
                            From Name:
                        </label>
                        <div className="from_name-holder d-flex align-items-center">
                            <div className="from_name-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="From Name"
                                    value={params.from_name}
                                    onChange={onChange}
                                    name="from_name"
                                    id="from_name"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="subject">
                            Subject:
                        </label>
                        <div className="subject-holder d-flex align-items-center">
                            <div className="subject-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Subject of The Campaign"
                                    value={params.subject}
                                    onChange={onChange}
                                    name="subject"
                                    id="subject"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="custom_template_id">
                            Email Template:
                        </label>
                        <div className="template_id-holder d-flex align-items-center">
                            <div className="template_id-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.custom_template_id}
                                    onChange={onChange}
                                    name="custom_template_id"
                                    id="custom_template_id"
                                    required
                                >
                                    <option value="0">Please Select a Template</option>
                                    {customTemplatesData.length > 0 && (
                                        customTemplatesData.map((item, index) => (
                                            <option value={item.id} key={index}>{item.custom_template_name}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    {customTemplatesData.length > 0 && (*/}
                    {/*        customTemplatesData.map((item, index) => (*/}
                    {/*            <div className="col-md-6 col-xl-4 mb-3 text-center" key={index}>*/}
                    {/*                <input type="radio" value={item.custom_template_id}*/}
                    {/*                       className="choices-input"*/}
                    {/*                       onChange={onChange}*/}
                    {/*                       id={item.custom_template_id}*/}
                    {/*                       name="custom_template_id"*/}
                    {/*                       checked={params.custom_template_id === item.custom_template_id}*/}
                    {/*                />*/}
                    {/*                <label*/}
                    {/*                    className="c-pointer choice-content position-relative"*/}
                    {/*                    htmlFor={item.custom_template_id}>*/}
                    {/*                    <div*/}
                    {/*                        className="circle position-absolute bottom-0 start-50 translate-middle-x bg-transparent"></div>*/}
                    {/*                    <div*/}
                    {/*                        className="d-flex align-items-end justify-content-center h-100 bg-holder position-relative"*/}
                    {/*                        style={{*/}
                    {/*                            backgroundImage: `url(https://api.skillspay.io/thedss/images/${item.custom_template_image})`*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                    </div>*/}
                    {/*                </label>*/}
                    {/*            </div>*/}
                    {/*        ))*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="header_first_title">
                            First Header Title:
                        </label>
                        <div
                            className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                            <textarea
                                className="textarea form-control py-2"
                                placeholder="First Title In The Header"
                                value={params.header_first_title}
                                onChange={onChange}
                                name="header_first_title"
                                id="header_first_title"
                                rows={2}
                                required
                            />
                            </div>
                        </div>
                    </div>
                    {params.custom_template_id === '1' && (
                        <div className="form-group mb-4">
                            <label className="mb-1" htmlFor="header_second_title">
                                Second Header Title (Optional):
                            </label>
                            <div
                                className="description-holder d-flex align-items-center">
                                <div className="description-input flex-grow-1">
                                <textarea
                                    className="textarea form-control py-2"
                                    placeholder="Second Title In The Header"
                                    value={params.header_second_title}
                                    onChange={onChange}
                                    name="header_second_title"
                                    id="header_second_title"
                                    rows={3}
                                />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="template_id">
                            Email Template Content:
                        </label>
                        <div className="template_id-holder d-flex align-items-center">
                            <div className="template_id-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.template_id}
                                    onChange={onChange}
                                    name="template_id"
                                    id="template_id"
                                    required
                                >
                                    <option value="0">Please Select a Template Content</option>
                                    {templatesData.length > 0 && (
                                        templatesData.map((item, index) => (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="button_text_content">
                            Content Of Button:
                        </label>
                        <div className="button-text-holder d-flex align-items-center">
                            <div className="button-text-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Text Of Button"
                                    value={params.button_text_content}
                                    onChange={onChange}
                                    name="button_text_content"
                                    id="button_text_content"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="button_link">
                            Link Of Button:
                        </label>
                        <div className="button-text-holder d-flex align-items-center">
                            <div className="button-text-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="https://....."
                                    value={params.button_link}
                                    onChange={onChange}
                                    name="button_link"
                                    id="button_link"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="footer_first_text">
                            First Footer Text (Optional):
                        </label>
                        <div
                            className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                            <textarea
                                className="textarea form-control py-2"
                                placeholder="First Text In The Footer"
                                value={params.footer_first_text}
                                onChange={onChange}
                                name="footer_first_text"
                                id="footer_first_text"
                                rows={2}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="footer_second_text">
                            Second Footer Text (Optional):
                        </label>
                        <div
                            className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                            <textarea
                                className="textarea form-control py-2"
                                placeholder="Second Text In The Footer"
                                value={params.footer_second_text}
                                onChange={onChange}
                                name="footer_second_text"
                                id="footer_second_text"
                                rows={2}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="targeted_type">
                            Targeted Type:
                        </label>
                        <div className="targeted_type-holder d-flex align-items-center">
                            <div className="targeted_type-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.targeted_type}
                                    onChange={onChange}
                                    name="targeted_type"
                                    id="targeted_type"
                                    required
                                >
                                    <option value="status">Account Status</option>
                                    <option value="age">Age</option>
                                    <option value="goal">Goal</option>
                                    <option value="income_source">Income Source</option>
                                    <option value="something_special_to_achieve">Target To Achieve</option>
                                    <option value="financial_situation">Financial Situation</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="targeted_value">
                            Targeted Value:
                        </label>
                        <div className="targeted_value-holder d-flex align-items-center">
                            <div className="targeted_value-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.targeted_value}
                                    onChange={onChange}
                                    name="targeted_value"
                                    id="targeted_value"
                                    required
                                >
                                    {selectedOptions.map(option => (
                                        <option key={option.id} value={option.value}>{option.value}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {isCreating ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default TargetedCampaignForm;