import React, {useEffect, useState} from "react";
import {chapterService} from "../../../_services";

const ChapterForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        title: "",
        order_num: "",
    });

    const [file, setFile] = useState(null);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title: props.chapter.title,
                order_num: props.chapter.order_num,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                title: "",
                order_num: "",
            });
        }
    }, [props.editMode, props.chapter]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("course_id", props.course_id);
            formData.append("title", params.title);
            formData.append("order_num", params.order_num);
            formData.append("image_file", file);

            if (isCreatingMode) {
                await chapterService.createChapter(formData);
            } else {
                formData.append("id", props.chapter.chapter_id);
                await chapterService.updateChapter(formData);
            }
            setParamsValues({
                title: "",
                order_num: "",
            });
            setFile(null);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
        } catch (error) {
            console.error("Error Creating/Updating chapter:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-chapter-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center">Add a New Chapter</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="title">
                            Title:
                        </label>
                        <div className="title-holder d-flex align-items-center">
                            <div className="title-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Title of the Chapter"
                                    value={params.title}
                                    onChange={onChange}
                                    name="title"
                                    id="title"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="order_num">
                            Order:
                        </label>
                        <div className="order_num-holder d-flex align-items-center">
                            <div className="order_num-input flex-grow-1">
                                <input
                                    type="number"
                                    className="form-control text py-2"
                                    placeholder="Chapter Order Within The Course"
                                    value={params.order_num}
                                    onChange={onChange}
                                    name="order_num"
                                    id="order_num"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="file">
                            Upload File:
                        </label>
                        <div className="file-holder d-flex align-items-center">
                            <div className="file-input flex-grow-1">
                                <input
                                    type="file"
                                    className="form-control py-2"
                                    onChange={onFileChange}
                                    name="file"
                                    id="file"
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>
                    {file && (
                        <div className="form-group mb-4">
                            <label className="mb-1" htmlFor="selectedImage">
                                Selected Image:
                            </label>
                            <div className="selected-image-holder">
                                <div className="selected-image w-25 mx-auto">
                                    <img
                                        style={{maxHeight: "200px"}}
                                        src={URL.createObjectURL(file)}
                                        alt="Selected Background"
                                        className="selected-image img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="text-end">
                        <button type="button" disabled={isLoading} className="btn btn-secondary me-3" onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary px-4" disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChapterForm;
