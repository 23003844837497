const PeriodCard = (props) => {
    return (
        <div className="col-lg-4 mb-3">
            <div
                className="infos-holder shadow-sm rounded-2 py-3 px-2 text-center">
                <div className="value fw-bold">{props.value}</div>
                <div className="name text-black-50">{props.name}</div>
            </div>
        </div>
    );
}

export default PeriodCard;