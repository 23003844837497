import React, {useEffect, useState} from "react";
import {courseService} from "../../../_services";
import {IoWarning} from "react-icons/io5";

const CourseForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        title: "",
        level: "Beginner",
        description: "",
        duration: "",
        received_skills: "",
        intro_video: "",
        what_will_you_learn: "",
        is_featured: 0,
    });

    const [file, setFile] = useState(null);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title: props.course.title,
                level: props.course.level,
                description: props.course.description,
                duration: props.course.duration,
                received_skills: props.course.received_skills,
                intro_video: props.course.intro_video,
                what_will_you_learn: props.course.what_will_you_learn,
                is_featured: props.course.is_featured,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                title: "",
                level: "Beginner",
                description: "",
                duration: "",
                received_skills: "",
                intro_video: "",
                what_will_you_learn: "",
                is_featured: 0,
            });
        }
    }, [props.editMode, props.course]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("title", params.title);
            formData.append("description", params.description);
            formData.append("duration", params.duration);
            formData.append("level", params.level);
            formData.append("received_skills", params.received_skills);
            formData.append("intro_video", params.intro_video);
            formData.append("what_will_you_learn", params.what_will_you_learn);
            formData.append("is_featured", params.is_featured);
            formData.append("background_file", file);

            if (isCreatingMode) {
                await courseService.createCourse(formData);
            } else {
                formData.append("id", props.course.course_id);
                await courseService.updateCourse(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                title: "",
                level: "Beginner",
                description: "",
                duration: "",
                received_skills: "",
                intro_video: "",
                what_will_you_learn: "",
                is_featured: 0,
            });
            setFile(null);
        } catch (error) {
            console.error("Error Creating/Updating course:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-course-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center">Add a New Course</div>
            <div className="form-holder">
                <form onSubmit={onSubmit} encType="multipart/form-data">
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="title">
                            Title:
                        </label>
                        <div className="title-holder d-flex align-items-center">
                            <div className="title-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Title of the Course"
                                    value={params.title}
                                    onChange={onChange}
                                    name="title"
                                    id="title"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="description">
                            Description:
                        </label>
                        <div
                            className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                            <textarea
                                className="textarea form-control py-2"
                                placeholder="Description of the Course"
                                value={params.description}
                                onChange={onChange}
                                name="description"
                                id="description"
                                rows={3}
                                required
                            />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="is_featured">
                            Featured Course:
                        </label>
                        <div className="is-featured-holder d-flex align-items-center">
                            <div className="is-featured-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.is_featured}
                                    onChange={onChange}
                                    name="is_featured"
                                    id="is_featured"
                                    required
                                >
                                    <option value="0">Not Featured Course</option>
                                    <option value="1">Featured Course</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="level">
                            Level:
                        </label>
                        <div className="level-holder d-flex align-items-center">
                            <div className="level-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.level}
                                    onChange={onChange}
                                    name="level"
                                    id="level"
                                    required
                                >
                                    <option value="Select">Select</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Advanced">Advanced</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="duration">
                            Duration:
                        </label>
                        <div className="duration-holder d-flex align-items-center">
                            <div className="duration-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Duration of the Course in Hours"
                                    value={params.duration}
                                    onChange={onChange}
                                    name="duration"
                                    id="duration"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1 d-flex align-items-center" htmlFor="received_skills">
                            Received Skills <span className="ms-1 text-danger">(<IoWarning className="text-warning mb-1"
                                                                                           size="25"/>Separated by Comma<IoWarning
                            className="text-warning mb-1" size="25"/>)</span>:
                        </label>
                        <div className="skills-holder d-flex align-items-center">
                            <div className="skills-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Received Skills Of This Course"
                                    value={params.received_skills}
                                    onChange={onChange}
                                    name="received_skills"
                                    id="received_skills"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="what_will_you_learn">
                            What Will You Learn <span className="ms-1 text-danger">(<IoWarning
                            className="text-warning mb-1" size="25"/>Separated by Line<IoWarning
                            className="text-warning mb-1" size="25"/>)</span>:
                        </label>
                        <div className="learn-holder d-flex align-items-center">
                            <div className="learn-input flex-grow-1">
                                <textarea
                                    className="form-control text py-2"
                                    placeholder="What will you learn"
                                    value={params.what_will_you_learn}
                                    onChange={onChange}
                                    name="what_will_you_learn"
                                    id="what_will_you_learn"
                                    rows={5}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="intro_video">
                            Course Introduction:
                        </label>
                        <div className="intro_video-holder d-flex align-items-center">
                            <div className="intro_video-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Link of Introduction Video"
                                    value={params.intro_video}
                                    onChange={onChange}
                                    name="intro_video"
                                    id="intro_video"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="file">
                            Upload File:
                        </label>
                        <div className="file-holder d-flex align-items-center">
                            <div className="file-input flex-grow-1">
                                <input
                                    type="file"
                                    className="form-control py-2"
                                    onChange={onFileChange}
                                    name="file"
                                    id="file"
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>
                    {file && (
                        <div className="form-group mb-4">
                            <label className="mb-1" htmlFor="selectedImage">
                                Selected Image:
                            </label>
                            <div className="selected-image-holder">
                                <div className="selected-image w-25 mx-auto">
                                    <img
                                        style={{maxHeight: "200px"}}
                                        src={URL.createObjectURL(file)}
                                        alt="Selected Background"
                                        className="selected-image img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="text-end">
                        <button type="button" disabled={isLoading} className="btn btn-secondary me-3" onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary px-4" disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CourseForm;
