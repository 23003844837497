import {BiCheckCircle} from "react-icons/bi";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {lessonService} from "../../../_services";
import Accordion from 'react-bootstrap/Accordion';

const ChapterLessons = (props) => {
    let chapter_id = props.id;

    const [lessonsData, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await lessonService.getLessonsForUser(chapter_id);
                setData(data);
            } catch (error) {
                console.error("Error fetching lessons data:", error);
            }
        };

        fetchData();
    }, [chapter_id]);
    return (
        <Accordion.Body>
            <div className="lessons-holder">
                {lessonsData.length > 0 ? (
                    lessonsData.map((lesson, index) => (
                        lesson.lesson_completed > 0 ? (
                                <div className="bg-white border border-1 p-3 d-flex align-items-center justify-content-between rounded-2 mb-2"
                                     key={index}>
                                    <div className="d-flex align-items-center">
                                        <div className="icon-holder me-2"><BiCheckCircle color="green" size="25"/></div>
                                        <div className="lesson-title">{lesson.title}</div>
                                    </div>
                                    <div>
                                        <Link to={`/courses/lesson/${lesson.id}`}>
                                            <button className="rounded-2 border-0 small btn btn-sm text-white" style={{backgroundColor: "#0EB14D"}}>Watch Lesson Again</button>
                                        </Link>
                                    </div>
                                </div>
                            ) :
                            <div className="bg-white border border-1 p-3 rounded-2 mb-2" key={index}>
                                <div className="lesson-info d-flex align-items-center">
                                    <div className="icon-holder me-2"><BiCheckCircle
                                        color={lesson.lesson_completed > 0 ? '#0EB14D' : 'gray'} size="25"/></div>
                                    <div className="lesson-title">{lesson.title}</div>
                                </div>
                                <div className="btn-holder mt-3 text-center">
                                    <Link to={`/courses/lesson/${lesson.id}`}>
                                        <button className="continue-btn px-4 py-2 rounded-2">Continue Lesson</button>
                                    </Link>
                                </div>
                            </div>

                    ))
                ) : (
                    <div className="text-center">There is no lesson yet</div>
                )
                }
            </div>
        </Accordion.Body>
    );
}

export default ChapterLessons;