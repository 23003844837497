import React, {useEffect, useState} from "react";
import {pricingPlanService} from "../../../_services";

const PricingPlanForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);

    const [params, setParamsValues] = useState({
        plan_name: "",
        description: "",
        duration_type: "monthly",
        duration: 1,
        regular_price: "",
        sale_price: "",
    });

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                plan_name: props.pricingPlan.plan_name,
                description: props.pricingPlan.description,
                duration_type: props.pricingPlan.duration_type,
                duration: props.pricingPlan.duration,
                regular_price: props.pricingPlan.regular_price,
                sale_price: props.pricingPlan.sale_price,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                plan_name: "",
                description: "",
                duration_type: "monthly",
                duration: 1,
                regular_price: "",
                sale_price: "",
            });
        }
    }, [props.editMode, props.pricingPlan]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("plan_name", params.plan_name);
            formData.append("description", params.description);
            formData.append("duration_type", params.duration_type);
            if (params.duration_type === "monthly" || params.duration_type === "yearly" || params.duration_type === "lifetime" || params.duration_type === "pdf_course" || params.duration_type === "video_course") {
                formData.append("duration", 1);
            } else {
                formData.append("duration", params.duration);
            }
            formData.append("regular_price", params.regular_price);
            formData.append("sale_price", params.sale_price);

            if (isCreatingMode) {
                await pricingPlanService.createPricingPlan(formData);
            } else {
                formData.append("id", props.pricingPlan.pricing_plan_id);
                await pricingPlanService.updatePricingPlan(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                plan_name: "",
                description: "",
                duration_type: "monthly",
                duration: 1,
                regular_price: "",
                sale_price: "",
            });
        } catch (error) {
            console.error("Error Creating/Updating Pricing Plan:", error);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-pricingPlan-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center">Add a New pricingPlan</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="plan_name">
                            Plan Name:
                        </label>
                        <div className="plan_name-holder d-flex align-items-center">
                            <div className="plan_name-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Name of the Pricing Plan"
                                    value={params.plan_name}
                                    onChange={onChange}
                                    name="plan_name"
                                    id="plan_name"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="description">
                            Plan Description:
                        </label>
                        <div className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                                <textarea
                                    className="textarea form-control py-2"
                                    placeholder="Description of the Pricing Plan"
                                    value={params.description}
                                    onChange={onChange}
                                    name="description"
                                    id="description"
                                    rows={2}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="form-group">
                                <label className="mb-1" htmlFor="duration_type">
                                    Duration Type:
                                </label>
                                <div className="duration-type-holder d-flex align-items-center">
                                    <div className="duration-type-input flex-grow-1">
                                        <select
                                            className="form-control py-2"
                                            value={params.duration_type}
                                            onChange={onChange}
                                            name="duration_type"
                                            id="duration_type"
                                            required
                                        >
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                            <option value="number_of_days">Number of Days</option>
                                            <option value="number_of_months">Number of Months</option>
                                            <option value="number_of_years">Number of Years</option>
                                            <option value="pdf_course">PDF Course</option>
                                            <option value="video_course">Video Course</option>
                                            <option value="lifetime">Lifetime</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="mb-1" htmlFor="duration">
                                    Duration:
                                </label>
                                <div className="duration-holder d-flex align-items-center">
                                    <div className="duration-input flex-grow-1">
                                        <input
                                            disabled={params.duration_type === "monthly" || params.duration_type === "yearly" || params.duration_type === "lifetime" || params.duration_type === "pdf_course" || params.duration_type === "video_course"}
                                            type="number"
                                            className="form-control text py-2"
                                            placeholder="Duration Of Subscription"
                                            value={params.duration}
                                            onChange={onChange}
                                            name="duration"
                                            id="duration"
                                            required={params.duration_type === "number_of_days" || params.duration_type === "number_of_months" || params.duration_type === "number_of_years"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="regular_price">
                            Regular Price:
                        </label>
                        <div className="regular_price-holder d-flex align-items-center">
                            <div className="regular_price-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Price of Plan Before Discount"
                                    value={params.regular_price}
                                    onChange={onChange}
                                    name="regular_price"
                                    id="regular_price"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="sale_price">
                            Regular Price:
                        </label>
                        <div className="sale_price-holder d-flex align-items-center">
                            <div className="sale_price-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Price of Plan After Discount"
                                    value={params.sale_price}
                                    onChange={onChange}
                                    name="sale_price"
                                    id="sale_price"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary px-4">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PricingPlanForm;