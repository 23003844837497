import {
    BiBookContent,
    BiTime,
    BiBarChartAlt,
    BiCheckCircle,
    BiBulb
} from "react-icons/bi";
import {RiPagesLine} from "react-icons/ri";

const CourseDetails = (props) => {
    let received_skills = Array.isArray(props.received_skills && props.received_skills.split(',')) ? props.received_skills.split(',') : [];
    let courseLearns = Array.isArray(props.what_will_you_learn && props.what_will_you_learn.split('\n')) ? props.what_will_you_learn.split('\n') : [];
    return (
        <div className="course-details px-3">
            <div className="main-title">Course details</div>
            <div className="course-details-holder row g-3 mt-0 mb-4">
                <div className="col-6">
                    {props.pdf ? (
                        <div
                            className="box-card shadow-sm rounded-2 py-2 px-3 d-flex justify-content-between align-items-center">
                            <div className="infos-holder">
                                <div className="value fw-bold">{props.coursePages} Pages</div>
                                <div className="small text-black-50">Content</div>
                            </div>
                            <div className="icon-holder"><RiPagesLine className="icon" size="40"/></div>
                        </div>
                    ) : (
                        <div
                            className="box-card shadow-sm rounded-2 py-2 px-3 d-flex justify-content-between align-items-center">
                            <div className="infos-holder">
                                <div className="value fw-bold">{props.courseDuration} Hours</div>
                                <div className="small text-black-50">Time</div>
                            </div>
                            <div className="icon-holder"><BiTime className="icon" size="40"/></div>
                        </div>
                    )}
                </div>
                <div className="col-6">
                    <div
                        className="box-card shadow-sm rounded-2 py-2 px-3 d-flex justify-content-between align-items-center">
                        <div className="infos-holder">
                            <div className="value fw-bold">{props.courseLevel}</div>
                            <div className="small text-black-50">Level</div>
                        </div>
                        <div className="icon-holder"><BiBarChartAlt className="icon" size="40"/></div>
                    </div>
                </div>
                <div className="col-6">
                    <div
                        className="box-card shadow-sm rounded-2 py-2 px-3 d-flex justify-content-between align-items-center">
                        <div className="infos-holder">
                            <div className="value fw-bold">{props.courseLessonsNumber}</div>
                            <div className="small text-black-50">Lessons</div>
                        </div>
                        <div className="icon-holder"><BiBookContent className="icon" size="40"/></div>
                    </div>
                </div>
                <div className="col-6">
                    <div
                        className="box-card shadow-sm rounded-2 py-2 px-3 d-flex justify-content-between align-items-center">
                        <div className="infos-holder">
                            <div className="value fw-bold">{props.courseLessonsQuizzes}</div>
                            <div className="small text-black-50">Quizzes</div>
                        </div>
                        <div className="icon-holder"><BiBulb className="icon" size="40"/></div>
                    </div>
                </div>
            </div>
            <div className="main-title">Course description</div>
            <div className="course-description-holder mb-4">
                <div className="description mt-2">{props.courseDescription}</div>
            </div>
            <div className="main-title">Received skills</div>
            <div className="received-skills-holder mb-4">
                <div className="skills-holder mt-2">
                    {
                        received_skills.map((skill, index) =>
                            <div className="skill p-1 rounded-2 small d-inline-block m-1" key={index}>{skill}</div>
                        )
                    }
                </div>
            </div>
            <div className="main-title">What will you learn</div>
            <div className="learns-holder">
                <div className="learn mt-2">
                    {
                        courseLearns.map((learn, index) =>
                            <div className="d-flex mb-2 align-items-start" key={index}>
                                <div className="icon-holder me-2"><BiCheckCircle className="icon" size="20"/></div>
                                <div className="learn-content">{learn}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default CourseDetails;