import Axios from "../ServiceCaller";

let getChapters = async (course_id) => {
    const { data } = await Axios.get(`courses/${course_id}/chapters`);
    return data.Chapters;
};
let createChapter = async (chapterData) => {
    const { data } = await Axios.post("courses/chapters", chapterData);
    return data;
};
let getChapterById = async (id) => {
    const { data } = await Axios.get(`courses/chapters/${id}`);
    return data.Chapter;
};
let updateChapter = async (chapterData) => {
    const { data } = await Axios.put("courses/chapters", chapterData);
    return data;
};
let deleteChapter = async (chapter_id) => {
    try {
        const response = await Axios.delete(`courses/chapters`, {
            data: { id: chapter_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Chapter:", error);
        throw error;
    }
};
export const chapterService = {
    getChapters,
    createChapter,
    getChapterById,
    updateChapter,
    deleteChapter,
};