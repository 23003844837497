import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {chapterService} from "../../../_services";
import ChapterRow from "./ChapterRow";
import ChapterForm from "./ChapterForm";

const Chapters = () => {

    let {cid} = useParams();
    const [chaptersData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [chapterToEdit, setChapterToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await chapterService.getChapters(cid);
                setData(data);
            } catch (error) {
                console.error("Error fetching chapters data:", error);
            }
        };

        fetchData();
    }, [refresh, cid]);

    const handleEditChapter = (chapter) => {
        setEditMode(true);
        setChapterToEdit(chapter);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <div className="mt-4">
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="big-title me-3 mb-2 mb-md-0">Chapters</div>
                <div className="add-btn-holder">
                    <button className="btn btn-primary py-1 btn-sm" type="button" disabled={!hidden}
                            onClick={setFormHiddenAdd}>Add New Chapter
                    </button>
                </div>
            </div>
            <div className={hidden ? "d-none" : "d-block"}>
                <ChapterForm
                    course_id={cid}
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    editMode={editMode}
                    chapter={chapterToEdit}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                />
            </div>
            <div className="row m-0 horizontal-items">
                {chaptersData.length > 0 ? (
                    chaptersData.map((chapter) => (
                        <ChapterRow
                            key={chapter.id}
                            course_id={chapter.course_id}
                            chapter_id={chapter.id}
                            title={chapter.title}
                            image={chapter.image_name}
                            lessons_num={chapter.lessons_num}
                            order_num={chapter.order_num}
                            created_date={chapter.created_date}
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            handleEditChapter={handleEditChapter}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    ))
                ) : (
                    <div className="text-center">There is no chapter yet</div>
                )}
            </div>
        </div>
    )
}

export default Chapters;