import Axios from "../ServiceCaller";

let getCourses = async () => {
    const { data } = await Axios.get("/courses");
    return data.Courses;
};
let getFeaturedCourses = async () => {
    const { data } = await Axios.get("/courses/featured");
    return data.Courses;
};
let createCourse = async (courseData) => {
    const { data } = await Axios.post("/courses", courseData);
    return data;
};
let getCourseById = async (id) => {
    const { data } = await Axios.get(`/courses/${id}`);
    return data.Course;
};
let updateCourse = async (courseData) => {
    const { data } = await Axios.put("/courses", courseData);
    return data;
};
let deleteCourse = async (course_id) => {
    try {
        const response = await Axios.delete(`/courses`, {
            data: { id: course_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Course:", error);
        throw error;
    }
};
let IncreaseCourseProgress = async (c_id, ch_id, l_id) => {
    const { data } = await Axios.get(`courses/progress/${c_id}/${ch_id}/${l_id}`);
    return data;
};

export const courseService = {
    getCourses,
    getFeaturedCourses,
    createCourse,
    getCourseById,
    updateCourse,
    deleteCourse,
    IncreaseCourseProgress
};