import {Link} from "react-router-dom";
import {useState} from "react";
import {pdfCourseService} from "../../../_services";
import {BiEdit, BiTrash} from "react-icons/bi";
import defaultChapterBg from "../../../assets/images/default_chapter_bg.jpg"

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const PdfCourseRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);
    const handleDeleteChapter = async () => {
        setIsDeleting(true);

        try {
            await pdfCourseService.deletePdfCourse(props.pdf_course_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting PDF Course:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditPdfCourse = () => {
        props.handleEditPdfCourse(props);
        props.sendHiddenToParent(!props.hidden);
    };

    return (
        <div className="col-xl-6-thedss mb-3">
            <div className={`bg-white rounded-3 pt-2 pt-md-1 py-1 px-2 px-lg-3 d-flex flex-column flex-md-row align-items-center ${props.is_featured ? 'border border-danger' : ''}`}>
                <div className="course-bg-holder text-center me-2">
                    {props.background_name ? (
                        <img className="course-bg img-fluid rounded-3" alt={props.title}
                             
                             src={`https://api.skillspay.io/thedss/images/${props.background_name}`}
                        />
                    ) : (
                        <img className="course-bg img-fluid rounded-3" alt={props.title}
                             
                             src={defaultChapterBg}
                        />
                    )}
                </div>
                <div className="align-self-md-start mt-2 px-2">
                    <div className="course-title fw-bold mb-1 text-center text-md-start">{props.title}</div>
                    <div className="level-holder text-center text-md-start">
                        <div className="level text-black-50">{props.lessons_num} Lessons</div>
                    </div>
                </div>
                <div className="ms-md-auto">
                    <div className="actions-btn my-3">
                        <div className="d-flex justify-content-center">
                            <div>
                                <button className="border-0 bg-transparent" onClick={handleEditPdfCourse}
                                        disabled={!props.hidden}>
                                    <BiEdit size="22" className="edit-icon"/>
                                </button>
                            </div>
                            {isDeleting ? (
                                <span className="text-danger">Loading...</span>
                            ) : (
                                <BiTrash size="25" onClick={handleDeleteChapter} className="remove-icon"/>
                            )}
                        </div>
                        <div className="edit-btn-holder px-2 mt-2">
                            <Link className="name-link" to={`/admin/pdf-courses/pdf-course/${props.pdf_course_id}`}>
                                <button className="edit-btn w-100 py-1 px-3 rounded-2">Lessons</button>
                            </Link>
                        </div>
                    </div>
                    <div
                        className="course-created-date text-end text-nowrap">{props.created_date ? `Created at: ${getDate(props.created_date)}` : ''}</div>
                </div>
            </div>
        </div>
    );
}

export default PdfCourseRow;