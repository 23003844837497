import React, {useEffect, useState} from "react";
import {settingService} from "../../../_services";

const CurrenciesForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);

    const [params, setParamsValues] = useState({
        code: "",
        rate: "",
    });

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                code: props.currencies.code,
                rate: props.currencies.rate,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                code: "",
                rate: "",
            });
        }
    }, [props.editMode, props.currencies]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("code", params.code);
            formData.append("rate", params.rate);

            if (isCreatingMode) {
                await settingService.createCurrency(formData);
            } else {
                formData.append("id", props.currencies.currency_id);
                await settingService.updateCurrency(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                code: "",
                rate: "",
            });
        } catch (error) {
            console.error("Error Creating/Updating currencies:", error);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            code: "",
            rate: "",
        });
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-currencies-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title main-dark-color fw-bold mb-2">Add a New currency</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group mb-4">
                                <label className="mb-1" htmlFor="code">
                                    Code:
                                </label>
                                <div className="code-holder d-flex align-items-center">
                                    <div className="code-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control text py-2"
                                            placeholder="Code of currency"
                                            value={params.code}
                                            onChange={onChange}
                                            name="code"
                                            id="code"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group mb-4">
                                <label className="mb-1" htmlFor="rate">
                                    Rate to USD:
                                </label>
                                <div className="rate-holder d-flex align-items-center">
                                    <div className="rate-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control text py-2"
                                            placeholder="Rate to USD"
                                            value={params.rate}
                                            onChange={onChange}
                                            name="rate"
                                            id="rate"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary px-4">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CurrenciesForm;