import {useEffect, useState} from "react";
import {pdfCourseService} from "../../_services";
import {Header, PdfCourseForm, PdfCourseRow} from "./Admin";

const PdfCourses = () => {

    const [pdfCoursesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [pdfCourseToEdit, setPdfCourseToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await pdfCourseService.getPdfCourses();
                setData(data);
            } catch (error) {
                console.error("Error fetching PDF Courses data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditPdfCourse = (pdfCourse) => {
        setEditMode(true);
        setPdfCourseToEdit(pdfCourse);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="PDF Courses"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-courses-page">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="big-title me-3 mb-2 mb-md-0">PDF Courses</div>
                        <div className="add-btn-holder">
                            <button className="btn btn-primary py-1 btn-sm" type="button" disabled={!hidden}
                                    onClick={setFormHiddenAdd}>Add New PDF Course
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <PdfCourseForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            pdfCourse={pdfCourseToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="row m-0 horizontal-items">
                        {pdfCoursesData.length > 0 ? (
                            pdfCoursesData.map((pdf_course) => (
                                <PdfCourseRow
                                    key={pdf_course.id}
                                    pdf_course_id={pdf_course.id}
                                    title={pdf_course.title}
                                    description={pdf_course.description}
                                    background_name={pdf_course.background_name}
                                    lessons_num={pdf_course.lessons_num}
                                    created_date={pdf_course.created_date}
                                    sendRefreshToParent={setRefresh}
                                    refresh={refresh}
                                    handleEditPdfCourse={handleEditPdfCourse}
                                    sendHiddenToParent={setHidden}
                                    hidden={hidden}
                                    level={pdf_course.level}
                                    course_pages={pdf_course.course_pages}
                                    quizzes_num={pdf_course.quizzes_num}
                                    is_featured={pdf_course.is_featured}
                                    received_skills={pdf_course.received_skills}
                                    what_will_you_learn={pdf_course.what_will_you_learn}
                                />
                            ))
                        ) : (
                            <div className="text-center">There is no PDF Course yet</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PdfCourses;