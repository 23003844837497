import {Header, CampaignForm, CampaignRow} from "./Admin";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {emailService} from "../../_services";
import {Table} from "react-bootstrap";

const EmailMarketing = () => {
    const [campaignsData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [campaignToEdit, setCampaignToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await emailService.getCampaigns();
                setData(data);
            } catch (error) {
                console.error("Error fetching campaigns data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditCampaign = (campaign) => {
        setEditMode(true);
        setCampaignToEdit(campaign);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }
    return (
        <>
            <Header title="Email Marketing"/>
            <div className="page-content p-2 p-lg-3">
                <div className="email-marketing-page bg-white p-3 rounded-3">
                    <div className="big-title mb-2 text-center">Email Marketing Settings</div>
                    <div className="row justify-content-end">
                        <div className="col-md-4 mb-3 mb-md-0">
                            <div className="text-center">
                                <button className="btn btn-primary" disabled={!hidden} type="button"
                                        onClick={setFormHiddenAdd}>Add New Campaign
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0">
                            <div className="text-center">
                                <Link to="/admin/email-marketing/targeted-campaigns">
                                    <button className="btn btn-danger">Targeted Campaigns</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0">
                            <div className="text-center">
                                <Link to="/admin/email-marketing/email-templates">
                                    <button className="btn btn-success">Manage Templates</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="mt-4 mb-2 mb-lg-3">
                            <div className="big-title me-3 mb-2 mb-md-0">Email Campaigns</div>
                        </div>
                        <div className={hidden ? "d-none" : "d-block"}>
                            <CampaignForm
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                editMode={editMode}
                                campaign={campaignToEdit}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        </div>
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Name</td>
                                <td className="text-black-50">From Name</td>
                                <td className="text-black-50">Subject</td>
                                <td className="text-black-50">Template</td>
                                <td className="text-black-50">Duration</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {campaignsData.length > 0 ? (
                                campaignsData.map((campaign) => (
                                    <CampaignRow
                                        key={campaign.campaign_id}
                                        campaign_id={campaign.campaign_id}
                                        campaign_name={campaign.campaign_name}
                                        from_name={campaign.from_name}
                                        subject={campaign.subject}
                                        template_id={campaign.template_id}
                                        template_name={campaign.template_name}
                                        custom_template_id={campaign.custom_template_id}
                                        duration={campaign.duration}
                                        duration_type={campaign.duration_type}
                                        created_date={campaign.created_date}
                                        header_first_title={campaign.header_first_title}
                                        header_second_title={campaign.header_second_title}
                                        custom_old_template_id={campaign.custom_old_template_id}
                                        button_text_content={campaign.button_text_content}
                                        button_link={campaign.button_link}
                                        footer_first_text={campaign.footer_first_text}
                                        footer_second_text={campaign.footer_second_text}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditCampaign={handleEditCampaign}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailMarketing;