import React from "react";
import Iframe from "react-iframe";
import {BiX} from "react-icons/bi";

const VideoModal = (props) => {
    const setVideoModalHidden = () => {
        props.setVideoLink('')
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="d-flex align-items-center justify-content-center video-modal-content w-100 vh-100 position-fixed top-0 start-50 translate-middle-x">
            <div className="w-100 px-1">
                <div className="text-end">
                    <button type="button" className="btn btn-outline-danger border-0 me-3" onClick={setVideoModalHidden}>
                        <BiX size="30"/>
                    </button>
                </div>
                <div className="video-holder mt-3">
                    <Iframe className="rounded-3"
                            url={props.url}
                            width="100%"
                            height="600px"
                    />
                </div>
            </div>
        </div>
    );
}

export default VideoModal;