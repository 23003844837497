import {Routes, Route} from "react-router-dom";
import {
    PLayout,
    Courses,
    Error,
    Profile,
    Course,
    Lesson,
    Support,
    PdfCourse,
    PdfLesson,
    AllCourses,
    AllPdfCourses
} from "./Private";

const PrivateRouter = () => {
    return (
        <Routes>
            <Route element={<PLayout/>}>
                <Route index element={<Courses/>}/>
                <Route index path="/" element={<Courses/>}/>
                <Route index path="courses" element={<Courses/>}/>
                <Route index path="all-courses" element={<AllCourses/>}/>
                <Route index path="all-pdf-courses" element={<AllPdfCourses/>}/>
                <Route path="profile" element={<Profile/>}/>
                <Route path="support" element={<Support/>}/>
                <Route path="courses/course/:cid" element={<Course/>}/>
                <Route path="pdf-courses/course/:cid" element={<PdfCourse/>}/>
                <Route path="courses/lesson/:lid" element={<Lesson/>}/>
                <Route path="pdf-courses/lesson/:lid" element={<PdfLesson/>}/>

                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
}

export default PrivateRouter;