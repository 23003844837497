import React, { useState, useEffect } from 'react';

function Timer() {
    const totalSeconds = 29 * 60; // 48 hours in seconds
    const [time, setTime] = useState(totalSeconds);

    useEffect(() => {
        const interval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [time]);

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return (
        <span>
            {`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
        </span>
    );
}

export default Timer;