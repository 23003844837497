import PeriodCard from "./PeriodCard";
import {useEffect, useState} from "react";
import {dashboardService} from "../../../_services";

const MonthCards = () => {
    const [quizTaken, setQuizTaken] = useState(0);
    const [registeredUsers, setRegisteredUsers] = useState(0);
    const [revenue, setRevenue] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const quizData = await dashboardService.getMonthQuizTaken();
                setQuizTaken(quizData)
                const registeredData = await dashboardService.getMonthRegisteredUsers();
                setRegisteredUsers(registeredData)
                const revenueData = await dashboardService.getMonthRevenue();
                setRevenue(revenueData)
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="period-card-stats bg-white rounded-2 py-3 px-2">
            <div className="big-title mb-3 text-center">This Month Statistics</div>
            <div className="row">
                <PeriodCard
                    name="Quiz Taken"
                    value={quizTaken}
                />
                <PeriodCard
                    name="Registered Users"
                    value={registeredUsers}
                />
                <PeriodCard
                    name="Revenue"
                    value={`$${revenue}`}
                />
            </div>
        </div>
    );
}

export default MonthCards;