import {Header, OrderRow} from "./Admin";
import {useEffect, useState} from "react";
import {orderService} from "../../_services";

const Orders = () => {
    const [ordersData, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await orderService.getOrders();
                setData(data);
            } catch (error) {
                console.error("Error fetching orders data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Header title="Orders"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-orders-page">
                    <div className="big-title mb-2 mb-lg-3">Orders List</div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">ID</td>
                                <td className="text-black-50">Customer Name</td>
                                <td className="text-black-50">Email</td>
                                <td className="text-black-50">Product Name</td>
                                <td className="text-black-50">Price</td>
                                <td className="text-black-50">Method</td>
                                <td className="text-black-50">Created</td>
                            </tr>
                            </thead>
                            <tbody>
                            {ordersData.length > 0 ? (
                                ordersData.map((order) => (
                                    <OrderRow
                                        key={order.id}
                                        order_id={order.id}
                                        customer_name={order.full_name}
                                        email={order.email}
                                        product_name={order.product_name}
                                        total={order.total}
                                        payment_method={order.payment_method}
                                        created_date={order.created_date}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">There is no order yet</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Orders;