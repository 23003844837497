import {Header, TotalCards, TodayCards, YesterdayCards, WeekCards, MonthCards} from "./Admin";
const Dashboard = () => {
    return (
        <>
            <Header title="Dashboard"/>
            <div className="page-content">
                <div className="main-dashboard-page p-2 p-lg-3">
                    <TotalCards/>
                    <div className="border-5 my-2" style={{borderStyle: "dashed", borderColor: "#e9e9e9"}}></div>
                    <div className="row m-0">
                        <div className="col-xxl-6 mb-3">
                            <TodayCards/>
                        </div>
                        <div className="col-xxl-6 mb-3">
                            <YesterdayCards/>
                        </div>
                        <div className="col-xxl-6 mb-3">
                            <WeekCards/>
                        </div>
                        <div className="col-xxl-6 mb-3">
                            <MonthCards/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;