import React, {useId} from 'react';
import {useQuery} from "react-query";
import {settingService} from "../../_services";
import FacebookPixel from "./FacebookPixel";

const FacebookPixelHolder = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => settingService.getFacebookPixelKeysSettings());
    const FacebookPixelData = data || {"data": []}

    if (isLoading) {
        return (<></>);
    }

    let pixel_id;
    if (FacebookPixelData.length > 0 && FacebookPixelData[0].pixel_id) {
        pixel_id = FacebookPixelData[0].pixel_id
    }

    if (pixel_id) {
        return (
            <FacebookPixel pixel_id={pixel_id} />
        )
    }
    return null;
};

export default FacebookPixelHolder;