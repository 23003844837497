import Axios from "../ServiceCaller";

let getOrders = async () => {
    const { data } = await Axios.get("/orders");
    return data.Orders;
};

let createOrder = (orderData) => {
    return Axios.post('/orders', orderData);
};

let getOrderById = async (id) => {
    const { data } = await Axios.get(`/orders/${id}`);
    return data.Order;
};

let PayOrderWithTranzak = (data) => {
    return Axios.post('/payments/tranzak/create-tranzak-order', data);
};

let verifyTransaction = async (transactionId) => {
    const {data} = await Axios.get(`/payments/tranzak/verify-transaction/${transactionId}`);
    return data.Response;
};

export const orderService = {
    getOrders,
    createOrder,
    getOrderById,
    PayOrderWithTranzak,
    verifyTransaction,
};