import ProgressBar from 'react-bootstrap/ProgressBar';
import {BiBulb, BiBookContent} from "react-icons/bi";
import defaultChapterBg from "../../../assets/images/default_chapter_bg.jpg";

const CourseHeader = (props) => {
    return (
        <div className="course-header position-relative py-4 px-3"
             style={{
                 backgroundImage: props.bg
                     ? `url(https://api.skillspay.io/thedss/images/${props.bg})`
                     : `url(${defaultChapterBg})`
             }}
        >
            <div className="bg-overlay position-absolute w-100 h-100 top-0 start-0"></div>
            <div className="position-relative text-white">
                <h3 className="title fs-4">{props.courseTitle}</h3>
                <div className="level text-white-50">{props.courseLevel}</div>
                {props.coursePercentage > 0 ? (
                    <div className="px-2 mt-3"><ProgressBar now={props.coursePercentage}
                                                            label={`${props.coursePercentage}%`}/></div>
                ) : (
                    <div className="px-2 mt-3">
                        <div className="text-center text-black-50 alert alert-light py-1 small">
                            Not Started Yet
                        </div>
                    </div>
                )}
                <div className="row g-3 mt-3">
                    <div className="col-6">
                        <div className="box-card rounded-2 py-2 px-3 d-flex justify-content-between align-items-center">
                            <div className="infos-holder">
                                <div className="fs-3 fw-bold">{props.courseLessonsNumber}</div>
                                <div className="small text-white-50">Lessons</div>
                            </div>
                            <div className="icon-holder"><BiBookContent className="icon" size="45"/></div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="box-card rounded-2 py-2 px-3 d-flex justify-content-between align-items-center">
                            <div className="infos-holder">
                                <div className="fs-3 fw-bold">{props.courseLessonsQuizzes}</div>
                                <div className="small text-white-50">Quizzes</div>
                            </div>
                            <div className="icon-holder"><BiBulb className="icon" size="45"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseHeader;