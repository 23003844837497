import {NavLink} from "react-router-dom";
import {
    BiHome, BiSupport,
    BiUserCircle
} from "react-icons/bi";


const BottomNavbar = () => {
    const menuItem = [
        {
            path: "/courses",
            name: "Courses",
            icon: <BiHome/>
        },
        {
            path: "/profile",
            name: "Profile",
            icon: <BiUserCircle/>
        },
        {
            path: "/support",
            name: "Support",
            icon: <BiSupport/>
        }
    ]

    return (
        <div className="main-bottom-navbar shadow">
            <div className="menu-links">
                {
                    menuItem.map((item, index) => (
                        <div className="nav-link" key={index}>
                            <NavLink to={item.path} key={index}
                                     className="nav-link-item d-flex flex-column align-items-center">
                                        <span className="icon">
                                            {item.icon}
                                        </span>
                                <span className="nav-text">{item.name}</span>
                            </NavLink>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default BottomNavbar;