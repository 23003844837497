import {BiCheckCircle} from "react-icons/bi";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {pdfLessonService} from "../../../_services";

const PdfLessons = (props) => {
    let pdf_course_id = props.course_id;

    const [lessonsData, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await pdfLessonService.getPdfLessonsForUser(pdf_course_id);
                setData(data);
            } catch (error) {
                console.error("Error fetching lessons data:", error);
            }
        };

        fetchData();
    }, [pdf_course_id]);
    return (
        <div className="course-chapters">
            <div className="lessons-holder">
                {lessonsData.length > 0 ? (
                    lessonsData.map((pdfLesson, index) => (
                        pdfLesson.lesson_completed > 0 ? (
                                <div
                                    className="bg-white border border-1 p-3 d-flex align-items-center justify-content-between rounded-2 mb-2"
                                    key={index}>
                                    <div className="d-flex align-items-center">
                                        <div className="icon-holder me-2"><BiCheckCircle color="green" size="25"/></div>
                                        <div className="lesson-title">{pdfLesson.title}</div>
                                    </div>
                                    <div>
                                        <Link to={`/pdf-courses/lesson/${pdfLesson.id}`}>
                                            <button className="rounded-2 border-0 small btn btn-sm text-white"
                                                    style={{backgroundColor: "#0EB14D"}}>Watch Lesson Again
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            ) :
                            <div className="bg-white border border-1 p-3 rounded-2 mb-2" key={index}>
                                <div className="lesson-info d-flex align-items-center">
                                    <div className="icon-holder me-2"><BiCheckCircle
                                        color={pdfLesson.lesson_completed > 0 ? '#0EB14D' : 'gray'} size="25"/></div>
                                    <div className="lesson-title">{pdfLesson.title}</div>
                                </div>
                                <div className="btn-holder mt-3 text-center">
                                    <Link to={`/pdf-courses/lesson/${pdfLesson.id}`}>
                                        <button className="continue-btn px-4 py-2 rounded-2">Continue Lesson</button>
                                    </Link>
                                </div>
                            </div>

                    ))
                ) : (
                    <div className="text-center">There is no lesson yet</div>
                )
                }
            </div>
        </div>
    );
}

export default PdfLessons;