import {Header, LessonForm, LessonRow} from "./Admin";
import {useEffect, useState} from "react";
import {lessonService} from "../../_services";
import {BiArrowBack} from "react-icons/bi";
import {Link, useParams} from "react-router-dom";

const Lessons = () => {

    let {chid} = useParams();
    const [lessonsData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [titleFromChild, setTitleFromChild] = useState('');
    const [idFromChild, setIdFromChild] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [lessonToEdit, setLessonToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    const getTitleFromChild = (title) => {
        setTitleFromChild(title);
    }

    const getIdFromChild = (id) => {
        setIdFromChild(id);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await lessonService.getLessons(chid);
                setData(data);
            } catch (error) {
                console.error("Error fetching lessons data:", error);
            }
        };

        fetchData();
    }, [refresh, chid]);

    const handleEditLesson = (lesson) => {
        setEditMode(true);
        setLessonToEdit(lesson);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Lessons"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-lessons-page">
                    <Link className="big-title back fs-6 mb-2 mb-lg-3 d-flex align-items-center c-pointer"
                          to={`/admin/courses/course/${idFromChild}`}>
                        <BiArrowBack className="me-2 icon" size="25"/>Back To Course Details
                    </Link>
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="big-title me-3 mb-2 mb-md-0">Lessons List of "{titleFromChild}"</div>
                        <div className="add-btn-holder">
                            <button className="btn btn-primary py-1 btn-sm" disabled={!hidden} type="button"
                                    onClick={setFormHiddenAdd}>Add New Lesson
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <LessonForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            course_id={idFromChild}
                            chapter_id={chid}
                            editMode={editMode}
                            lesson={lessonToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Title</td>
                                <td className="text-black-50">Video URL</td>
                                <td className="text-black-50">Order</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {lessonsData.length > 0 ? (
                                lessonsData.map((lesson) => (
                                    <LessonRow
                                        key={lesson.id}
                                        lesson_id={lesson.id}
                                        course_id={lesson.course_id}
                                        chapter_id={lesson.chapter_id}
                                        chapter_title={lesson.chapter_title}
                                        title={lesson.title}
                                        video_url={lesson.video_url}
                                        order_num={lesson.order_num}
                                        created_date={lesson.created_date}
                                        sendTitleToParent={getTitleFromChild}
                                        sendIdToParent={getIdFromChild}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditLesson={handleEditLesson}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Lessons;