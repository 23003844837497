import Axios from "../ServiceCaller";

let getPdfCourses = async () => {
    const { data } = await Axios.get("/pdf-courses");
    return data.PdfCourses;
};
let createPdfCourse = async (pdfCourseData) => {
    const { data } = await Axios.post("/pdf-courses", pdfCourseData);
    return data;
};
let getPdfCourseById = async (id) => {
    const { data } = await Axios.get(`/pdf-courses/${id}`);
    return data.PdfCourse;
};
let updatePdfCourse = async (pdfCourseData) => {
    const { data } = await Axios.put("/pdf-courses", pdfCourseData);
    return data;
};
let deletePdfCourse = async (pdf_course_id) => {
    try {
        const response = await Axios.delete(`/pdf-courses`, {
            data: { id: pdf_course_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting PDF Course:", error);
        throw error;
    }
};
let IncreasePdfCourseProgress = async (c_id, l_id) => {
    const { data } = await Axios.get(`pdf-courses/progress/${c_id}/${l_id}`);
    return data;
};

export const pdfCourseService = {
    getPdfCourses,
    createPdfCourse,
    getPdfCourseById,
    updatePdfCourse,
    deletePdfCourse,
    IncreasePdfCourseProgress,
};