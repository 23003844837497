import Axios from "../ServiceCaller";

//--- Quiz Taken Stats ---//
let getTotalQuizTaken = async () => {
    const { data } = await Axios.get("/dashboard/quiz-taken/total");
    return data.result;
};
let getTodayQuizTaken = async () => {
    const { data } = await Axios.get("/dashboard/quiz-taken/today");
    return data.result;
};
let getYesterdayQuizTaken = async () => {
    const { data } = await Axios.get("/dashboard/quiz-taken/yesterday");
    return data.result;
};
let getWeekQuizTaken = async () => {
    const { data } = await Axios.get("/dashboard/quiz-taken/week");
    return data.result;
};
let getMonthQuizTaken = async () => {
    const { data } = await Axios.get("/dashboard/quiz-taken/month");
    return data.result;
};

//--- Registered Users Stats ---//
let getTotalRegisteredUsers = async () => {
    const { data } = await Axios.get("/dashboard/registered-users/total");
    return data.result;
};
let getTodayRegisteredUsers = async () => {
    const { data } = await Axios.get("/dashboard/registered-users/today");
    return data.result;
};
let getYesterdayRegisteredUsers = async () => {
    const { data } = await Axios.get("/dashboard/registered-users/yesterday");
    return data.result;
};
let getWeekRegisteredUsers = async () => {
    const { data } = await Axios.get("/dashboard/registered-users/week");
    return data.result;
};
let getMonthRegisteredUsers = async () => {
    const { data } = await Axios.get("/dashboard/registered-users/month");
    return data.result;
};

//--- Revenue Stats ---//
let getTotalRevenue = async () => {
    const { data } = await Axios.get("/dashboard/revenue/total");
    return data.result;
};
let getTodayRevenue = async () => {
    const { data } = await Axios.get("/dashboard/revenue/today");
    return data.result;
};
let getYesterdayRevenue = async () => {
    const { data } = await Axios.get("/dashboard/revenue/yesterday");
    return data.result;
};
let getWeekRevenue = async () => {
    const { data } = await Axios.get("/dashboard/revenue/week");
    return data.result;
};
let getMonthRevenue = async () => {
    const { data } = await Axios.get("/dashboard/revenue/month");
    return data.result;
};

export const dashboardService = {
    //--- Quiz Taken Stats ---//
    getTotalQuizTaken,
    getTodayQuizTaken,
    getYesterdayQuizTaken,
    getWeekQuizTaken,
    getMonthQuizTaken,
    //--- Registered Users Stats ---//
    getTotalRegisteredUsers,
    getTodayRegisteredUsers,
    getYesterdayRegisteredUsers,
    getWeekRegisteredUsers,
    getMonthRegisteredUsers,
    //--- Revenue Stats ---//
    getTotalRevenue,
    getTodayRevenue,
    getYesterdayRevenue,
    getWeekRevenue,
    getMonthRevenue,
};