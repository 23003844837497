import {
    FacebookPixelKeysSettings,
    GoogleAnalyticsKeysSettings,
    Header,
    PaypalKeysSettings,
    SendGridKeysSettings,
    TranzakKeysSettings,
    StripeKeysSettings,
} from "./Admin";

const KeysSettings = () => {
    return (
        <>
            <Header title="Keys Settings"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-payments-page">
                    <div className="paypal-keys-setting">
                        <PaypalKeysSettings/>
                    </div>
                    <div className="sendgrid-keys-setting mt-2 mt-lg-4">
                        <SendGridKeysSettings/>
                    </div>
                    <div className="google-analytics-keys-setting mt-2 mt-lg-4">
                        <GoogleAnalyticsKeysSettings/>
                    </div>
                    <div className="google-analytics-keys-setting mt-2 mt-lg-4">
                        <FacebookPixelKeysSettings/>
                    </div>
                    <div className="tranzak-keys-setting">
                        <TranzakKeysSettings/>
                    </div>
                    <div className="stripe-keys-setting">
                        <StripeKeysSettings/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeysSettings;