import {useEffect, useState} from "react";
import {settingService} from "../../../_services";
import button from "bootstrap/js/src/button";
import defaultCourseBg from "../../../assets/images/default_course_bg.jpg";

const SliderSettingsRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);
    const handleDeleteSliderSettings = async () => {
        setIsDeleting(true);

        try {
            await settingService.deleteSliderSettings(props.slider_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting slider:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        props.sendSliderDurationToParent(props.slide_duration);
    }, [props]);

    return (
        <div className="col-6 col-xl-4 mb-3">
            <div className="bg-white rounded-3 pt-3 px-2 px-lg-3 d-flex flex-column h-100">
                <div className="course-bg-holder position-relative mb-2">
                    <img className="course-bg img-fluid rounded-3" alt="slider slide item"
                         
                         src={`https://api.skillspay.io/thedss/slider-images/${props.slider_image}`}
                    />
                </div>
                <div className="text-center px-2 my-2 mt-auto">
                    {isDeleting ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <button onClick={handleDeleteSliderSettings} type="button"
                                className="btn text-center btn-sm btn-danger">
                            Delete
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SliderSettingsRow;