import FacebookPixelKeysSettingsForm from "../keys-settings/FacebookPixelKeysSettingsForm";
import facebookPixelIcon from "../../../assets/images/facebook-icon.png"
import {BiEdit} from "react-icons/bi";
import {useEffect, useState} from "react";
import {settingService} from "../../../_services";

const FacebookPixelKeysSettings = () => {
    const [facebookPixelKeysSettingsData, setFacebookPixelKeysSettingsData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await settingService.getFacebookPixelKeysSettings();
                setFacebookPixelKeysSettingsData(data);
            } catch (error) {
                console.error("Error fetching Facebook Pixel keys data:", error);
            }
        };

        fetchData();
    }, [refresh]);
    const setFormHidden = () => {
        setHidden(!hidden);
    }

    if (facebookPixelKeysSettingsData && facebookPixelKeysSettingsData.length > 0) {
        const facebookPixelSettingsData = facebookPixelKeysSettingsData[0];
        return (
            <>
                <div className="big-title mb-2 mb-lg-3">Fcebook Keys Settings</div>
                <div className={hidden ? "d-none" : "d-block"}>
                    <FacebookPixelKeysSettingsForm
                        settings_id={facebookPixelSettingsData.id}
                        pixel_id={facebookPixelSettingsData.pixel_id}
                        sendRefreshToParent={setRefresh}
                        refresh={refresh}
                        sendHiddenToParent={setHidden}
                        hidden={hidden}
                    />
                </div>
                <div className="row m-0 justify-content-center">
                    <div
                        className="payments-content bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                        <div className="icon-holder text-center mb-2 mb-lg-0">
                            <img src={facebookPixelIcon}
                                 width="64"
                                 height="auto"
                                 alt="Facebook Pixel"/>
                        </div>
                        <div className="d-flex flex-column flex-lg-row flex-grow-1 justify-content-evenly">
                            <div className="setting-holder text-center mb-2 mb-lg-0">
                                <div
                                    className="setting-name text-black-50">Pixel ID
                                </div>
                                <div className="setting-value">{facebookPixelSettingsData.pixel_id}</div>
                            </div>
                        </div>
                        <div onClick={setFormHidden}
                             className="edit-holder c-pointer d-flex align-items-center align-self-end mt-2 mt-lg-0 me-2">
                            <div className="icon-holder me-2"><BiEdit className="icon" size="25"/></div>
                            <div className="edit-text">Edit</div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }
}

export default FacebookPixelKeysSettings;