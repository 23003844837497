import React, {useEffect, useState} from "react";
import {lessonService} from "../../../_services";

const LessonForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        title: "",
        order_num: "",
        video_url: "",
    });

    const [file, setFile] = useState(null);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title: props.lesson.title,
                order_num: props.lesson.order_num,
                video_url: props.lesson.video_url,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                title: "",
                order_num: "",
                video_url: "",
            });
        }
    }, [props.editMode, props.lesson]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("course_id", props.course_id);
            formData.append("chapter_id", props.chapter_id);
            formData.append("title", params.title);
            formData.append("order_num", params.order_num);
            formData.append("video_url", params.video_url);
            formData.append("l_resource_file", file);

            if (isCreatingMode) {
                await lessonService.createLesson(formData);
            } else {
                formData.append("id", props.lesson.lesson_id);
                await lessonService.updateLesson(formData);
            }

            setFile(null);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                title: "",
                order_num: "",
                video_url: "",
            });
        } catch (error) {
            console.error("Error Creating/Updating lesson:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-lesson-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center">Add a New lesson</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="title">
                            Title:
                        </label>
                        <div className="title-holder d-flex align-items-center">
                            <div className="title-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Title of the lesson"
                                    value={params.title}
                                    onChange={onChange}
                                    name="title"
                                    id="title"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="order_num">
                            Order:
                        </label>
                        <div className="order_num-holder d-flex align-items-center">
                            <div className="order_num-input flex-grow-1">
                                <input
                                    type="number"
                                    className="form-control text py-2"
                                    placeholder="lesson Order Within The Chapter"
                                    value={params.order_num}
                                    onChange={onChange}
                                    name="order_num"
                                    id="order_num"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="video_url">
                            Video URL:
                        </label>
                        <div className="video_url-holder d-flex align-items-center">
                            <div className="video_url-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Video URL For Lesson Video"
                                    value={params.video_url}
                                    onChange={onChange}
                                    name="video_url"
                                    id="video_url"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="file">
                            Upload File:
                        </label>
                        <div className="file-holder d-flex align-items-center">
                            <div className="file-input flex-grow-1">
                                <input
                                    type="file"
                                    className="form-control py-2"
                                    onChange={onFileChange}
                                    name="file"
                                    id="file"
                                    accept=".pdf"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="button" disabled={isLoading} className="btn btn-secondary me-3" onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary px-4" disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LessonForm;