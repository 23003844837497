import Axios from "../ServiceCaller";

let getLessons = async (chapter_id) => {
    const { data } = await Axios.get(`courses/${chapter_id}/lessons`);
    return data.Lessons;
};
let getLessonsForUser = async (chapter_id) => {
    const { data } = await Axios.get(`courses/${chapter_id}/lessons/user`);
    return data.Lessons;
};
let createLesson = async (lessonData) => {
    const { data } = await Axios.post("courses/lessons", lessonData);
    return data;
};
let getLessonById = async (id) => {
    const { data } = await Axios.get(`courses/lessons/${id}`);
    return data.Lesson;
};
let getLessonByOrderNumber = async (c_id, ch_id, order_num) => {
    const { data } = await Axios.get(`courses/lessons/${c_id}/${ch_id}/${order_num}`);
    return data;
};
let updateLesson = async (lessonData) => {
    const { data } = await Axios.put("courses/lessons", lessonData);
    return data;
};
let deleteLesson = async (lesson_id) => {
    try {
        const response = await Axios.delete(`courses/lessons`, {
            data: { id: lesson_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Lesson:", error);
        throw error;
    }
};

export const lessonService = {
    getLessons,
    getLessonsForUser,
    createLesson,
    getLessonById,
    getLessonByOrderNumber,
    updateLesson,
    deleteLesson,
};