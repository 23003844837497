import React, {useEffect, useState} from "react";
import {settingService} from "../../../_services";

const GoogleAnalyticsSettingsForm = (props) => {
    const [isCreating, setIsCreating] = useState(false);

    const [params, setParamsValues] = useState({
        tracking_id: "",
    });

    useEffect(() => {
        setParamsValues({
            tracking_id: props.tracking_id,
        });
    }, [props]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("tracking_id", params.tracking_id);
            formData.append("settings_id", props.settings_id);
            await settingService.updateGoogleAnalyticsKeysSettings(formData);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
        } catch (error) {
            console.error("Error Creating/Updating GoogleAnalytics Keys Settings:", error);
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="main-form-holder add-chapter-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center mb-3">Edit Google Analytics Keys Settings</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="form-group mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="tracking_id">
                                API Key:
                            </label>
                            <div className="price-holder d-flex align-items-center">
                                <div className="price-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Google Analytics Tracking ID"
                                        value={params.tracking_id}
                                        onChange={onChange}
                                        name="tracking_id"
                                        id="tracking_id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isCreating ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default GoogleAnalyticsSettingsForm;
