import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {pdfCourseService, pdfLessonService} from "../../_services";
import {BiArrowBack, BiBookOpen, BiSolidFilePdf} from "react-icons/bi";
import {Document, Page, pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {IoArrowBack} from "react-icons/io5";
import {GrFormNext, GrFormPrevious} from "react-icons/gr";

const PdfLesson = () => {
    useEffect(() => {
        document.title = "Skillspay - PDF Lesson";
    }, []);
    let {lid} = useParams();

    const [lsnData, setData] = useState([]);
    let navigate = useNavigate();

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await pdfLessonService.getPdfLessonById(lid);
                setData(data);
            } catch (error) {
                console.error("Error fetching PDF lesson data:", error);
            }
        };

        fetchData();
    }, [lid]);
    const handlePreviousLesson = async (courseId, orderNum) => {
        const nextLessonOrderNum = orderNum - 1;
        try {
            const data = await pdfLessonService.getPdfLessonByOrderNumber(courseId, nextLessonOrderNum);
            if (data.pdf_lesson_id > 0) {
                navigate(`/pdf-courses/lesson/${data.pdf_lesson_id}`, {replace: true})
            }
        } catch (error) {
            console.error("Error fetching PDF lesson data:", error);
        }
    };

    const handleNextLesson = async (courseId, orderNum) => {
        const nextLessonOrderNum = orderNum + 1;
        try {
            const data = await pdfLessonService.getPdfLessonByOrderNumber(courseId, nextLessonOrderNum);
            await pdfCourseService.IncreasePdfCourseProgress(courseId, lid);
            if (data.pdf_lesson_id > 0) {
                navigate(`/pdf-courses/lesson/${data.pdf_lesson_id}`, {replace: true})
            }
        } catch (error) {
            console.error("Error fetching PDF lesson data:", error);
        }
    };

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const goToPrevPage = () => setPageNumber(pageNumber - 1);
    const goToNextPage = () => setPageNumber(pageNumber + 1);

    if (lsnData && lsnData.length > 0) {
        const lessonData = lsnData[0];

        const openPdfUrl = (pdfName) => {
            const urlPath = `https://api.skillspay.io/thedss/lesson-resources/${pdfName}`;
            window.open(urlPath, '_blank');
        };

        return (
            <div className="row m-0 align-items-center justify-content-center">
                <div className="col-xl-6-thedss col-xxl-5-thedss">
                    <div className="lesson-page pdf-lesson-page px-2">
                        <Link to={`/pdf-courses/course/${lessonData.pdf_course_id}`}>
                            <div className="d-flex align-items-center mt-2 border-bottom"
                                 style={{width: "fit-content"}}>
                                <div className="icon-holder me-1">
                                    <BiArrowBack size="25" color="#3d4465"/>
                                </div>
                                <div className="back-link">Back To Course</div>
                            </div>
                        </Link>
                        <div className="chapter-title text-center mt-3 mb-2">{lessonData.pdf_course_title}</div>
                        <div className="lesson-title mb-2">
                            <BiBookOpen size="20" className="icon"/> {lessonData.title}
                        </div>
                        {/*<Iframe className="rounded-3" url={lessonData.video_url.toString()} width="100%"*/}
                        {/*        height="280px"/>*/}
                        {lessonData.l_resource_file && (
                            <div className="lesson-resources mb-4">
                                <div className="pdf-reader-holder border border-light-subtle">
                                    <Document
                                        file={`https://api.skillspay.io/thedss/lesson-resources/${lessonData.l_resource_file}`}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber}/>
                                    </Document>
                                    <div className="d-flex justify-content-between navigate-btns-holder mt-3">
                                        <button
                                            className="d-flex align-items-center small border-0 navigate-button py-1 px-2 rounded-end-3"
                                            disabled={pageNumber <= 1}
                                            onClick={goToPrevPage}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <GrFormPrevious size="20" color="#3d4465"/>
                                            </div>
                                            <div className="small">
                                                Previous Slide
                                            </div>
                                        </button>
                                        <button
                                            className="d-flex align-items-center small border-0 navigate-button py-1 px-2 rounded-start-3"
                                            disabled={pageNumber >= numPages}
                                            onClick={goToNextPage}
                                        >
                                            <div className="small">
                                                Next Slide
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <GrFormNext size="20" color="#3d4465"/>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="text-center small text-black-50 mb-2">
                                        Slide {pageNumber} of {numPages}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="next-previous d-flex justify-content-between px-3 mt-3">
                            <button className="text-white px-3 py-1 rounded-2"
                                    onClick={() => handlePreviousLesson(lessonData.pdf_course_id, lessonData.order_num)}>Previous
                                Lesson
                            </button>
                            <button className="text-white px-3 py-1 rounded-2"
                                    onClick={() => handleNextLesson(lessonData.pdf_course_id, lessonData.order_num)}>Next
                                Lesson
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <>
            </>
        )
    }
}

export default PdfLesson;