import Axios from "../ServiceCaller";

let getPdfLessons = async (pdf_course_id) => {
    const { data } = await Axios.get(`/pdf-courses/${pdf_course_id}/lessons`);
    return data.PdfLessons;
};
let getPdfLessonsForUser = async (pdf_course_id) => {
    const { data } = await Axios.get(`/pdf-courses/${pdf_course_id}/lessons/user`);
    return data.PdfLessons;
};
let createPdfLesson = async (lessonData) => {
    const { data } = await Axios.post("/pdf-courses/lessons", lessonData);
    return data;
};
let getPdfLessonById = async (id) => {
    const { data } = await Axios.get(`/pdf-courses/lessons/${id}`);
    return data.PdfLesson;
};
let getPdfLessonByOrderNumber = async (c_id, order_num) => {
    const { data } = await Axios.get(`/pdf-courses/lessons/${c_id}/${order_num}`);
    return data;
};
let updatePdfLesson = async (lessonData) => {
    const { data } = await Axios.put("/pdf-courses/lessons", lessonData);
    return data;
};
let deletePdfLesson = async (lesson_id) => {
    try {
        const response = await Axios.delete(`/pdf-courses/lessons`, {
            data: { id: lesson_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting PDF Lesson:", error);
        throw error;
    }
};

export const pdfLessonService = {
    getPdfLessons,
    getPdfLessonsForUser,
    createPdfLesson,
    getPdfLessonById,
    getPdfLessonByOrderNumber,
    updatePdfLesson,
    deletePdfLesson,
};