import {useId} from "react";
import {useQuery} from "react-query";
import {accountService, courseService} from "../../../_services";
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {CourseBox} from "../../../pages/private/Private";

const HeaderWithName = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getUserData());
    const userData = data || {"data": []}

    if (isLoading) {
        return (
            <span></span>);
    }

    return (
        <div>
            {userData.User && userData.User[0].full_name && (
                <div className="">
                    <div className="text-black-50">
                        Hello
                    </div>
                    <div className="main-title fs-4">
                        {userData.User[0].full_name}
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeaderWithName;