import React, {useEffect, useState} from "react";
import {emailService} from "../../../_services";
import {NavLink} from "react-router-dom";

const CampaignForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isCreating, setIsCreating] = useState(false);
    const [templatesData, setData] = useState([]);
    const [customTemplatesData, setCustomData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await emailService.getTemplates();
                const customData = await emailService.getCustomTemplates();
                setData(data);
                setCustomData(customData);
            } catch (error) {
                console.error("Error fetching templates data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const [params, setParamsValues] = useState({
        campaign_name: "",
        from_name: "",
        subject: "",
        custom_template_id: "",
        header_first_title: "",
        header_second_title: "",
        template_id: 0,
        button_text_content: "",
        button_link: "",
        footer_first_text: "",
        footer_second_text: "",
        duration: 0,
        duration_type: "minutes",
    });

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                campaign_name: props.campaign.campaign_name,
                from_name: props.campaign.from_name,
                subject: props.campaign.subject,
                custom_template_id: props.campaign.custom_template_id,
                header_first_title: props.campaign.header_first_title,
                header_second_title: props.campaign.header_second_title,
                template_id: props.campaign.template_id,
                button_text_content: props.campaign.button_text_content,
                button_link: props.campaign.button_link,
                footer_first_text: props.campaign.footer_first_text,
                footer_second_text: props.campaign.footer_second_text,
                duration: props.campaign.duration,
                duration_type: props.campaign.duration_type,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                campaign_name: "",
                from_name: "",
                subject: "",
                custom_template_id: "",
                header_first_title: "",
                header_second_title: "",
                template_id: 0,
                button_text_content: "",
                button_link: "",
                footer_first_text: "",
                footer_second_text: "",
                duration: 0,
                duration_type: "minutes",
            });
        }
    }, [props.editMode, props.campaign]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("campaign_name", params.campaign_name);
            formData.append("from_name", params.from_name);
            formData.append("subject", params.subject);
            formData.append("custom_template_id", params.custom_template_id);
            formData.append("header_first_title", params.header_first_title);
            formData.append("header_second_title", params.header_second_title);
            formData.append("template_id", params.template_id);
            formData.append("button_text_content", params.button_text_content);
            formData.append("button_link", params.button_link);
            formData.append("footer_first_text", params.footer_first_text);
            formData.append("footer_second_text", params.footer_second_text);
            formData.append("duration", params.duration);
            formData.append("duration_type", params.duration_type);

            if (params.template_id === 0) {
                return;
            }

            if (isCreatingMode) {
                await emailService.createCampaign(formData);
            } else {
                formData.append("id", props.campaign.campaign_id);
                formData.append("custom_old_template_id", props.campaign.custom_old_template_id);
                await emailService.updateCampaign(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                campaign_name: "",
                from_name: "",
                subject: "",
                custom_template_id: "",
                header_first_title: "",
                header_second_title: "",
                template_id: 0,
                button_text_content: "",
                button_link: "",
                footer_first_text: "",
                footer_second_text: "",
                duration: 0,
                duration_type: "minutes",
            });
        } catch (error) {
            console.error("Error Creating/Updating campaign:", error);
        } finally {
            setIsCreating(false);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-course-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center">Add a New Campaign</div>
            <div className="form-holder">
                <form onSubmit={onSubmit} encType="multipart/form-data">
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="campaign_name">
                            Campaign Name:
                        </label>
                        <div className="campaign_name-holder d-flex align-items-center">
                            <div className="campaign_name-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Name Of The Campaign"
                                    value={params.campaign_name}
                                    onChange={onChange}
                                    name="campaign_name"
                                    id="campaign_name"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="from_name">
                            From Name:
                        </label>
                        <div className="from_name-holder d-flex align-items-center">
                            <div className="from_name-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="From Name"
                                    value={params.from_name}
                                    onChange={onChange}
                                    name="from_name"
                                    id="from_name"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="subject">
                            Subject:
                        </label>
                        <div className="subject-holder d-flex align-items-center">
                            <div className="subject-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Subject of The Campaign"
                                    value={params.subject}
                                    onChange={onChange}
                                    name="subject"
                                    id="subject"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="custom_template_id">
                            Email Template:
                        </label>
                        <div className="template_id-holder d-flex align-items-center">
                            <div className="template_id-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.custom_template_id}
                                    onChange={onChange}
                                    name="custom_template_id"
                                    id="custom_template_id"
                                    required
                                >
                                    <option value="0">Please Select a Template</option>
                                    {customTemplatesData.length > 0 && (
                                        customTemplatesData.map((item, index) => (
                                            <option value={item.id} key={index}>{item.custom_template_name}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="header_first_title">
                            First Header Title:
                        </label>
                        <div
                            className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                            <textarea
                                className="textarea form-control py-2"
                                placeholder="First Title In The Header"
                                value={params.header_first_title}
                                onChange={onChange}
                                name="header_first_title"
                                id="header_first_title"
                                rows={2}
                                required
                            />
                            </div>
                        </div>
                    </div>
                    {params.custom_template_id === '1' && (
                        <div className="form-group mb-4">
                            <label className="mb-1" htmlFor="header_second_title">
                                Second Header Title (Optional):
                            </label>
                            <div
                                className="description-holder d-flex align-items-center">
                                <div className="description-input flex-grow-1">
                                <textarea
                                    className="textarea form-control py-2"
                                    placeholder="Second Title In The Header"
                                    value={params.header_second_title}
                                    onChange={onChange}
                                    name="header_second_title"
                                    id="header_second_title"
                                    rows={3}
                                />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="template_id">
                            Email Template Content:
                        </label>
                        <div className="template_id-holder d-flex align-items-center">
                            <div className="template_id-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.template_id}
                                    onChange={onChange}
                                    name="template_id"
                                    id="template_id"
                                    required
                                >
                                    <option value="0">Please Select a Template Content</option>
                                    {templatesData.length > 0 && (
                                        templatesData.map((item, index) => (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="button_text_content">
                            Content Of Button:
                        </label>
                        <div className="button-text-holder d-flex align-items-center">
                            <div className="button-text-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Text Of Button"
                                    value={params.button_text_content}
                                    onChange={onChange}
                                    name="button_text_content"
                                    id="button_text_content"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="button_link">
                            Link Of Button:
                        </label>
                        <div className="button-text-holder d-flex align-items-center">
                            <div className="button-text-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="https://....."
                                    value={params.button_link}
                                    onChange={onChange}
                                    name="button_link"
                                    id="button_link"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="footer_first_text">
                            First Footer Text (Optional):
                        </label>
                        <div
                            className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                            <textarea
                                className="textarea form-control py-2"
                                placeholder="First Text In The Footer"
                                value={params.footer_first_text}
                                onChange={onChange}
                                name="footer_first_text"
                                id="footer_first_text"
                                rows={2}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="footer_second_text">
                            Second Footer Text (Optional):
                        </label>
                        <div
                            className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                            <textarea
                                className="textarea form-control py-2"
                                placeholder="Second Text In The Footer"
                                value={params.footer_second_text}
                                onChange={onChange}
                                name="footer_second_text"
                                id="footer_second_text"
                                rows={2}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="duration">
                            Duration After SignUp:
                        </label>
                        <div className="duration-holder d-flex align-items-center">
                            <div className="duration-input flex-grow-1">
                                <input
                                    type="number"
                                    className="form-control text py-2"
                                    placeholder="Duration After SignUp"
                                    value={params.duration}
                                    onChange={onChange}
                                    name="duration"
                                    id="duration"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="duration_type">
                            Duration Type:
                        </label>
                        <div className="duration_type-holder d-flex align-items-center">
                            <div className="duration_type-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.duration_type}
                                    onChange={onChange}
                                    name="duration_type"
                                    id="duration_type"
                                    required
                                >
                                    <option value="minutes">Minutes</option>
                                    <option value="hours">Hours</option>
                                    <option value="days">Days</option>
                                    <option value="months">Months</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {isCreating ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default CampaignForm;
