import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {accountService, orderService, settingService} from "../../../_services";
import stripeLogo from "../../../assets/images/stripe_logo.png";

const StripePaymentForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: props.userDetails.full_name,
                email: props.userDetails.email,
            },
        });

        if (error) {
            setErrorMessage(error.message);
            return;
        }

        const response = await fetch("https://api.skillspay.io/payments/stripe/create-payment-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-token': accountService.getToken(),
            },
            body: JSON.stringify({
                amount: parseInt(props.price * 100),
                currency: 'usd',
                description: props.product_name,
            }),
        });

        const {client_secret} = await response.json();

        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(client_secret, {
            payment_method: paymentMethod.id,
        });

        if (confirmError) {
            setErrorMessage(confirmError.message);
            return;
        }

        if (paymentIntent.status === "succeeded") {
            const orderData = {
                product_name: `${props.product_name}`,
                total: props.price,
                order_id: paymentIntent.id,
                course_id: props.course_id || "",
                order_status: "paid",
                payment_method: "stripe",
                duration_type: props.duration_type,
                duration: props.duration,
            };
            props.setPaymentMethod('Stripe');
            await orderService.createOrder(orderData);
            localStorage.setItem('ORDER_ID', paymentIntent.id);

            try {
                const {email, full_name, password} = props.userDetails;
                const {duration_type, duration} = props;
                const response = await fetch('https://api.skillspay.io/auth/signup', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, full_name, password, ORDER_ID: paymentIntent.id, duration_type, duration }),
                });
                if (response.status === 200) {
                    props.setHolderStep('order-success');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const [showForm, setShowForm] = useState(false);
    return (
        <>
            {showForm ? (
                <form onSubmit={handleSubmit}>
                    {errorMessage && <div className="text-center small text-danger">{errorMessage}</div>}
                    <CardElement className="form-control py-3"/>
                    <div className="pay-button text-center">
                        <button className="border-0 pay-btn py-2 h-auto small px-5 mt-2" type="submit" disabled={!stripe}>Pay</button>
                    </div>
                </form>
            ) : (
                <div className="pay-button">
                    <button type="button"
                            onClick={() => setShowForm(true)}
                            className="pay-btn w-100">
                        <span className="fw-light d-inline-block small fw-bold-500">Pay With Credit Card</span>
                        <img src={stripeLogo} className="stripe-logo" alt=""/>
                    </button>
                </div>
            )}
        </>
    );
};

const StripePayment = (props) => {
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        const fetchStripePromiseKey = async () => {
            try {
                const data = await settingService.getStripePromiseKey();
                setStripePromise(loadStripe(data.promise_key));
            } catch (error) {
                console.error("Error fetching Stripe Promise Key:", error);
            }
        };

        fetchStripePromiseKey();
    }, []);

    return stripePromise ? (
        <Elements stripe={stripePromise}>
            <StripePaymentForm {...props} />
        </Elements>
    ) : (
        <div>Loading payment gateway...</div>
    );
};

export default StripePayment;
