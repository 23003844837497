import {useId, useState, useEffect} from "react";
import {settingService} from "../../../_services";
import {useQuery} from "react-query";

const ChangeCurrency = (props) => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => settingService.getCurrencies());
    const [params, setParamsValues] = useState({currency: {rate: "", code: ""}});

    useEffect(() => {
        const storedRate = localStorage.getItem('skillspay_chosen_currency');
        const storedCode = localStorage.getItem('skillspay_chosen_currency_name');

        if (storedRate && storedCode) {
            setParamsValues({currency: {rate: storedRate, code: storedCode}});
        } else if (data && data.length > 0) {
            const usdCurrency = data.find(item => item.code === "USD") || data[0];
            setParamsValues({currency: {rate: usdCurrency.rate, code: usdCurrency.code}});
        }
    }, [data]);

    const onChange = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedOption = e.target.options[selectedIndex];
        const selectedCurrency = {
            rate: e.target.value,
            code: selectedOption.getAttribute('data-code')
        };

        setParamsValues({
            ...params,
            currency: selectedCurrency
        });

        localStorage.setItem('skillspay_chosen_currency', selectedCurrency.rate);
        localStorage.setItem('skillspay_chosen_currency_name', selectedCurrency.code);
        props.sendRefreshToParent(!props.refresh);
    };

    if (isLoading) {
        return (
            <></>
        );
    }

    if (data && data.length > 0) {
        return (
            <div className="main-currency-change-holder d-flex align-items-center justify-content-center">
                <label htmlFor="currency" className="d-inline-block main-text-color me-2">Select Currency:</label>
                <div>
                    <select
                        style={{width: "fit-content"}}
                        className="form-select py-2 form-select-sm border-start-0 border-top-0 border-bottom-0 rounded-0 mx-auto"
                        value={params.currency.rate}
                        onChange={onChange}
                        name="currency"
                        id="currency"
                    >
                        {data.map((item, index) => (
                            <option
                                value={item.rate}
                                data-code={item.code}
                                key={index}>
                                {item.code}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }

    return null;
};

export default ChangeCurrency;
