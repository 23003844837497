import {BiCheck, BiCheckCircle, BiPlay} from "react-icons/bi";
import {useEffect, useState} from "react";
import {chapterService} from "../../../_services";
import Accordion from 'react-bootstrap/Accordion';
import {FaPlay} from "react-icons/fa6";
import {VideoModal} from "../../../pages/public/Public";

const CourseChapters = (props) => {
    let courseId = props.course_id;

    const [chaptersData, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await chapterService.getChapters(courseId);
                setData(data);
            } catch (error) {
                console.error("Error fetching chapters data:", error);
            }
        };

        fetchData();
    }, [courseId]);
    const [hidden, setHidden] = useState(true);
    const [videoLink, setVideoLink] = useState(true);
    const setVideoModalHidden = (videoLink) => {
        setVideoLink(videoLink)
        setHidden(!hidden);
    }
    const course_introduction = props.intro_video || "";
    return (
        <Accordion.Body>
            <div>
                {course_introduction && (
                    <div className="main-intro-holder bg-white border border-1 p-3 pe-1 rounded-2 mb-2">
                        <div className="chapter-info d-flex align-items-center">
                            <div className="icon-holder me-2"><BiCheck
                                color='green' size="25"/></div>
                            <div className="chapter-title">Course Summary Video</div>
                            <div className="ms-auto d-flex intro-btn-holder align-items-center">
                                <button
                                    onClick={() => setVideoModalHidden(course_introduction.toString())}
                                    className="intro-btn position-relative mx-auto bg-transparent border-0"
                                    type="button">
                                    <div className="content-btn-holder d-flex align-items-center position-relative">
                                        <div className="icon-holder border border-2 d-flex justify-content-center align-items-center p-1 rounded-circle position-absolute"><FaPlay size="15" className="play-icon"/></div>
                                        <div className="button-text rounded-4">Watch</div>
                                    </div>
                                </button>
                                <div className={hidden ? 'video-modal-container hidden' : 'video-modal-container'}>
                                    <VideoModal
                                        url={videoLink}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                        setVideoLink={setVideoLink}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {chaptersData.length > 0 ? (
                    chaptersData.map((chapter, index) => (
                        <div className="bg-white border border-1 p-3 rounded-2 mb-2" key={index}>
                            <div className="chapter-info d-flex align-items-center">
                                    <div className="icon-holder me-2"><BiCheckCircle
                                        color='green' size="25"/></div>
                                    <div className="chapter-title">{chapter.title}</div>
                                </div>
                            </div>
                        )
                    )
                ) : (
                    <></>
                )
                }
            </div>
        </Accordion.Body>
    );
}

export default CourseChapters;