import {accountService} from "../../../_services";
import {useNavigate} from "react-router-dom";
import {BiLogOut} from "react-icons/bi";
import {useEffect} from "react";

const Header = (props) => {
    useEffect(() => {
        document.title = `Admin - ${props.title}`;
    }, [props.title]);
    let navigate = useNavigate();

    const logout = () => {
        accountService.logout();
        navigate("/auth/login")
    }

    return (
        <div className="dashboard-main-header ps-4 bg-white d-flex align-items-center justify-content-between">
            <div className="title-holder ps-1 ps-md-2 ps-lg-3 fs-5 fw-bold">
                {props.title}
            </div>
            <div onClick={logout} className="d-flex align-items-center c-pointer header-l-link me-2 me-lg-3">
                <BiLogOut size="25px" color="red"/>
                <span className="ms-1 d-none d-sm-block">Logout </span>
            </div>
        </div>
    );
}

export default Header;