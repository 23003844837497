import {useId} from "react";
import {useQuery} from "react-query";
import {pdfCourseService} from "../../_services";
import {CourseBox} from "./Private";

const AllPdfCourses = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => pdfCourseService.getPdfCourses());

    if (isLoading) {
        return (
            <span></span>);
    }

    return (
        <div className="courses-page px-3">
            <div className="pdf-courses-holder my-4">
                <div className="main-title mb-3">Beginner Starter Courses</div>
                {data.length > 0 && (
                    <div className="row">
                        {
                            data.map((course) => (
                                <div key={course.id} className="col-4 mb-3">
                                    <CourseBox
                                        pdf={true}
                                        course_id={course.id}
                                        bg={course.background_name}
                                        title={`${course.title.substring(0, 40)}`}
                                        level={course.level}
                                    />
                                </div>

                            ))
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllPdfCourses;