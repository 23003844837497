import {HeaderWithName, VideoCoursesHolder, PdfCoursesHolder, CourseBoxStarted} from "./Private";
import {FcBusinessman, FcBusinesswoman} from "react-icons/fc";
import {FaPlus} from "react-icons/fa6";
import {BiAlarm, BiCalendar} from "react-icons/bi";
import CircularProgress from "../../components/private/animated/CircularProgress";

const Courses = () => {

    return (
        <div className="courses-page px-3 pt-4">
            <HeaderWithName/>
            <div className="landing-section py-3 px-4 shadow-sm my-4 rounded-3">
                <div className="main-title mb-1">30 Days Study Plan</div>
                <div className="text-black-50 mb-3 small">We recommend this plan for better results</div>
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <div className="text-black-50 fw-bold-500 small mb-2">Tasks to Do</div>
                        <div className="d-flex align-items-center">
                            <FcBusinessman size="40" className="border bg-light rounded-circle p-1"/>
                            <FcBusinesswoman style={{marginLeft: "-6px"}} size="40" className="border bg-light rounded-circle p-1"/>
                            <FcBusinessman style={{marginLeft: "-6px"}} size="40" className="border bg-light rounded-circle p-1"/>
                            <FaPlus style={{marginLeft: "-6px"}} size="40" className="border bg-light rounded-circle p-2" color="orange"/>
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="text-black-50 fw-bold-500 small mb-2">Day Number</div>
                        <div className="small-title fw-bold-600 small">01</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <div className="text-black-50 fw-bold-500 small mb-2">Deadline</div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="d-flex align-items-center justify-content-center me-1">
                                <BiAlarm size="16" color="gray" style={{marginBottom: "1px"}}/>
                            </div>
                            <div className="small text-secondary fw-bold-500">
                                2:00 PM - 4:00 PM
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center justify-content-center me-1">
                                <BiCalendar size="16" color="gray" style={{marginBottom: "1px"}}/>
                            </div>
                            <div className="small text-secondary fw-bold-500">
                                June 16, 2024
                            </div>
                        </div>
                    </div>
                    <div className="landing-progress-holder">
                        <CircularProgress landing={true} score_value={68}/>
                    </div>
                </div>
            </div>
            <PdfCoursesHolder/>
            <VideoCoursesHolder/>
            <CourseBoxStarted/>
        </div>
    );
}

export default Courses;