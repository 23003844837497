import {Header, PdfLessonForm, PdfLessonRow} from "./Admin";
import {useEffect, useState} from "react";
import {pdfLessonService} from "../../_services";
import {BiArrowBack} from "react-icons/bi";
import {Link, useParams} from "react-router-dom";

const PdfLessons = () => {

    let {cid} = useParams();
    const [pdfLessonsData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [titleFromChild, setTitleFromChild] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [pdfLessonToEdit, setPdfLessonToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    const getTitleFromChild = (pdfCourseTitle) => {
        setTitleFromChild(pdfCourseTitle);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await pdfLessonService.getPdfLessons(cid);
                setData(data);
            } catch (error) {
                console.error("Error fetching pdf lessons data:", error);
            }
        };

        fetchData();
    }, [refresh, cid]);

    const handleEditPdfLesson = (pdfLesson) => {
        setEditMode(true);
        setPdfLessonToEdit(pdfLesson);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="PDF Lessons"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-lessons-page">
                    <Link className="big-title back fs-6 mb-2 mb-lg-3 d-flex align-items-center c-pointer"
                          to="/admin/pdf-courses">
                        <BiArrowBack className="me-2 icon" size="25"/>Back To Courses List
                    </Link>
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="big-title me-3 mb-2 mb-md-0">Lessons List of "{titleFromChild}"</div>
                        <div className="add-btn-holder">
                            <button className="btn btn-primary py-1 btn-sm" disabled={!hidden} type="button"
                                    onClick={setFormHiddenAdd}>Add New Lesson
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <PdfLessonForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            pdf_course_id={cid}
                            editMode={editMode}
                            pdfLesson={pdfLessonToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Title</td>
                                <td className="text-black-50">Order</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {pdfLessonsData.length > 0 ? (
                                pdfLessonsData.map((pdfLesson) => (
                                    <PdfLessonRow
                                        key={pdfLesson.id}
                                        pdf_lesson_id={pdfLesson.id}
                                        pdf_course_id={pdfLesson.pdf_course_id}
                                        pdf_course_title={pdfLesson.pdf_course_title}
                                        title={pdfLesson.title}
                                        order_num={pdfLesson.order_num}
                                        created_date={pdfLesson.created_date}
                                        sendTitleToParent={getTitleFromChild}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditPdfLesson={handleEditPdfLesson}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PdfLessons;