import {useEffect, useState} from "react";
import {courseService} from "../../../_services";
import Accordion from "react-bootstrap/Accordion";
import defaultChapterBg from "../../../assets/images/default_chapter_bg.jpg";
import CourseChapters from "./CourseChapters";

const CoursesCatalog = (props) => {
    const [coursesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await courseService.getFeaturedCourses();
                setData(data);
            } catch (error) {
                console.error("Error fetching courses data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    return (
        <>
            {props.pagedeal ? (
                <div className="main-courses-catalog rounded-2 my-4 pt-4 pb-3 px-2">
                    {!props.featured && (
                        <div className="big-title fw-bold text-center mb-3">Featured Courses</div>
                    )}
                    <div className="course-chapters">
                        <Accordion>
                            {coursesData.length > 0 ? (
                                coursesData.map((course, index) => (
                                    <Accordion.Item eventKey={`${index}`} key={index}>
                                        <Accordion.Header>
                                            <div className="chapter-holder d-flex align-items-center w-100">
                                                <div className="img-holder me-3">
                                                    {course.background_name ? (
                                                        <img className="img-fluid rounded-3" alt={course.title}
                                                             src={`https://api.skillspay.io/thedss/images/${course.background_name}`}
                                                        />
                                                    ) : (
                                                        <img className="img-fluid rounded-3" alt={course.title}
                                                             src={defaultChapterBg}
                                                        />
                                                    )}
                                                </div>
                                                <div className="chapter-info pe-3 w-100">
                                                    <div className="chapter-title">{course.title}</div>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="lessons-count mt-1 mt-sm-2-sm-2">
                                                            {course.level}
                                                        </div>
                                                        <div className="lessons-count mt-1 mt-sm-2-sm-2">
                                                            {course.lessons_num} Lessons
                                                        </div>
                                                        {/*<div className="lessons-count mt-1 mt-sm-2-sm-2">*/}
                                                        {/*    {course.course_duration} Hours*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <CourseChapters
                                            course_id={course.id}
                                            intro_video={course.intro_video}
                                        />
                                    </Accordion.Item>
                                ))
                            ) : (
                                <></>
                            )}
                        </Accordion>
                    </div>
                </div>
            ) : (
                <div className="main-courses-catalog rounded-2 my-4 pt-4 pb-3 px-2 d-xl-none-thedss-version">
                    {!props.featured && (
                        <div className="big-title fw-bold text-center mb-3">Featured Courses</div>
                    )}
                    <div className="course-chapters">
                        <Accordion>
                            {coursesData.length > 0 ? (
                                coursesData.map((course, index) => (
                                    <Accordion.Item eventKey={`${index}`} key={index}>
                                        <Accordion.Header>
                                            <div className="chapter-holder d-flex align-items-center w-100">
                                                <div className="img-holder me-3">
                                                    {course.background_name ? (
                                                        <img className="img-fluid rounded-3" alt={course.title}
                                                             src={`https://api.skillspay.io/thedss/images/${course.background_name}`}
                                                        />
                                                    ) : (
                                                        <img className="img-fluid rounded-3" alt={course.title}
                                                             src={defaultChapterBg}
                                                        />
                                                    )}
                                                </div>
                                                <div className="chapter-info pe-3 w-100">
                                                    <div className="chapter-title">{course.title}</div>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="lessons-count mt-1 mt-sm-2-sm-2">
                                                            {course.level}
                                                        </div>
                                                        <div className="lessons-count mt-1 mt-sm-2-sm-2">
                                                            {course.lessons_num} Lessons
                                                        </div>
                                                        {/*<div className="lessons-count mt-1 mt-sm-2-sm-2">*/}
                                                        {/*    {course.course_duration} Hours*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <CourseChapters
                                            course_id={course.id}
                                            intro_video={course.intro_video}
                                        />
                                    </Accordion.Item>
                                ))
                            ) : (
                                <></>
                            )}
                        </Accordion>
                    </div>
                </div>
            )}
        </>
    );
}

export default CoursesCatalog;