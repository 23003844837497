import "./public.css";
import logoBrand from "../../assets/images/logo.png";
import {useEffect, useId, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {orderService} from "../../_services";
import {useQuery} from "react-query";
import {FaCheck} from "react-icons/fa6";
import {BiArrowBack} from "react-icons/bi";

const ThankYou = () => {
    useEffect(() => {
        document.title = "Skillspay - Thank You";
    }, []);

    let randomID = useId();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get('transactionId');
    const navigate = useNavigate();
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [orderAmount, setOrderAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('')

    useEffect(() => {
        if (!transactionId) {
            return (
                navigate('/', {replace: true})
            );
        }
    }, [navigate]);

    const {isLoading, data} = useQuery(randomID, () => {
        if (transactionId) {
            orderService.verifyTransaction(transactionId).then(res => {
                console.log(res)
                if (res && res.status && res.status === 'order_status_success') {
                    setOrderAmount(res.payment_amount)
                    setPaymentMethod(res.payment_method)
                    setOrderSuccess(true)
                } else {
                    navigate('/')
                }
            })
        }
    });

    return (
        <div className="pricing-plans-page thank-you-page h-100 mx-auto"
             style={{minHeight: '100vh', maxWidth: '500px'}}>
            <div className="pricing-plans-header d-flex align-items-center justify-content-between px-3 bg-white">
                <div className="logo-holder text-center">
                    <img className="img-fluid" src={logoBrand} alt="skills pay"/>
                </div>
            </div>
            {orderSuccess && (
                <div className="thank-you-content">
                    <div className="circle-holder mx-auto">
                        <div className="circle-1 rounded-circle p-4">
                            <div className="circle-2 rounded-circle p-4">
                                <div className="circle-3 rounded-circle p-4">
                                    <FaCheck size="100" color="#fff"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="big-title fw-bold text-center mt-5">
                        Payment Successful!
                    </div>
                    <div className="w-75 mx-auto horizontal-line my-4"/>
                    <div className="payment-info main-text-color text-center fs-4">
                        Amount paid: <span className="info fw-bold-600">${orderAmount}</span>
                    </div>
                    <div className="payment-info main-text-color text-center fs-4">
                        Paid by: <span className="info fw-bold-600">{paymentMethod}</span>
                    </div>
                    <div className="link-btn-holder mt-5 text-center px-4">
                        <Link to="/auth/login" className="d-inline-block link w-100 rounded-3 py-3 fw-bold-600">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex align-items-center justify-content-center mx-1">
                                    <BiArrowBack size="25" color="#fff"/>
                                </div>
                                <div className="text mx-1 text-white">
                                    Back to Login
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ThankYou;