import {useId} from "react";
import {useQuery} from "react-query";
import {courseService} from "../../_services";
import {CourseBox} from "./Private";

const AllCourses = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => courseService.getCourses());

    if (isLoading) {
        return (
            <span></span>);
    }

    return (
        <div className="courses-page px-3">
            <div className="video-courses-holder my-4">
                <div className="main-title mb-3">Advanced Video Course Upgrades</div>
                {data.length > 0 && (
                    <div className="row">
                        {
                            data.map((course) => (
                                <div key={course.id} className="col-6 mb-3">
                                    <CourseBox
                                        course_id={course.id}
                                        bg={course.background_name}
                                        title={`${course.title.substring(0, 50)}`}
                                        level={course.level}
                                    />
                                </div>
                            ))
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllCourses;