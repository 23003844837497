import {BiEdit, BiTrash} from "react-icons/bi";
import {useEffect, useState} from "react";
import {emailService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const TemplateRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteTemplate = async () => {
        setIsDeleting(true);

        try {
            await emailService.deleteTemplate(props.template_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting course:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditTemplate = () => {
        props.handleEditTemplate(props);
        props.sendHiddenToParent(!props.hidden);
    };
    if (props.template_id > 0) {
        return (
            <tr>
                <td>
                    <span>{props.name}</span>
                </td>
                <td>
                    {`${props.content.substring(0, 30)}...`}
                </td>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
                <td>
                <span className="text-center">
                    <button className="border-0 bg-transparent" onClick={handleEditTemplate} disabled={!props.hidden}>
                        <BiEdit size="22" className="edit-icon"/>
                    </button>
                    {isDeleting ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <BiTrash onClick={handleDeleteTemplate} size="22" className="remove-icon mx-auto"/>
                    )}
                </span>
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
                <td colSpan="5" className="text-center">There is no template yet</td>
            </tr>
        );
    }
}

export default TemplateRow;