import "./public.css";
import goalBg from "../../assets/images/deal/goal_bg.png";
import freelancing from "../../assets/images/deal/freelancing.webp";
import five_stars from "../../assets/images/deal/five_stars.png";
import skills_now from "../../assets/images/deal/skills_now.webp";
import skills_goal from "../../assets/images/deal/skills_goal.webp"
import power_now from "../../assets/images/deal/power_now.png";
import power_goal from "../../assets/images/deal/power_goal.png";
import lightbulb from "../../assets/images/deal/lightbulb.webp";
import logoBrand from "../../assets/images/logo.png";
import {BiCheckCircle, BiTargetLock} from "react-icons/bi";
import {useEffect, useState} from "react";
import {accountService} from "../../_services";
import Timer from "../../components/private/animated/Timer";
import {Link, useNavigate} from "react-router-dom";
import screen_1 from "../../assets/images/deal/screen1.jpeg"
import screen_2 from "../../assets/images/deal/screen2.jpeg"
import screen_3 from "../../assets/images/deal/screen3.jpeg"
import screen_4 from "../../assets/images/deal/screen4.jpeg"
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
// import required modules
import {Autoplay, EffectCoverflow, Navigation, Pagination} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {GoGoal} from "react-icons/go";
import {CoursesCatalog, VideoModal} from "./Public";
import {Header} from "../private/Private";
import testimonial_pic1 from "../../assets/images/public/testimonial_pic1.png";
import {BsPlay} from "react-icons/bs";
import {AiFillStar} from "react-icons/ai";
import testimonial_pic2 from "../../assets/images/public/testimonial_pic2.png";
import testimonial_pic3 from "../../assets/images/public/testimonial_pic3.png";
import testimonial_pic4 from "../../assets/images/public/testimonial_pic4.png";
import fox_icon from "../../assets/images/fox-icon.png";
import benzinga_icon from "../../assets/images/benzinga-icon.svg";
import usa_today_icon from "../../assets/images/usa-today-icon.svg";
import digital_journal_icon from "../../assets/images/digital-journal-icon.png";
import ncn_icon from "../../assets/images/ncn-icon.png";

const Deal = () => {
    useEffect(() => {
        document.title = "Skillspay - Deal";
    }, []);
    let navigate = useNavigate();
    let gender_type = localStorage.getItem("gender_type") ? localStorage.getItem("gender_type") : "male";
    let gender_age = localStorage.getItem("gender_age") ? localStorage.getItem("gender_age") : "18";
    let score_value = localStorage.getItem("score_value") ? localStorage.getItem("score_value") : "94";

    const [genderType, setGender] = useState(gender_type);
    const [age, setAge] = useState(gender_age);
    const [scoreValue, setScoreValue] = useState(score_value);
    const [goalValue, setGoalValue] = useState('Grow wealth');
    const [targetValue, setTargetValue] = useState('More than $100K');
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('ORDER_ID')) {
            if (localStorage.getItem("skip_all_questions") !== 'thedss_skip_all_questions') {
                if (!accountService.isQuizDone()) {
                    navigate('/auth/quiz', {replace: true});
                }
            }
        }
        const storedState = localStorage.getItem("quizState");
        if (storedState) {
            const parsedState = JSON.parse(storedState);
            setGoalValue(parsedState.params.something_special_to_achieve);
            setTargetValue(parsedState.params.income_level_wanted);
        }
    }, [navigate]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;

            // Change the threshold (1000) as needed
            setIsScrolled(scrollY >= 600);
        };

        // Attach the event listener to the window
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if (accountService.isLogged()) {
            navigate('/courses', {replace: true});
        }
    }, [navigate]);

    const [videoLink, setVideoLink] = useState(true);
    const [hidden, setHidden] = useState(true);
    const setVideoModalHidden = (videoLink) => {
        setVideoLink(videoLink)
        setHidden(!hidden);
    }

    return (
        <div className="deal-page h-100 mx-auto holder-of-desktop-page">
            <Header/>
            <div className="row m-0 align-items-center justify-content-center">
                <div className="col-xl-6-thedss p-0">
                    <div className="page-content p-1">
                        <div className="goal-holder rounded-2 mt-2 mb-2 border">
                            <div className="d-flex justify-content-between py-3">
                                <div className="big-title fw-bold text-center w-50 fs-5">Now</div>
                                <div className="big-title fw-bold text-center w-50 fs-5">Goal</div>
                            </div>
                            <div className="img-holder d-flex text-center rounded-bottom"
                                 style={{backgroundImage: `url(${goalBg})`}}>
                                <div className="w-50"><img alt="deal now" className="img-fluid"
                                                           src={require(`../../assets/images/deal/${genderType.toLowerCase()}_${age}_deal_now.webp`)}
                                /></div>
                                <div className="w-50"><img alt="deal goal" className="img-fluid"
                                                           src={require(`../../assets/images/deal/${genderType.toLowerCase()}_${age}_deal_goal.webp`)}
                                /></div>
                            </div>
                            <div className="infos-holder d-flex p-3 pb-4">
                                <div className="w-50 text-center">
                                    <div className="big-title fw-bold fs-6">Flexibility</div>
                                    <img className="img-fluid skill" alt="skill now" src={skills_now}/>
                                    <div className="big-title fw-bold fs-6 mt-3">Earning power</div>
                                    <img className="img-fluid poswer" alt="power now" src={power_now}/>
                                    <div className="small-title mt-1">Limited</div>
                                </div>
                                <div className="w-50 text-center">
                                    <div className="big-title fw-bold fs-6">Flexibility</div>
                                    <img className="img-fluid skill" alt="skill goal" src={skills_goal}/>
                                    <div className="big-title fw-bold fs-6 mt-3">Earning power</div>
                                    <img className="img-fluid poswer" alt="power goal" src={power_goal}/>
                                    <div className="small-title mt-1">High</div>
                                </div>
                            </div>
                        </div>
                        <div className="small-title small my-3 text-center px-2">
                            This is not a guarantee or promise of results. Results may vary from one individual to
                            another based
                            on
                            several different factors.
                        </div>
                        <div className="readiness-holder bg-white rounded-2 my-4 p-2">
                            <div className="big-title fw-bold mb-3 fs-5">Your readiness: <span>{scoreValue}%</span>
                            </div>
                            <div className="readiness-content d-flex align-items-center px-3 py-4 rounded-3 border">
                                <div className="content me-2">
                                    <span>30 Days</span> with Skillspay is enough to start your making money online
                                    Journey with
                                    our lessons, tasks to do and support.
                                </div>
                                <div className="icon-holder">
                                    <img className="img-fluid" src={lightbulb} alt="lightbulb"/>
                                </div>
                            </div>
                        </div>
                        <div className="freelancing-holder rounded-2 my-4 p-4">
                            <div className="big-title fw-bold text-center mb-3">Making Money Online is easier than you
                                think!
                            </div>
                            <div className="content-holder">
                                <div className="imgs-holder">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="img-holder position-relative me-2">
                                            <img className="img-fluid" alt="freelancer example"
                                                 src={require(`../../assets/images/quiz/${genderType.toLowerCase()}_${age}.webp`)}
                                            />
                                            <div className="gradient-bg position-absolute end-0 bottom-0 w-100"></div>
                                        </div>
                                        <div className="img-holder ms-2">
                                            <img className="img-fluid" alt="freelancing values" src={freelancing}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="featrs mt-4">
                                    <div className="d-flex align-items-center mb-1 justify-content-center">
                                        <div className="icon-holder me-2">👌</div>
                                        <div className="featr text-color">No need to be a professional</div>
                                    </div>
                                    <div className="d-flex align-items-center mb-1 justify-content-center">
                                        <div className="icon-holder me-2">👌</div>
                                        <div className="featr text-color">No need for a university degree</div>
                                    </div>
                                    <div className="d-flex align-items-center mb-1 justify-content-center">
                                        <div className="icon-holder me-2">👌</div>
                                        <div className="featr text-color">Work at your own pace and terms</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="all-payment-holder bg-white rounded-2 my-4 p-2" id="all_payment_holder">
                            <div className="big-title fw-bold text-center mb-3">Try Skillspay for 30 days,<br/>and you
                                will:
                            </div>
                            <div className="features-holder">
                                <div className="feature d-flex align-items-center px-2 mb-3">
                                    <div className="icon-holder me-2"><BiCheckCircle size="25" color="#7abf4c"/></div>
                                    <div className="feature-value text-color">Become a professional in a niche you
                                        pick
                                    </div>
                                </div>
                                <div className="feature d-flex align-items-center px-2 mb-3">
                                    <div className="icon-holder me-2"><BiCheckCircle size="25" color="#7abf4c"/></div>
                                    <div className="feature-value text-color">Master Skills Required to earn $10.000+ a
                                        month
                                    </div>
                                </div>
                                <div className="feature d-flex align-items-center px-2 mb-3">
                                    <div className="icon-holder me-2"><BiCheckCircle size="25" color="#7abf4c"/></div>
                                    <div className="feature-value text-color">Grow your income by discovering additional
                                        income
                                        sources
                                    </div>
                                </div>
                                <div className="feature d-flex align-items-center px-2 mb-3">
                                    <div className="icon-holder me-2"><BiCheckCircle size="25" color="#7abf4c"/></div>
                                    <div className="feature-value text-color"> Become more confident as generating
                                        revenue will
                                        become more easier
                                    </div>
                                </div>
                            </div>
                            <div className="goal-income-holder row px-3 my-4">
                                <div className="col-6">
                                    <div className="content p-2 rounded-3" style={{backgroundColor: "#f6f6f6"}}>
                                        <div className="text-center text-black-50 small">Your Goal</div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="icon-holder me-1"><GoGoal color="#fe634e" size="20"/></div>
                                            <div className="value fw-bold" style={{color: "#3d4465"}}>{goalValue}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="content p-2 rounded-3" style={{backgroundColor: "#f6f6f6"}}>
                                        <div className="text-center text-black-50 small">Target</div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="icon-holder me-1"><BiTargetLock color="#fe634e" size="25"/>
                                            </div>
                                            <div className="value fw-bold"
                                                 style={{color: "#3d4465"}}>{targetValue}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isScrolled ?
                                <div
                                    className="try-now-floating-btn d-flex align-items-center justify-content-center p-3 position-fixed sticky-bottom bg-white shadow-lg translate-middle-x start-50 rounded-top-4"
                                    style={{width: "100%", maxWidth: "480px", zIndex: 900}}
                                >
                                    <div className="pay-button bg-white text-center">
                                        <Link to="/pricing-plans" className="pay-btn py-2 px-5 rounded-3">
                                            GET STARTED
                                        </Link>
                                    </div>
                                </div>
                                : null}
                        </div>
                        <div className="testimonials-section mt-3 pt-2 pb-3 px-2 shadow-sm">
                            <div className="main-text-color fw-bold fs-5 text-center mb-1">Don’t take our word for it…
                            </div>
                            <div className="main-text-color small text-center mb-2">Testimonies from past students.
                            </div>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={15}
                                loop={true}
                                className="myTestimonialSwiper"
                                pagination={true}
                                modules={[Autoplay, Pagination]}
                            >
                                <SwiperSlide>
                                    <div className="testimonial-box px-4">
                                        <div className="box-content rounded-3"
                                             onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955012948')}>
                                            <div className="img-holder rounded-3 position-relative">
                                                <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                                     src={testimonial_pic1} alt="Sarah W."/>
                                                <div
                                                    className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div
                                                            className="d-flex align-items-center justify-content-center">
                                                            <BsPlay size="50" color="#fff"/>
                                                        </div>
                                                        <div className="review-holder">
                                                            <div className="name text-white fs-5">
                                                                Sarah W.
                                                            </div>
                                                            <div className="review-stars">
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-3 rounded-bottom-3 pb-4"
                                                 style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                                "Skillspay changed my life"
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="testimonial-box px-4">
                                        <div className="box-content rounded-3"
                                             onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955012273')}>
                                            <div className="img-holder rounded-3 position-relative">
                                                <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                                     src={testimonial_pic2} alt="Peter B."/>
                                                <div
                                                    className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div
                                                            className="d-flex align-items-center justify-content-center">
                                                            <BsPlay size="50" color="#fff"/>
                                                        </div>
                                                        <div className="review-holder">
                                                            <div className="name text-white fs-5">
                                                                Peter B.
                                                            </div>
                                                            <div className="review-stars">
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-3 rounded-bottom-3 pb-4"
                                                 style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                                "Skillspay gave me an opportunity I’ll never forget"
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="testimonial-box px-4">
                                        <div className="box-content rounded-3"
                                             onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955009904')}>
                                            <div className="img-holder rounded-3 position-relative">
                                                <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                                     src={testimonial_pic3} alt="Donald M."/>
                                                <div
                                                    className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div
                                                            className="d-flex align-items-center justify-content-center">
                                                            <BsPlay size="50" color="#fff"/>
                                                        </div>
                                                        <div className="review-holder">
                                                            <div className="name text-white fs-5">
                                                                Donald M.
                                                            </div>
                                                            <div className="review-stars">
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-3 rounded-bottom-3 pb-4"
                                                 style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                                "Skillspay is the ultimate resource for success and a game changer"
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="testimonial-box px-4">
                                        <div className="box-content rounded-3"
                                             onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955010794')}>
                                            <div className="img-holder rounded-3 position-relative">
                                                <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                                     src={testimonial_pic4} alt="Cyndy F."/>
                                                <div
                                                    className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div
                                                            className="d-flex align-items-center justify-content-center">
                                                            <BsPlay size="50" color="#fff"/>
                                                        </div>
                                                        <div className="review-holder">
                                                            <div className="name text-white fs-5">
                                                                Cyndy F.
                                                            </div>
                                                            <div className="review-stars">
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                                <AiFillStar size="25" color="#E0B703"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-3 rounded-bottom-3 pb-4"
                                                 style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                                "Thanks for the opportunity to learn and earn"
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <CoursesCatalog pagedeal="yes"/>
                        <div className="screens-slider-holder bg-white rounded-2 my-4 py-2">
                            <div className="big-title fw-bold text-center mb-3 pt-2 px-2">Access Skillspay anywhere
                                using your mobile
                                device
                            </div>
                            <div className="slider-holder">
                                <Swiper
                                    effect={'coverflow'}
                                    grabCursor={true}
                                    centeredSlides={true}
                                    initialSlide={1}
                                    loop={true}
                                    slidesPerView={'auto'}
                                    coverflowEffect={{
                                        rotate: 0,
                                        stretch: 0,
                                        depth: 100,
                                        modifier: 2.5,
                                    }}
                                    // pagination={{ el: '.swiper-pagination', clickable: true }}
                                    // navigation={{
                                    //     nextEl: '.swiper-button-next',
                                    //     prevEl: '.swiper-button-prev',
                                    //     clickable: true,
                                    // }}
                                    modules={[EffectCoverflow, Pagination, Navigation]}
                                    className="swiper_container"
                                >
                                    <SwiperSlide style={{width: "fit-content"}}>
                                        <div className="text-center h-100 w-100">
                                            <img className="h-100" src={screen_1} alt="slide_image"/>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{width: "fit-content"}}>
                                        <div className="text-center h-100 w-100">
                                            <img className="h-100" src={screen_2} alt="slide_image"/>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{width: "fit-content"}}>
                                        <div className="text-center h-100 w-100">
                                            <img className="h-100" src={screen_3} alt="slide_image"/>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{width: "fit-content"}}>
                                        <div className="text-center h-100 w-100">
                                            <img className="h-100" src={screen_4} alt="slide_image"/>
                                        </div>
                                    </SwiperSlide>

                                    {/*<div className="slider-controler">*/}
                                    {/*    <div className="swiper-button-prev slider-arrow">*/}
                                    {/*        <ion-icon name="arrow-back-outline"></ion-icon>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="swiper-button-next slider-arrow">*/}
                                    {/*        <ion-icon name="arrow-forward-outline"></ion-icon>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="swiper-pagination"></div>*/}
                                    {/*</div>*/}
                                </Swiper>
                            </div>
                        </div>
                        <div className="as-seen-section bg-white mt-4 mb-5 py-2 pb-3 px-2 shadow-sm"
                             style={{backgroundColor: "#f9f9f9"}}>
                            <div className="title text-center mb-2">As Seen On</div>
                            <div className="as-seen-content row m-0 justify-content-evenly mb-5">
                                <div className="col-2 mb-2 text-center">
                                    <div className="icon-holder">
                                        <img
                                            src={fox_icon}
                                            alt="fox"
                                            width="60"
                                            height="30"
                                        />
                                    </div>
                                </div>
                                <div className="col-2 mb-2 text-center">
                                    <div className="icon-holder">
                                        <img
                                            src={benzinga_icon}
                                            alt="benzinga"
                                            width="60"
                                            height="30"
                                        />
                                    </div>
                                </div>
                                <div className="col-2 mb-2 text-center">
                                    <div className="icon-holder">
                                        <img
                                            src={usa_today_icon}
                                            alt="usa today"
                                            width="60"
                                            height="30"
                                        />
                                    </div>
                                </div>
                                <div className="col-2 mb-2 text-center">
                                    <div className="icon-holder">
                                        <img
                                            src={digital_journal_icon}
                                            alt="digital journal"
                                            width="60"
                                            height="30"
                                        />
                                    </div>
                                </div>
                                <div className="col-2 mb-2 text-center">
                                    <div className="icon-holder">
                                        <img
                                            src={ncn_icon}
                                            alt="ncn"
                                            width="60"
                                            height="30"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={hidden ? 'video-modal-container hidden' : 'video-modal-container'}>
                <VideoModal
                    url={videoLink}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                    setVideoLink={setVideoLink}
                />
            </div>
        </div>
    );
}

export default Deal;