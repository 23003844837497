import {Link} from "react-router-dom";
import {useState} from "react";
import {userService} from "../../../_services";
import {BiTrash} from "react-icons/bi";

const QuizUserRow = (props) => {
    const getDate = (dt) => {
        let date = new Date(dt);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date.getFullYear();
        return mm + '/' + dd + '/' + yyyy;
    }
    const [isDeleting, setIsDeleting] = useState(false);
    const handleDeleteQuizUser = async () => {
        setIsDeleting(true);

        try {
            await userService.deleteQuizUser(props.quiz_user_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting quiz user:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <tr>
            <td>
                {props.email}
            </td>
            <td>
                {props.account_status}
            </td>
            <td>
                {props.gender}
            </td>
            <td>
                {props.age}
            </td>
            <td>
                {props.goal}
            </td>
            <td>
                <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
            </td>
            <td>
                <span className="text-center">
                    <Link className="name-link me-2" to={`/admin/quiz-users/user/${props.quiz_user_id}`}>
                        <button
                            className="edit-btn py-1 rounded-2 btn btn-sm btn-success border-0">Details</button>
                    </Link>
                    {isDeleting ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <BiTrash
                            onClick={() => (props.quiz_user_id === 1 ? null : handleDeleteQuizUser())}
                            size="25"
                            className={`remove-icon mx-auto ${props.quiz_user_id === 1 ? 'disabled' : ''}`}
                        />
                    )}
                </span>
            </td>
        </tr>
    );
}

export default QuizUserRow;