import Axios from "../ServiceCaller";

let getUsers = async () => {
    const { data } = await Axios.get("/users");
    return data.Users;
};
let getQuizUsers = async () => {
    const { data } = await Axios.get("/users/quiz-users");
    return data.QuizUsers;
};
// let createUser = async (credentials) => {
//     const { data } = await Axios.post("/users", credentials);
//     return data;
// };
let getUserById = async (user_id) => {
    const { data } = await Axios.get(`/users/${user_id}`);
    return data.User;
};
let getQuizUserById = async (user_id) => {
    const { data } = await Axios.get(`/users/quiz-users/${user_id}`);
    return data.User;
};
// let updateUser = async (credentials) => {
//     const { data } = await Axios.put("/users", credentials);
//     return data;
// };
let deleteUser = async (user_id) => {
    try {
        const response = await Axios.delete(`/users`, {
            data: { id: user_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting User:", error);
        throw error;
    }
};
let deleteQuizUser = async (quiz_user_id) => {
    try {
        const response = await Axios.delete(`/users/quiz-users`, {
            data: { id: quiz_user_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting User:", error);
        throw error;
    }
};

export const userService = {
    getUsers,
    getQuizUsers,
    // createUser,
    getUserById,
    // updateUser,
    deleteUser,
    getQuizUserById,
    deleteQuizUser,
};