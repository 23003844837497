import {useEffect, useState} from "react";
import {settingService} from "../../../_services";

const CourseForm = (props) => {
    const [isCreating, setIsCreating] = useState(false);

    const [params, setParamsValues] = useState({
        slide_duration: "",
    });
    useEffect(() => {
        setParamsValues({
            ...params,
            slide_duration: props.slider_duration,
        });
    }, [props]);

    const [file, setFile] = useState(null);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("slide_duration", params.slide_duration);
            formData.append("slider_image", file);

            await settingService.createSliderSettings(formData);

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setFile(null);
        } catch (error) {
            console.error("Error Creating HomePageSlider Settings:", error);
        } finally {
            setIsCreating(false);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
        setFile(null);
    }

    return (
        <div className="main-form-holder add-sliderSettings-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="form-holder">
                <form onSubmit={onSubmit} encType="multipart/form-data">
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="slide_duration">
                            Slide Duration (ms):
                        </label>
                        <div className="slide_duration-holder d-flex align-items-center">
                            <div className="slide_duration-input flex-grow-1">
                                <input
                                    type="number"
                                    className="form-control text py-2"
                                    placeholder="Duration Between Slides"
                                    value={params.slide_duration}
                                    onChange={onChange}
                                    name="slide_duration"
                                    id="slide_duration"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="big-title fs-6 text-center">Add a New Image To Slider</div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="file">
                            Upload Image:
                        </label>
                        <div className="file-holder d-flex align-items-center">
                            <div className="file-input flex-grow-1">
                                <input
                                    type="file"
                                    className="form-control py-2"
                                    onChange={onFileChange}
                                    name="file"
                                    id="file"
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>
                    {file && (
                        <div className="form-group mb-4">
                            <label className="mb-1" htmlFor="selectedImage">
                                Selected Image:
                            </label>
                            <div className="selected-image-holder">
                                <div className="selected-image w-25 mx-auto">
                                    <img
                                        style={{maxHeight: "200px"}}
                                        src={URL.createObjectURL(file)}
                                        alt="Selected Background"
                                        className="selected-image img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {isCreating ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default CourseForm;
