import GoogleAnalyticsKeysSettingsForm from "../keys-settings/GoogleAnalyticsKeysSettingsForm";
import googleAnalyticsIcon from "../../../assets/images/google-analytics-icon.svg"
import {BiEdit} from "react-icons/bi";
import {useEffect, useState} from "react";
import {settingService} from "../../../_services";

const GoogleAnalyticsKeysSettings = () => {
    const [googleAnalyticsKeysSettingsData, setGoogleAnalyticsKeysSettingsData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await settingService.getGoogleAnalyticsKeysSettings();
                setGoogleAnalyticsKeysSettingsData(data);
            } catch (error) {
                console.error("Error fetching Google Analytics keys data:", error);
            }
        };

        fetchData();
    }, [refresh]);
    const setFormHidden = () => {
        setHidden(!hidden);
    }
    if (googleAnalyticsKeysSettingsData && googleAnalyticsKeysSettingsData.length > 0) {
        const googleAnalyticsSettingsData = googleAnalyticsKeysSettingsData[0];
        return (
            <>
                <div className="big-title mb-2 mb-lg-3">Analytics Keys Settings</div>
                <div className={hidden ? "d-none" : "d-block"}>
                    <GoogleAnalyticsKeysSettingsForm
                        settings_id={googleAnalyticsSettingsData.id}
                        tracking_id={googleAnalyticsSettingsData.tracking_id}
                        sendRefreshToParent={setRefresh}
                        refresh={refresh}
                        sendHiddenToParent={setHidden}
                        hidden={hidden}
                    />
                </div>
                <div className="row m-0 justify-content-center">
                    <div
                        className="payments-content bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                        <div className="icon-holder text-center mb-2 mb-lg-0">
                            <img src={googleAnalyticsIcon}
                                 width="64"
                                 height="auto"
                                 
                                 alt="Google Analytics payment"/>
                        </div>
                        <div className="d-flex flex-column flex-lg-row flex-grow-1 justify-content-evenly">
                            <div className="setting-holder text-center mb-2 mb-lg-0">
                                <div
                                    className="setting-name text-black-50">Tracking ID
                                </div>
                                <div className="setting-value">{googleAnalyticsSettingsData.tracking_id}</div>
                            </div>
                        </div>
                        <div onClick={setFormHidden}
                             className="edit-holder c-pointer d-flex align-items-center align-self-end mt-2 mt-lg-0 me-2">
                            <div className="icon-holder me-2"><BiEdit className="icon" size="25"/></div>
                            <div className="edit-text">Edit</div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }
}

export default GoogleAnalyticsKeysSettings;