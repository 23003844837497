import Accordion from 'react-bootstrap/Accordion';
import {useEffect, useState} from "react";
import {chapterService} from "../../../_services";
import ChapterLessons from "./ChapterLessons";
import defaultChapterBg from "../../../assets/images/default_chapter_bg.jpg";

const CourseChapters = (props) => {
    let courseId = props.course_id;

    const [chaptersData, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await chapterService.getChapters(courseId);
                setData(data);
            } catch (error) {
                console.error("Error fetching chapters data:", error);
            }
        };

        fetchData();
    }, [courseId]);
    return (
        <div className="course-chapters px-3">
            <Accordion>
                {chaptersData.length > 0 ? (
                    chaptersData.map((chapter, index) => (
                        <Accordion.Item eventKey={`${index}`} key={index}>
                            <Accordion.Header>
                                <div className="chapter-holder d-flex align-items-center">
                                    <div className="img-holder me-3">
                                        {chapter.image_name ? (
                                            <img className="img-fluid rounded-circle" alt={chapter.title}
                                                 
                                                 src={`https://api.skillspay.io/thedss/images/${chapter.image_name}`}
                                            />
                                        ) : (
                                            <img className="img-fluid rounded-circle" alt={chapter.title}
                                                 
                                                 src={defaultChapterBg}
                                            />
                                        )}

                                    </div>
                                    <div className="chapter-info pe-3">
                                        <div className="chapter-title">{chapter.title}</div>
                                        <div className="lessons-count mt-1 mt-sm-2-sm-2">1
                                            / {chapter.lessons_num} LESSONS
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Header>
                            <ChapterLessons
                                id={chapter.id}
                                course_id={chapter.course_id}
                            />
                        </Accordion.Item>
                    ))
                ) : (
                    <div className="text-center">There is no chapter yet</div>
                )}
            </Accordion>
        </div>
    );
}

export default CourseChapters;