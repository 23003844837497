import "./public.css";
import {useEffect, useId, useState} from "react";
import {accountService, settingService} from "../../_services";
import {Link, useNavigate} from "react-router-dom";
import {Header} from "../private/Private";
import {useQuery} from "react-query";
import {CoursesCatalog, HomePageSlider, VideoModal} from "./Public";
import {FaPlay} from "react-icons/fa6";
import bg_home_1 from "../../assets/images/bg_home_1.png"
import benzinga_icon from "../../assets/images/benzinga-icon.svg";
import digital_journal_icon from "../../assets/images/digital-journal-icon.png";
import fox_icon from "../../assets/images/fox-icon.png";
import ncn_icon from "../../assets/images/ncn-icon.png";
import usa_today_icon from "../../assets/images/usa-today-icon.svg";
import {BsPlay} from "react-icons/bs";
import {AiFillStar} from "react-icons/ai";
import {Swiper, SwiperSlide} from "swiper/react";

import testimonial_pic1 from "../../assets/images/public/testimonial_pic1.png";
import testimonial_pic2 from "../../assets/images/public/testimonial_pic2.png";
import testimonial_pic3 from "../../assets/images/public/testimonial_pic3.png";
import testimonial_pic4 from "../../assets/images/public/testimonial_pic4.png";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {Autoplay, Pagination} from "swiper/modules";

const Home = () => {
    useEffect(() => {
        document.title = "Skillspay";
    }, []);
    let navigate = useNavigate();
    useEffect(() => {
        if (accountService.isLogged()) {
            navigate('/courses', {replace: true});
        }
    }, [navigate]);
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => settingService.getHomePageData());
    const homePageData = data || {"data": []}

    const [videoLink, setVideoLink] = useState(true);
    const [hidden, setHidden] = useState(true);
    const setVideoModalHidden = (videoLink) => {
        setVideoLink(videoLink)
        setHidden(!hidden);
    }

    if (isLoading) {
        return (
            <span></span>);
    }

    return (
        <div className="h-100 mx-auto bg-white holder-of-desktop-page">
            <Header desktop="home"/>
            <div className="home-page">
                <div
                    className="d-xl-none-thedss-version first-title fw-bold mt-3 mt-1 mb-1 fs-5 px-3 position-relative">
                    {homePageData[0].title_one || ''}
                </div>
                <div className="d-xl-none-thedss-version second-title text-black-50 px-3 position-relative">
                    {homePageData[0].title_two || ''}
                </div>
                <div className="home-slider-holder d-xl-none-thedss-version px-3 position-relative">
                    <div className="position-absolute bg-home-1"
                         style={{backgroundImage: `url(${bg_home_1})`}}></div>
                    <HomePageSlider/>
                    <div className="position-absolute bg-home-2"
                         style={{backgroundImage: `url(${bg_home_1})`}}></div>
                </div>
                <div className="intro-holder d-xl-none-thedss-version mb-4 d-xl-none-thedss-version">
                    <div className="intro-title text-center fw-bold fs-5 mb-3 position-relative">
                        Introducing Skillspay.io
                    </div>
                    <div className="intro-btn-holder">
                        <button
                            className="intro-btn position-relative mx-auto bg-transparent border-0 d-flex align-items-center"
                            type="button"
                            onClick={() => setVideoModalHidden(homePageData[0].video_url.toString())}>
                            <div className="icon-holder"><FaPlay size="30" className="play-icon me-1"/></div>
                            <div className="text-holder">Watch Introduction</div>
                        </button>
                    </div>
                </div>
                <div className="description-holder d-xl-none-thedss-version position-relative">
                    <div className="position-absolute bg-home-1"
                         style={{backgroundImage: `url(${bg_home_1})`}}></div>
                    <div className="ready-title text-center fs-5 mb-2 position-relative">
                        Are You Ready!
                    </div>
                    <div className="description lh-lg px-4 text-center position-relative">
                        {homePageData[0].description}
                    </div>
                </div>
                <Link to="/auth/quiz" className="d-xl-none-thedss-version">
                    <div className="btn-holder mt-4 text-center">
                        <button className="quiz-button text-white border-0 px-5 py-3 rounded-3" type="button">
                            GET STARTED NOW
                        </button>
                    </div>
                </Link>
                <div className="as-seen-section mt-5 d-xl-none-thedss-version pt-2 pb-3 px-2 shadow-sm"
                     style={{backgroundColor: "#f9f9f9"}}>
                    <div className="title text-center mb-2">As Seen On</div>
                    <div className="as-seen-content row m-0 justify-content-evenly">
                        <div className="col-2 mb-2 text-center">
                            <div className="icon-holder">
                                <img
                                    src={fox_icon}
                                    alt="fox"
                                    width="60"
                                    height="30"
                                />
                            </div>
                        </div>
                        <div className="col-2 mb-2 text-center">
                            <div className="icon-holder">
                                <img
                                    src={benzinga_icon}
                                    alt="benzinga"
                                    width="60"
                                    height="30"
                                />
                            </div>
                        </div>
                        <div className="col-2 mb-2 text-center">
                            <div className="icon-holder">
                                <img
                                    src={usa_today_icon}
                                    alt="usa today"
                                    width="60"
                                    height="30"
                                />
                            </div>
                        </div>
                        <div className="col-2 mb-2 text-center">
                            <div className="icon-holder">
                                <img
                                    src={digital_journal_icon}
                                    alt="digital journal"
                                    width="60"
                                    height="30"
                                />
                            </div>
                        </div>
                        <div className="col-2 mb-2 text-center">
                            <div className="icon-holder">
                                <img
                                    src={ncn_icon}
                                    alt="ncn"
                                    width="60"
                                    height="30"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonials-section mt-3 pt-2 pb-3 px-2 shadow-sm">
                    <div className="main-text-color fw-bold fs-5 text-center mb-1">Don’t take our word for it…</div>
                    <div className="main-text-color small text-center mb-2">Testimonies from past students.</div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={15}
                        loop={true}
                        className="myTestimonialSwiper"
                        pagination={true}
                        modules={[Autoplay, Pagination]}
                    >
                        <SwiperSlide>
                            <div className="testimonial-box px-4">
                                <div className="box-content rounded-3"
                                     onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955012948')}>
                                    <div className="img-holder rounded-3 position-relative">
                                        <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                             src={testimonial_pic1} alt="Sarah W."/>
                                        <div
                                            className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <BsPlay size="50" color="#fff"/>
                                                </div>
                                                <div className="review-holder">
                                                    <div className="name text-white fs-5">
                                                        Sarah W.
                                                    </div>
                                                    <div className="review-stars">
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 rounded-bottom-3 pb-4"
                                         style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                        "Skillspay changed my life"
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box px-4">
                                <div className="box-content rounded-3"
                                     onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955012273')}>
                                    <div className="img-holder rounded-3 position-relative">
                                        <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                             src={testimonial_pic2} alt="Peter B."/>
                                        <div
                                            className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <BsPlay size="50" color="#fff"/>
                                                </div>
                                                <div className="review-holder">
                                                    <div className="name text-white fs-5">
                                                        Peter B.
                                                    </div>
                                                    <div className="review-stars">
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 rounded-bottom-3 pb-4"
                                         style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                        "Skillspay gave me an opportunity I’ll never forget"
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box px-4">
                                <div className="box-content rounded-3"
                                     onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955009904')}>
                                    <div className="img-holder rounded-3 position-relative">
                                        <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                             src={testimonial_pic3} alt="Donald M."/>
                                        <div
                                            className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <BsPlay size="50" color="#fff"/>
                                                </div>
                                                <div className="review-holder">
                                                    <div className="name text-white fs-5">
                                                        Donald M.
                                                    </div>
                                                    <div className="review-stars">
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 rounded-bottom-3 pb-4"
                                         style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                        "Skillspay is the ultimate resource for success and a game changer"
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box px-4">
                                <div className="box-content rounded-3"
                                     onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955010794')}>
                                    <div className="img-holder rounded-3 position-relative">
                                        <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                             src={testimonial_pic4} alt="Cyndy F."/>
                                        <div
                                            className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <BsPlay size="50" color="#fff"/>
                                                </div>
                                                <div className="review-holder">
                                                    <div className="name text-white fs-5">
                                                        Cyndy F.
                                                    </div>
                                                    <div className="review-stars">
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                        <AiFillStar size="25" color="#E0B703"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 rounded-bottom-3 pb-4"
                                         style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                        "Thanks for the opportunity to learn and earn"
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <Link to="/auth/quiz" className="d-xl-none-thedss-version">
                        <div className="btn-holder mt-3 text-center">
                            <button className="quiz-button text-white border-0 px-5 py-3 rounded-3" type="button">
                                GET STARTED NOW
                            </button>
                        </div>
                    </Link>
                </div>
                <div className="courses-catalog-holder d-xl-none-thedss-version pb-3 mb-3">
                    <CoursesCatalog/>
                    <Link className="" to="/auth/quiz">
                        <div className="btn-holder mt-4 text-center">
                            <button className="quiz-button text-white border-0 px-5 py-3 rounded-3" type="button">
                                GET STARTED NOW
                            </button>
                        </div>
                    </Link>
                </div>
                <div className="copyright-text text-center py-2">
                    All Right Reserved &copy; 2024 Skillspay
                </div>
                <div className={hidden ? 'video-modal-container hidden' : 'video-modal-container'}>
                    <VideoModal
                        url={videoLink}
                        sendHiddenToParent={setHidden}
                        hidden={hidden}
                        setVideoLink={setVideoLink}
                    />
                </div>
            </div>
        </div>
    );
}

export default Home;