import "./public.css";
import {useEffect} from "react";
import {accountService} from "../../_services";
import {Link, useNavigate} from "react-router-dom";
import {CoursesCatalog} from "./Public";
import {Header} from "../private/Private";

const FeaturedCourses = () => {
    useEffect(() => {
        document.title = "Skillspay - Featured Courses";
    }, []);
    let navigate = useNavigate();
    useEffect(() => {
        if (accountService.isLogged()) {
            return (
                navigate('/courses', {replace: true})
            );
        }
    }, [navigate]);

    return (
        <div className="pricing-plans-page h-100 mx-auto" style={{minHeight: '100vh', maxWidth: '500px'}}>
            <Header/>
            <div className="title-holder px-2 py-3">
                <div className="page-title fw-bold text-center mb-2 px-3 lh-sm">Our Featured Courses</div>
                <div className="text-center big-title fw-bold px-3 lh-sm fs-6">
                    Sign up Today to Acquire Skills which Pay!
                </div>
            </div>
            <div className="home-page courses-catalog-holder d-xl-none-thedss-version pb-3 mb-3">
                <CoursesCatalog featured="none"/>
                <Link className="" to="/pricing-plans">
                    <div className="btn-holder mt-4 text-center">
                        <button className="quiz-button text-white border-0 px-5 py-3 rounded-3" type="button">
                            Start Acquiring Skills Now
                        </button>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default FeaturedCourses;