import {Header, CourseRow, CourseForm} from "./Admin";
import {useEffect, useState} from "react";
import {courseService} from "../../_services";

const Courses = () => {
    const [editMode, setEditMode] = useState(false);
    const [courseToEdit, setCourseToEdit] = useState(null);
    const [coursesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await courseService.getCourses();
                setData(data);
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditCourse = (course) => {
        setEditMode(true);
        setCourseToEdit(course);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Courses"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-courses-page">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="big-title me-3 mb-2 mb-md-0">Courses List</div>
                        <div className="add-btn-holder">
                            <button className="btn btn-primary py-1 btn-sm" type="button" disabled={!hidden}
                                    onClick={setFormHiddenAdd}>Add New Course
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <CourseForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            course={courseToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="row m-0 vertical-items">
                        {coursesData.length > 0 ? (
                                coursesData.map((course) => (
                                    <CourseRow
                                        key={course.id}
                                        course_id={course.id}
                                        title={course.title}
                                        level={course.level}
                                        background={course.background_name}
                                        description={course.description}
                                        duration={course.course_duration}
                                        lessons_num={course.lessons_num}
                                        quizzes_num={course.quizzes_num}
                                        received_skills={course.received_skills}
                                        intro_video={course.intro_video}
                                        what_will_you_learn={course.what_will_you_learn}
                                        is_featured={course.is_featured}
                                        created_date={course.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditCourse={handleEditCourse}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            )
                            : (
                                <div className="text-center">There is no course yet</div>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Courses;