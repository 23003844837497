import React from "react";
import PaypalPaymentButton from "../../private/main/PaypalPaymentButton";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import CreditCardForm from "../../private/main/CreditCardForm";
import StripePayment from "../../private/main/StripePayment";

import payment_logo_1 from "../../../assets/images/payments/payment_logo_1.jpg"
import payment_logo_2 from "../../../assets/images/payments/payment_logo_2.jpg"
import payment_logo_3 from "../../../assets/images/payments/payment_logo_3.jpg"
import payment_logo_4 from "../../../assets/images/payments/payment_logo_4.jpg"
import payment_logo_5 from "../../../assets/images/payments/payment_logo_5.jpg"
import payment_logo_6 from "../../../assets/images/payments/payment_logo_6.jpg"
import payment_logo_7 from "../../../assets/images/payments/payment_logo_7.jpg"
import payment_logo_8 from "../../../assets/images/payments/payment_logo_8.jpg"
import payment_logo_9 from "../../../assets/images/payments/payment_logo_9.jpg"

const PaymentMethods = (props) => {
    const initialOptions = {
        clientId: `${props.client_id}`,
        currency: "USD",
        intent: "capture",
    };

    return (
        <div className="mt-4 px-3">
            <div className="text-center mt-4 fw-bold fs-5">Payment Methods</div>
            <div className="text-black-50 mt-3 small px-2">
                Choose your desired payment method below. If you are having issues with payment contact us on
                payments@skillspay.io for assistance.
            </div>
            <div className="mt-3 small px-2 main-text-color">
                You’re subscribing to : <span className="fw-bold-600">{props.product_name}</span> Plan<br/>
                Which will cost: <span className="fw-bold-600">${props.price}</span>
            </div>
            <div className="mt-4 payment-methods-custom">
                <StripePayment
                    duration_type={props.duration_type}
                    duration={props.duration}
                    product_name={props.product_name}
                    price={props.price}
                    course_id={props.course_id || ''}
                    pdf_course_id={props.pdf_course_id || ''}
                    userDetails={props.userDetails}
                    setPaymentMethod={props.setPaymentMethod}
                    setHolderStep={props.setHolderStep}
                />
                <CreditCardForm
                    duration_type={props.duration_type}
                    duration={props.duration}
                    product_name={props.product_name}
                    price={props.price}
                    course_id={props.course_id || ''}
                    pdf_course_id={props.pdf_course_id || ''}
                    userDetails={props.userDetails}
                    setPaymentMethod={props.setPaymentMethod}
                    setHolderStep={props.setHolderStep}
                />
                <PayPalScriptProvider options={initialOptions}>
                    <PaypalPaymentButton
                        duration_type={props.duration_type}
                        duration={props.duration}
                        product_name={props.product_name}
                        price={props.price}
                        course_id={props.course_id || ''}
                        pdf_course_id={props.pdf_course_id || ''}
                        userDetails={props.userDetails}
                        setPaymentMethod={props.setPaymentMethod}
                        setHolderStep={props.setHolderStep}
                    />
                </PayPalScriptProvider>
            </div>
            <div className="mt-4 px-2 main-text-color text-center pb-4">
                We accept payment in <span className="fw-bold-600">100+</span> currencies
            </div>
            <div className="pb-2">
                <div className="logos-holder bg-white p-2 shadow-sm mb-5 d-flex flex-wrap align-items-center justify-content-center">
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_1} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_2} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_3} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_4} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_5} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_6} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_7} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_8} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_9} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentMethods;