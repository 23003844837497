import {useEffect} from "react";
import Header from "../../components/private/main/Header";

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "Skillspay - Privacy Policy";
    }, []);
    return (
        <>
            <Header/>
        </>
    );
}

export default PrivacyPolicy;