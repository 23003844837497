const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const OrderRow = (props) => {
    return (
        <tr>
            <td>
                <span>#{props.order_id}</span>
            </td>
            <td>
                {props.customer_name}
            </td>
            <td>
                {props.email}
            </td>
            <td>
                {props.product_name}
            </td>
            <td>
                ${props.total}
            </td>
            <td>
                {props.payment_method.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}
            </td>
            <td>
                <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
            </td>
        </tr>
    );
}

export default OrderRow;