import {Chapters, Header} from "./Admin";
import {BiArrowBack} from "react-icons/bi";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {courseService} from "../../_services";

const Course = () => {
    const getDate = (dt) => {
        let date = new Date(dt);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date.getFullYear();
        return mm + '/' + dd + '/' + yyyy;
    }
    let {cid} = useParams();
    const [crsData, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await courseService.getCourseById(cid);
                setData(data);
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchData();
    }, [cid]);
    if (crsData && crsData.length > 0) {
        const courseData = crsData[0];
        return (
            <>
                <Header title="Course Details"/>
                <div className="page-content p-2 p-lg-3">
                    <div className="main-courses-page">
                        <Link className="big-title back fs-6 mb-2 mb-lg-3 d-flex align-items-center c-pointer"
                              to="/admin/courses">
                            <BiArrowBack className="me-2 icon" size="25"/>Back To Courses List
                        </Link>
                        <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                            <div
                                className="course-created-date text-end pb-1 text-nowrap">{courseData.created_date ? `Created at: ${getDate(courseData.created_date)}` : ''}</div>
                            <div className="big-title mb-3 mb-lg-3 text-center">{courseData.title}</div>
                            <div
                                className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between">
                                <div className="mb-2"><span className="text-black-50">Level: </span>{courseData.level}
                                </div>
                                <div className="mb-2"><span
                                    className="text-black-50">Lessons: </span>{courseData.lessons_num}</div>
                                <div className="mb-2"><span
                                    className="text-black-50">Duration: </span>{courseData.course_duration}</div>
                                <div className="mb-2"><span
                                    className="text-black-50">Quizzes: </span>{courseData.quizzes_num}</div>
                            </div>
                            <div className="course-description mt-2">
                                {courseData.description}
                            </div>
                        </div>
                        <Chapters course_id={cid}/>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <Header title="Course Details"/>
                <div className="page-content p-2 p-lg-3">
                    <div className="main-courses-page">
                        <Link className="big-title back fs-6 mb-2 mb-lg-3 d-flex align-items-center c-pointer"
                              to="/admin/courses">
                            <BiArrowBack className="me-2 icon" size="25"/>Back To Courses List
                        </Link>
                        <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3 text-center">
                            This Course You Try To Access Is Not Found
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Course;