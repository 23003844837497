import {Navigate} from "react-router-dom";
import {accountService} from "../_services";

const IsLogged = ({children}) => {
    if (accountService.isLogged()) {
        return <Navigate to="/courses"/>;
    }
    return children;

};

export default IsLogged;