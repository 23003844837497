import React from "react";

const CreateAccount = ({userDetails, setUserDetails, setHolderStep}) => {

    const onChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value
        });
    }

    const handleSetPaymentStep = () => {
        setHolderStep('payment')
    };

    return (
        <div className="mt-4 px-3">
            <div className="text-center my-4 fw-bold fs-5">Sign up your account</div>
            <div className="user-info-step text-start">
                <form encType="multipart/form-data">
                    <div className="form-group mb-3">
                        <label className="mb-1 ps-1 small text-black-50"
                               htmlFor="full_name">Full Name</label>
                        <input type="text" className="form-control"
                               placeholder="Your name" name="full_name" id="full_name"
                               value={userDetails.full_name}
                               onChange={onChange}/>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1 ps-1 small text-black-50"
                               htmlFor="email">Email</label>
                        <input type="text" className="form-control"
                               placeholder="example@mail.com" name="email" id="email"
                               value={userDetails.email}
                               onChange={onChange}/>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1 ps-1 small text-black-50"
                               htmlFor="password">Password</label>
                        <input type="password" className="form-control"
                               placeholder="Password"
                               name="password" id="password" value={userDetails.password}
                               onChange={onChange}/>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1 ps-1 small text-black-50"
                               htmlFor="confirm_password">Confirm Password</label>
                        <input type="password" className="form-control"
                               placeholder="Confirm Password"
                               name="confirm_password" id="confirm_password" value={userDetails.confirm_password}
                               onChange={onChange}/>
                    </div>
                </form>
                <div className="pay-btn-holder px-3 mt-5">
                    <button type="button"
                            className="border-0 w-100 py-2 px-3 text-white submit-button rounded-3"
                            disabled={!userDetails.email || !userDetails.full_name || !userDetails.password || !userDetails.confirm_password || (userDetails.password !== userDetails.confirm_password)}
                            onClick={handleSetPaymentStep}>
                        Pay to Activate Account
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateAccount;