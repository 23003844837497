import {Header, TemplateForm, TemplateRow} from "./Admin";
import {Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {emailService} from "../../_services";
import {BiArrowBack} from "react-icons/bi";
import {Link} from "react-router-dom";

const EmailTemplates = () => {

    const [templatesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [templateToEdit, setTemplateToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await emailService.getTemplates();
                setData(data);
            } catch (error) {
                console.error("Error fetching templates data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditTemplate = (template) => {
        setEditMode(true);
        setTemplateToEdit(template);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Email Templates"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-templates-page">
                    <Link className="big-title back fs-6 mb-2 mb-lg-3 d-flex align-items-center c-pointer"
                          to="/admin/email-marketing">
                        <BiArrowBack className="me-2 icon" size="25"/>Back To Emails Configurations
                    </Link>
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="big-title me-3 mb-2 mb-md-0">Email Templates</div>
                        <div className="add-btn-holder">
                            <button className="btn btn-primary py-1 btn-sm" disabled={!hidden} type="button" onClick={setFormHiddenAdd}>Add New Template</button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <TemplateForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            template={templateToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Name</td>
                                <td className="text-black-50">Content</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {templatesData.length > 0 ? (
                                templatesData.map((template) => (
                                    <TemplateRow
                                        key={template.id}
                                        template_id={template.id}
                                        name={template.name}
                                        content={template.content}
                                        created_date={template.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditTemplate={handleEditTemplate}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailTemplates;