import {BiBarChartAlt, BiEdit, BiTime, BiTrash} from "react-icons/bi";
import {Link} from "react-router-dom";
import {useState} from "react";
import {courseService} from "../../../_services";
import defaultCourseBg from "../../../assets/images/default_course_bg.jpg";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const CourseRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);
    const handleDeleteCourse = async () => {
        setIsDeleting(true);

        try {
            await courseService.deleteCourse(props.course_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting course:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditCourse = () => {
        props.handleEditCourse(props);
        props.sendHiddenToParent(!props.hidden);
    };

    return (
        <div className="col-lg-6 col-xxl-4 mb-3">
            <div className={`bg-white rounded-3 pt-3 px-2 px-lg-3 d-flex flex-column h-100 ${props.is_featured ? 'border border-danger' : ''}`}>
                <div className="course-bg-holder position-relative mb-2">
                    {props.background ? (
                        <img className="course-bg img-fluid rounded-3" alt={props.title}
                             
                             src={`https://api.skillspay.io/thedss/images/${props.background}`}
                        />
                    ) : (
                        <img className="course-bg img-fluid rounded-3" alt={props.title}
                             
                             src={defaultCourseBg}
                        />
                    )}
                    <div
                        className="level-holder position-absolute bottom-0 start-0 ms-2 mb-2 bg-white px-3 py-1 rounded-pill">
                        <div className="level d-flex align-items-center">
                            <div className="icon-holder me-1">
                                <BiBarChartAlt size="20" color="#FE634E"/>
                            </div>
                            <div>
                                {props.level}
                            </div>
                        </div>
                    </div>
                    <div
                        className="level-holder position-absolute bottom-0 end-0 me-2 mb-2 bg-white px-3 py-1 rounded-pill">
                        <div className="duration d-flex align-items-center">
                            <div className="icon-holder me-1">
                                <BiTime size="20" color="#FE634E"/>
                            </div>
                            <div>
                                {props.duration} Hours
                            </div>
                        </div>
                    </div>
                </div>
                <div className="course-title fw-bold mb-2">{props.title}</div>
                <div className="course-description mb-2">
                    {`${props.description.substring(0, 180)}...`}
                </div>
                <div className="mt-auto">
                    <div className="actions-btn d-flex align-items-center justify-content-between my-3">
                        <div className="edit-btn-holder w-50 px-2">
                            <Link className="name-link" to={`/admin/courses/course/${props.course_id}`}>
                                <button className="edit-btn w-100 py-1 rounded-2">Chapters</button>
                            </Link>
                        </div>
                        <div className="d-flex justify-content-center w-50 px-2">
                            <div>
                                <button className="border-0 bg-transparent" onClick={handleEditCourse}
                                        disabled={!props.hidden}>
                                    <BiEdit size="22" className="edit-icon"/>
                                </button>
                            </div>
                            {isDeleting ? (
                                <span className="text-danger">Loading...</span>
                            ) : (
                                <BiTrash size="25" onClick={handleDeleteCourse} className="remove-icon"/>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="course-created-date text-end pb-1 text-nowrap">{props.created_date ? `Created at: ${getDate(props.created_date)}` : ''}</div>
            </div>
        </div>
    );
}

export default CourseRow;