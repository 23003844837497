import {Header, HomePageForm, SliderSettings} from "./Admin";
import {BiEdit} from "react-icons/bi";
import {useEffect, useState} from "react";
import {settingService} from "../../_services";

const Settings = () => {
    const [homePageData, setHomePageData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await settingService.getHomePageData();
                setHomePageData(data);
            } catch (error) {
                console.error("Error fetching Home Page data:", error);
            }
        };

        fetchData();
    }, [refresh]);
    const setFormHidden = () => {
        setHidden(!hidden);
    }
    if (homePageData && homePageData.length > 0) {
        const HomePageData = homePageData[0];
        return (
            <>
                <Header title="Settings"/>
                <div className="page-content p-2 p-lg-3">
                    <div className="main-settings-page">
                        <div className="big-title mb-2 text-center">Homepage Settings</div>
                        <div className={hidden ? "d-none" : "d-block"}>
                            <HomePageForm
                                data_id={HomePageData.id}
                                title_one={HomePageData.title_one}
                                title_two={HomePageData.title_two}
                                video_url={HomePageData.video_url}
                                description={HomePageData.description}
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        </div>
                        <div className="row m-0 justify-content-center">
                            <div className="settings-content p-2 p-lg-3 p-xl-4">
                                <div className="row">
                                    <div className="col-xl-6-thedss mb-3 mb-xl-0-thedss">
                                        <div className="bg-white p-2 rounded-4 h-100">
                                            <div onClick={setFormHidden}
                                                 className="edit-holder c-pointer d-flex align-items-center justify-content-end me-2">
                                                <div className="icon-holder me-2"><BiEdit className="icon" size="25"/></div>
                                                <div className="edit-text">Edit</div>
                                            </div>
                                            <div className="setting-holder text-center mb-3">
                                                <div className="setting-name text-black-50 fw-light">First Title</div>
                                                <div className="setting-value">{HomePageData.title_one}</div>
                                            </div>
                                            <div className="setting-holder text-center mb-3">
                                                <div className="setting-name text-black-50 fw-light">Second Title</div>
                                                <div className="setting-value">{HomePageData.title_two}</div>
                                            </div>
                                            <div className="setting-holder text-center mb-3">
                                                <div className="setting-name text-black-50 fw-light">Video URL</div>
                                                <div className="setting-value">
                                                    {`${HomePageData.video_url.substring(0, 25)}...`}
                                                </div>
                                            </div>
                                            <div className="setting-holder text-center mb-3">
                                                <div className="setting-name text-black-50 fw-light">Description</div>
                                                <div className="setting-value">{HomePageData.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6-thedss mb-3 mb-xl-0-thedss">
                                        <div className="bg-white p-2 rounded-4 slider-settings-content h-100">
                                            <div className="slider-title text-center">Slider Settings</div>
                                            <SliderSettings/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <Header title="HomePage"/>
                <div className="main-payments-page">
                    <div className="big-title mb-2 mb-lg-3">Homepage Settings</div>
                </div>
            </>
        )
    }
}

export default Settings;