import {Outlet} from "react-router-dom";
import "./private.css"
import {Header, BottomNavbar} from "./Private";

const PLayout = () => {
    return (
        <div className="h-100 mx-auto holder-of-desktop-page bg-white">
            <Header desktop="home"/>
            <div style={{paddingBottom: '80px'}}>
                <Outlet/>
            </div>
            <div className="d-xl-none-thedss-version">
                <BottomNavbar/>
            </div>
        </div>
    );
}

export default PLayout;