import SendGridKeysSettingsForm from "../keys-settings/SendGridKeysSettingsForm";
import sendgridIcon from "../../../assets/images/sendgrid.png"
import {BiEdit} from "react-icons/bi";
import {useEffect, useState} from "react";
import {emailService} from "../../../_services";

const SendGridKeysSettings = () => {
    const [sendgridKeysSettingsData, setSendGridKeysSettingsData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await emailService.getSendGridKeysSettings();
                setSendGridKeysSettingsData(data);
            } catch (error) {
                console.error("Error fetching sendgrid keys data:", error);
            }
        };

        fetchData();
    }, [refresh]);
    const setFormHidden = () => {
        setHidden(!hidden);
    }
    if (sendgridKeysSettingsData && sendgridKeysSettingsData.length > 0) {
        const sendgridSettingsData = sendgridKeysSettingsData[0];
        return (
            <>
                <div className="big-title mb-2 mb-lg-3">SendGrid Keys Settings</div>
                <div className={hidden ? "d-none" : "d-block"}>
                    <SendGridKeysSettingsForm
                        settings_id={sendgridSettingsData.id}
                        api_key={sendgridSettingsData.api_key}
                        sendRefreshToParent={setRefresh}
                        refresh={refresh}
                        sendHiddenToParent={setHidden}
                        hidden={hidden}
                    />
                </div>
                <div className="row m-0 justify-content-center">
                    <div
                        className="payments-content bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                        <div className="icon-holder text-center mb-2 mb-lg-0">
                            <img src={sendgridIcon}
                                 width="64"
                                 height="auto"
                                 
                                 alt="sendgrid payment"/>
                        </div>
                        <div className="d-flex flex-column flex-lg-row flex-grow-1 justify-content-evenly">
                            <div className="setting-holder text-center mb-2 mb-lg-0">
                                <div
                                    className="setting-name text-black-50">API Key
                                </div>
                                <div className="setting-value">
                                    {`${sendgridSettingsData.api_key.substring(0, 10)}...`}
                                </div>
                            </div>
                        </div>
                        <div onClick={setFormHidden}
                             className="edit-holder c-pointer d-flex align-items-center align-self-end mt-2 mt-lg-0 me-2">
                            <div className="icon-holder me-2"><BiEdit className="icon" size="25"/></div>
                            <div className="edit-text">Edit</div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }
}

export default SendGridKeysSettings;