import React, {useEffect, useState} from "react";
import {settingService} from "../../../_services";

const HomePageForm = (props) => {
    const [isCreating, setIsCreating] = useState(false);

    const [params, setParamsValues] = useState({
        title_one: "",
        title_two: "",
        video_url: "",
        description: "",
    });

    useEffect(() => {
        setParamsValues({
            title_one: props.title_one,
            title_two: props.title_two,
            video_url: props.video_url,
            description: props.description,
        });
    }, [props]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("title_one", params.title_one);
            formData.append("title_two", params.title_two);
            formData.append("video_url", params.video_url);
            formData.append("description", params.description);
            formData.append("data_id", props.data_id);
            await settingService.updateHomePageData(formData);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
        } catch (error) {
            console.error("Error Updating Home Page Data:", error);
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="main-form-holder add-chapter-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center mb-3">Edit Home Page Data</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="form-group col-md-6 mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="title_one">
                                First Title
                            </label>
                            <div className="title-one-holder d-flex align-items-center">
                                <div className="title-one-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="First Title"
                                        value={params.title_one}
                                        onChange={onChange}
                                        name="title_one"
                                        id="title_one"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="title_two">
                                Second Title
                            </label>
                            <div className="title-two-holder d-flex align-items-center">
                                <div className="title-two-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Second Title"
                                        value={params.title_two}
                                        onChange={onChange}
                                        name="title_two"
                                        id="title_two"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="video_url">
                                Video URL
                            </label>
                            <div className="title-two-holder d-flex align-items-center">
                                <div className="title-two-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Video URL"
                                        value={params.video_url}
                                        onChange={onChange}
                                        name="video_url"
                                        id="video_url"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-2 mb-md-4">
                            <label className="mb-1" htmlFor="description">
                                Description
                            </label>
                            <div className="title-two-holder d-flex align-items-center">
                                <div className="title-two-input flex-grow-1">
                                    <textarea
                                        className="textarea form-control py-2"
                                        placeholder="Description of the Course"
                                        value={params.description}
                                        onChange={onChange}
                                        name="description"
                                        id="description"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isCreating ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default HomePageForm;
