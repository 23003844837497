import {accountService, orderService} from "../../../_services";
import tranzakLogo from "../../../assets/images/tranzak_logo.png";

const CreditCardForm = (props) => {

    const productData = {
        product: {
            amount: props.price, // Amount in the smallest currency unit (e.g., cents for USD)
            currencyCode: "USD", // Currency code
            paymentMethod: 'card', // Payment method (update as needed)
            description: props.product_name,
            payerNote: "Skillspay Order",
            cancelUrl: "",
            returnUrl: "https://skillspay.io/thank-you",
        }
    }

    const handlePayClickToPay = () => {
        orderService.PayOrderWithTranzak(productData)
            .then(async res => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const orderData = {
                            "order_id": res.data.data.requestId,
                            "product_name": `${props.product_name}`,
                            "total": props.price,
                            "course_id": props.course_id || "",
                            "order_status": "not_paid",
                            "payment_method": "tranzak",
                            "duration_type": props.duration_type,
                            "duration": props.duration,
                        }
                        props.setPaymentMethod('Credit Card')
                        await orderService.createOrder(orderData);
                        localStorage.setItem('ORDER_ID', res.data.data.requestId);
                        if (res.data.data.requestId) {
                            try {
                                const email = props.userDetails.email;
                                const full_name = props.userDetails.full_name;
                                const password = props.userDetails.password;
                                const ORDER_ID = res.data.data.requestId;
                                const STATUS = 'inactive'
                                const duration_type = props.duration_type;
                                const duration = props.duration;
                                const response = await fetch('https://api.skillspay.io/auth/signup', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({email, full_name, password, ORDER_ID, duration_type, duration, STATUS}),
                                });
                            } catch (error) {
                                console.error('Error:', error);
                            }
                        }
                        window.location.href = res.data.data.links.paymentAuthUrl
                    }
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="pay-button">
            <button type="button"
                    onClick={() => handlePayClickToPay()}
                    className="pay-btn w-100 my-4">
                <span className="fw-light d-inline-block small fw-bold-500 me-2">Pay With Mobile Wallet</span>
                <img src={tranzakLogo} className="tranzak-logo" alt=""/>
            </button>
        </div>
    );
}

export default CreditCardForm;